import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from '../services/http.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderComponent } from 'ngx-ui-loader/lib/core/ngx-ui-loader.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';

export interface DialogData {
  data: any;
}


@Component({
  selector: 'app-positionconversion',
  templateUrl: './positionconversion.component.html',
  styleUrls: ['./positionconversion.component.scss']
})
export class PositionconversionComponent implements OnInit {
  position: any;
  bgColorBStype: any;
  symbol: any;
  convPos: any;
  posParam: any;
  type: any;
  qty: any;
  errormeg: any;

  constructor(
    public service: HttpService,
    public toastr: ToastrService,
    public loader: NgxUiLoaderService,
    public dialogRef: MatDialogRef<PositionconversionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public routeTo: Router
  ) { }

  ngOnInit() {
    this.position = this.data['Data'];
    this.bgColorBStype = 'buy';
    this.symbol = this.position['Symbol'];
    this.qty = this.position['C/FQty'];
    if (this.position['Intra/CNC'] == "Intra") {
      this.posParam = 1;
      this.convPos = "cnc";
    } else {
      this.posParam = 2;
      this.convPos = "intra";
    }
  }

  /**
   * Method to Find selected position
   * @author kalai
   * @since 24-04-2020
   */
  positionSelect(value) {
    if (value === "intra") {
      this.posParam = 1;
    } else {
      this.posParam = 2;
    }
  }

  /**
   * @method Method to position conversion
   * @params na
   * @return na
   * @author kalai 
   * @on 02-11-2019
   */
  convertPosition() {
    this.loader.start();
    let jsonObj = {
      "userId": this.service.getUserId(),
      "internalScripToken": this.position['Internaltoken'],
      "exchangeId": this.position['ExchangeId'],
      "marketId": this.position['MarketId'],
      "internalLoginId": this.service.getInternalId(),
      "buyOrSell": 1,
      "qty": this.qty,
      "intraday": this.posParam
    }
    this.service.positionConvert(jsonObj).subscribe(resp => {
      this.loader.stop();
      if (resp['StatusRequest']['status'] == "0") {
        this.service.toastr.success("Position converted Successfully");
        this.dialogRef.close(true);
      } else {
        if (resp['StatusRequest']['status'] == "1D")
          this.errormeg = resp['StatusRequest']['Message'];
        this.service.toastr.error("Failed");
      }
    }, (err) => {
      this.service.toastr.error(err);
      this.loader.stop();
    })
  }
}
