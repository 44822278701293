import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from '../services/http.service';

@Component({
  selector: 'app-position-info',
  templateUrl: './position-info.component.html',
  styleUrls: ['./position-info.component.scss']
})
export class PositionInfoComponent implements OnInit {
  info: any;
  userId: any

  constructor(
    public dialogRef: MatDialogRef<PositionInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public service: HttpService
  ) {
    this.info = data['data'];
    this.userId = this.service.getUserId();
  }

  ngOnInit() {
  }
  closeModelBox(): void {
    this.dialogRef.close();
  }

}
