import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { SharedataService } from 'src/app/services/sharedata.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-holding',
  templateUrl: './holding.component.html',
  styleUrls: ['./holding.component.scss']
})
export class HoldingComponent implements OnInit {
  userDetails: any;
  userID: any;
  mwDetails: any;
  position: any;
  showDatafound: boolean;
  emptytable: any = false;
  holdings: any = [];
  totalHoldStock: Number = 0;;
  constructor(
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
    public sharedata: SharedataService,
    public service: HttpService,
  ) { }

  ngOnInit() {
    if (
      localStorage.getItem("userdetails") != null ||
      localStorage.getItem("userdetails") != undefined
    ) {
      var userDetails = JSON.parse(localStorage.getItem("userdetails"));
      this.userDetails = userDetails;
      this.userID = this.userDetails["UserDetails"]["UserID"];
      this.mwDetails = this.userDetails["MarketWatchDetails"];

      this.getHoldingList();
    }
  }

  /**
   * Method to call the Holding List
   * @author Kalai
   */
  getHoldingList() {
    this.loader.start();
    let JsonObj = {
      "userId": this.userID,
      "symbol": "" //Empty string for all holdings, if u want for specific stock send symbol name
    }
    this.service.holdingListDetails(JsonObj).subscribe(resp => {
      this.loader.stop();
      if (resp['StatusRequest']['status'] == '0' && resp['StatusRequest']['No.ofRecords'] > 1) {
        // resp['HoldingData'].pop();
        var result: any = resp['HoldingData'];
        this.totalHoldStock = result.length;
        result.map((item, i) => {
          result[i]['Exchange'] = "NSE";
          result[i]['CurrentMarketPrc'] = Number(result[i]['CurrentMarketPrc']).toFixed(2);
          result[i]['CurrentValue'] = Number(result[i]['CurrentValue']).toFixed(2);
        });
        this.holdings = result;

        this.emptytable = true;
      } else {
        if (resp['StatusRequest']['status'] == "1") {
          // this.service.unauthorized();
          this.service.toastr.error(resp["StatusRequest"]['Message']);
        }
      }

    }, (err) => {
      this.loader.stop();
      this.service.unauthorized();
    });
  }

}
