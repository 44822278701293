import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SharedataService {
  public executeOrderShare = new BehaviorSubject<Boolean>(false);
  public indexDataShare = new BehaviorSubject<any>("");
  public webSocketDataService = new BehaviorSubject<any>("");
  public OTPGenerate = new BehaviorSubject<boolean>(false);
  public orderGateway = new BehaviorSubject<any>("");
  public positionsharedata = new BehaviorSubject<any>("");
  public depthDataShare = new BehaviorSubject<any>("");
  public marketStatus = new BehaviorSubject<any>("");
  public backToHome = new BehaviorSubject<Boolean>(false);
  public notifyNos = new BehaviorSubject<any>("");




  webSocketData = this.webSocketDataService.asObservable();
  ordersMsgShare = this.executeOrderShare.asObservable();
  indexTickDataObservable = this.indexDataShare.asObservable();
  OTPgenerate = this.OTPGenerate.asObservable();
  orderPlaceTcp = this.orderGateway.asObservable();
  positionshare = this.positionsharedata.asObservable();
  depth = this.depthDataShare.asObservable();
  mktStatus = this.marketStatus.asObservable();
  backToChartToHome = this.backToHome.asObservable();
  badgeLengthshare = this.notifyNos.asObservable();



  constructor() { }
  // notifyLength(data) {
  //   this.notifyLength.next(data);
  // }

  notifyLength(data) {
    this.notifyNos.next(data);
  }

  marketStatusShare(data) {
    this.marketStatus.next(data);
  }

  depthData(data) {
    this.depthDataShare.next(data);
  }

  socketData(data) {
    this.webSocketDataService.next(data);
  }

  position(data) {
    this.positionsharedata.next(data);
  }

  otpGenerate(data) {
    this.OTPGenerate.next(data);
  }

  excuteOrders(placeOdr: Boolean) {
    this.executeOrderShare.next(placeOdr);
  }

  indexTickData(jsonobj) {
    this.indexDataShare.next(jsonobj);
  }

  orderGatewayTcp(jsonobj) {
    this.orderGateway.next(jsonobj);
  }

  goToBackHomePageMobileView(response) {
    // setTimeout(() => {
    console.log(response)
    this.backToHome.next(response)
    // }, 500);
  }
}

