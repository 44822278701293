import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from '../services/http.service';
import { NgxUiLoaderComponent } from 'ngx-ui-loader/lib/core/ngx-ui-loader.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-scripinfo',
  templateUrl: './scripinfo.component.html',
  styleUrls: ['./scripinfo.component.scss']
})
export class ScripinfoComponent implements OnInit {
  [x: string]: any;
  securityInfo: any = [];
  tikPrc: any;
  info: any;
  userId: any;
  exchange: any;
  expiryDate: any;
  ltt: any;
  StartDate: any;
  endDate: any;

  constructor(
    public dialogRef: MatDialogRef<ScripinfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public service: HttpService,
    public loader: NgxUiLoaderService) {
    // this.info = data;
    this.showSecurityInfo(data)
    this.userId = this.service.getUserId();
  }

  ngOnInit() {
  }


  showSecurityInfo(scrip) {
    this.loader.start()
    this.scrip = scrip;
    let jsonObj: any = {
      marketId: scrip['Market']['Id'],
      exchangeId: scrip['Exchange']['Id'],
      internalScripToken: scrip["Token"],
      userId: this.service.getUserId(),
    };
    this.service.depthNewRequest(jsonObj).subscribe((resp) => {
      if (resp["StatusOfRequest"]["Status"] == 0) {
        this.priceRange = resp["MarketDepthData"]["DPR"];
        this.depthData = resp['MarketDepthData'];
        this.ltt = this.depthData['LTT'];
        // this.;s
        this.expiryDate = resp['MarketDepthData']['ExpiryDate'] == "" ? "NA" : resp['MarketDepthData']['ExpiryDate'];
      }
    });
    let jsonData = {
      "userId": this.service.getUserId(),
      "isin": scrip['ISINno'],
      "exchangeId": scrip['Exchange']['Id'],
      "internalScripToken": scrip['Token'],
      "marketId": scrip['Market']['Id']
    }
    this.service.getSecurityInfo(jsonData).subscribe((res) => {
      this.loader.stop();
      if (res['StatusOfRequest']['Status'] == "0") {
        this.info = res['ScripDetails'];
        this.Symbol = this.info['Symbol']
        this.exchange = scrip['Exchange']['Name'];
        this.price = scrip['LTP'] == undefined || scrip['LTP'] == "" ? "0.00" : scrip['LTP'];
        var startDate: any = this.info['StartDate'] == undefined ? "NA" : this.info['StartDate'].split(" ")
        this.StartDate = startDate[0];
        var endDate = this.info['EndDate'] == undefined ? "NA" : this.info['EndDate'].split(" ")
        this.endDate = endDate[0];
      }
    }, (err) => {
      this.service.toastr.error(err.error);
      this.dialogRef.close(true);
    })
  }


  closeModelBox(): void {
    this.dialogRef.close();
  }
}
