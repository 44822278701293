import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EntrylevelRoutingModule } from './entrylevel-routing.module';
import { LoginComponent } from './login/login.component';
import { OtpauthenComponent } from './otpauthen/otpauthen.component';

import { ReactiveFormsModule } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule, MatButtonModule, MatSelectModule, MatIconModule, MatListModule, MatGridListModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';



@NgModule({
  declarations: [
    LoginComponent,
    OtpauthenComponent,
    ForgotpasswordComponent,
    ResetPasswordComponent,

    // LogoutpopupComponent
  ],
  imports: [
    CommonModule,
    EntrylevelRoutingModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    ReactiveFormsModule,
    CommonModule,
    MatButtonModule,
    FlexLayoutModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    FormsModule,
  ],
  entryComponents: [
    // LogoutpopupComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [HttpService]
})
export class EntrylevelModule { }
