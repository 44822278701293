import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from 'ngx-toastr';
import { debug } from 'util';
import { SharedataService } from 'src/app/services/sharedata.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OrdercancelComponent } from 'src/app/ordercancel/ordercancel.component';
import { OrderInfoComponent } from 'src/app/order-info/order-info.component';
import { OrderwindowComponent } from 'src/app/orderwindow/orderwindow.component';
import { Sort } from '@angular/material';
import { Time } from '@angular/common';

export interface orderlist {
  EntryDateTime: Time;
  OrderType: any;
  Symbol: any;
  Qty: Number;
  Price: Number;
  Status: any;
}

export interface allOrders {
  Time: Time;
  Market: any;
  Symbol: any;
  Qty: Number;
  Price: Number;
  Status: any;
}

export interface completedOrder {
  Time: Time;
  Market: any;
  Symbol: any;
  TradeQty: Number;
  TradedPrice: Number;
  Status: any;
}


export const constanttabs = [
  { tabs: "Pending", img: "assets/image/All.svg" },
  { tabs: "Completed", img: "assets/image/Completed.svg" },
  { tabs: "All Orders", img: "assets/image/Pending.svg" },
  // { tabs: "Orders", img: "assets/image/Pending.svg" }
];
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})

export class OrderComponent implements OnInit {
  userDetails: any = [];
  completedOrder: any = [];
  userID: any;
  emptytablemsg: boolean;
  entrytime: any;
  CPcode: any;
  showDatafound: boolean = true;
  Qty: any;
  // myModel: boolean;
  Trig: any;
  ordertabs: any = constanttabs;
  completedTrades: any;
  orderlist: any = [];
  sortedData: orderlist[];
  allSortedData: allOrders[];
  compSortedData: completedOrder[];
  // sortedData: any[];
  emptytablelist: boolean = false;
  selectedIndex: any = 0;
  orderDetails: any;
  activityLog: any;
  emptytablesegment: boolean = false;
  showOverAllExit: boolean = false;
  myModel: boolean = false;
  exitarray: any = [];
  openOrder: any = [];
  tempOpenOrders: any = [];
  temppositions: any;
  tcpResponse: any;
  ordertype: string;
  emptytable: boolean;
  // allOrders: any = [];
  orders: any[];
  orderStatus: string;
  ordertiktoken: any;
  allOrders: any = [];
  constructor(
    public httpService: HttpService,
    public dialog: MatDialog,
    public routeTo: Router,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
    public sharedata: SharedataService,

  ) {
    if (localStorage.getItem("OrderActiveTab") != undefined || localStorage.getItem("OrderActiveTab") != null) {
      this.selectedIndex = Number(localStorage.getItem("OrderActiveTab"));
    }
    if (localStorage.getItem("userdetails") != null || localStorage.getItem("userdetails") != undefined) {
      var userDetails = JSON.parse(localStorage.getItem("userdetails"));
      this.userDetails = userDetails;
      this.userID = this.userDetails['UserDetails']['UserID'];
    }
    this.getorderssegment(this.selectedIndex);

    this.sharedata.ordersMsgShare.subscribe((res: boolean) => {
      if (res) {
        this.getorderssegment(this.selectedIndex);
      }
    });
    this.sharedata.orderPlaceTcp.subscribe((msg: any) => {
      this.tcpResponse = msg
      this.allOrderList();
      // if (msg != "") {
      //   debugger
      //   if (msg[0] == "75" && msg[6] != "") {
      //     // this.orderinfo = true;
      //     for (let idx of this.activityLog) {
      //       if (idx['Symbol'] == msg[55] && idx['InternalOrderId'] == msg[2] && idx['OrderNumber'] == msg[8]) {
      //         idx['odr.status'] = msg[31];
      //       }
      //     }
      //     sessionStorage.setItem("orderStatus", JSON.stringify(this.activityLog));
      //   } else if (msg[0] == "57" && msg[12] != "") {
      //     // this.orderinfo = true;
      //     for (let idx of this.activityLog) {
      //       if (idx['InternalOrderId'] == msg[12]) {
      //         idx['rej.Reason'] = msg[3];
      //       }
      //     }
      //     sessionStorage.setItem("orderStatus", JSON.stringify(this.activityLog))
      //     //
      //   }
      // }
    });
    var notifyNos: any = 0;
    this.sharedata.notifyLength(notifyNos);
  }

  ngOnInit() {

  }

  /**
   * Method to select order page Tabs
   * @author Kalai
      * @param indexid 
   */
  getorderssegment(indexid) {
    this.myModel = false;
    this.exitarray = [];
    this.showDatafound = true;
    this.tempOpenOrders = [];
    localStorage.setItem("OrderActiveTab", indexid);
    this.selectedIndex = indexid;
    if (indexid == 0) {
      this.getOrderList();
    } else if (indexid == 1) {
      this.gettradelist();
    } else if (indexid == 2) {
      this.allOrderList();
    } else if (indexid == 3) {
      this.getactivityLog();
    }
  }


  /**
   * Method to get Order List
   * @author Dinesh
   * @on 17-03-2020
   */
  getOrderList() {
    this.orderStatus = "PENDING ORDER";
    let JsonData: any = {
      "internalLoginId": this.userDetails['UserDetails']['UserInternalID'],
      userId: this.userID,
      "orderType": "1",
      "orderStatus": "2"
    }
    this.loader.start();
    this.httpService.listOrder(JsonData).subscribe(resp => {
      this.loader.stop();
      this.showDatafound = true;
      if (resp['StatusOfRequest']['Status'] == "0" && resp['OrderList'].length > 1) {
        this.orderDetails = resp['OrderList'];
        if (resp['OrderList'].length > 1) {
          this.showDatafound = false;
          this.emptytablemsg = false;
          for (let index in this.orderDetails) {
            this.orderDetails[index]['Price'] = Number(this.orderDetails[index]['Price']).toFixed(2)
            if (this.orderDetails[index]['Trig.Price'] != "" && this.orderDetails[index]['Trig.Price'] > 0) {
              this.orderDetails[index]['Status'] = "Trig.pending";
            }
            this.orderDetails[index]['Status'] = "Open";
          }
          this.orderDetails.pop();
          this.orderlist = this.orderDetails;
          this.sortedData = this.orderlist;
          this.sortedData = this.orderlist.slice();
          this.tempOpenOrders = this.orderDetails;
        } else {
          this.showDatafound = true;
        }
      } else {
        this.showDatafound = true;
      }
    }, (err) => {
      if (err.error == 'Unauthorized') {
        this.httpService.unauthorized();
      }
    })
  }

  alertify() {
    this.httpService.alertpop();
  }

  /**
  * Method to get Trade List
  * @author Dinesh
  * @on 18-03-2020
  */
  gettradelist() {
    this.loader.start();
    this.orderStatus = "COMPLETE ORDER"
    let JsonData: any = {
      "internalLoginId": this.userDetails['UserDetails']['UserInternalID'],
      "userId": this.userID,
      "orderType": "-1"
    }

    this.httpService.tradelist(JsonData).subscribe(resp => {
      this.loader.stop();
      this.showDatafound = true;
      if (resp['StatusOfRequest']['Status'] == 0) {
        this.completedTrades = resp['TradeList'];
        if (this.completedTrades.length > 1) {
          this.showDatafound = false;
          for (let index in this.completedTrades) {
            this.completedTrades[index]['Status'] = "Completed";
            this.completedTrades[index]['TradedPrice'] = Number(this.completedTrades[index]['TradedPrice']).toFixed(2);
          }
        }
        this.completedTrades.pop();
        this.completedOrder = this.completedTrades;
        this.compSortedData = this.completedOrder.slice();
      } else if (resp['Message'] == "User has been logged off.") {
        this.httpService.unauthorized();
      }
    }, (err) => {
      if (err.error == 'Unauthorized') {
        this.httpService.unauthorized();
      }
    })
  }

  /**
* Method to Modify Order
* @param scrOrder
* @param clicTyp
* @author Kalai
* @on 10-03-2020
*/
  scripsPOrd(scrOrder, clicTyp) {
    if (clicTyp == "BUY") {
      scrOrder["Trantype"] = "B";
    } else {
      scrOrder["Trantype"] = "S";
    }
    this.ordertiktoken = scrOrder["Token"];

    switch (clicTyp) {
      case "BUY":
        this.orderWindow(clicTyp, scrOrder, "mktwatch");
        break;
      case "SELL":
        this.orderWindow(clicTyp, scrOrder, "mktwatch");
        break;

      case "modify":
        this.orderWindow(clicTyp, scrOrder, "orderModify");
    }
  }

  /**
   * Method to open the order Window Popup
   * @author Kalai
   * @on 13-03-2020
   */
  orderWindow(type, scrOrder, page) {
    const dialogRef = this.dialog.open(OrderwindowComponent, {
      width: "600px",
      autoFocus: false,
      height: "300px",
      data: { clicTyp: type, scrOrder: scrOrder, page: page },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  /**
   * Method to define check box event
   * @author Kalai
   * @param event
   * @param index 
   */
  selectPendingOrderCheck(event, index) {
    if (index == "" && event.checked) {
      this.exitarray = [];
      this.showOverAllExit = true;
      this.exitarray = this.tempOpenOrders;
    }
    if (index != "" && event.checked) {
      this.exitarray.push(index);
      if (this.exitarray.length > 0) {
        this.showOverAllExit = true;
      }
    }
    if (index != "" && !event.checked) {
      for (let idex of this.exitarray) {
        var idxid: number = this.exitarray.indexOf(idex);
        if (this.exitarray[idxid]['InternalScripToken'] == index['InternalScripToken'] && this.exitarray[idxid]['InternalOrderId'] == index['InternalOrderId']) {
          this.exitarray.splice(idxid, 1);
        }
      }
      if (this.exitarray.length > 0) {
        this.showOverAllExit = true;
      } else if (this.exitarray.length == 0) {
        this.showOverAllExit = false;
        this.myModel = false;
      }
    }
    if (index == "" && !event.checked) {
      this.showOverAllExit = false;
      this.exitarray = [];
    }
  }

  getactivityLog() {
    let jsonObj = {
      userId: this.httpService.getUserId()
    }
    this.httpService.getActivityLog(jsonObj).subscribe(resp => {
      this.activityLog = resp['ColumnData']
    })
  }

  /**
   *Method to get the Activity log list 
   @author Kalai
   @since 18-05-2020
   */
  allOrderList() {
    // let jsonObj = {
    //   userId: this.httpService.getUserId()
    // }
    // this.httpService.getActivityLog(jsonObj).subscribe(resp => {
    //   this.activityLog = resp['ColumnData']
    // })
    this.orderStatus = "ORDERS"
    this.loader.start();
    let JsonData = {
      "internalLoginId": this.httpService.getInternalId(),
      "userId": this.httpService.getUserId(),
      "orderType": "-1",
      "orderStatus": "2"
    }
    this.httpService.allOrderList(JsonData).subscribe(resp => {
      this.loader.stop();
      this.showDatafound = true
      if (resp.length > 0) {
        this.showDatafound = false;
        this.allOrders = resp;
        if (this.tcpResponse != "") {
          if (this.tcpResponse[0] == "75" && this.tcpResponse[6] != "") {
            // this.orderinfo = true;
            for (let idx of this.allOrders) {
              if (idx['Symbol'] == this.tcpResponse[55] && idx['InternalOrderId'] == this.tcpResponse[2] && idx['OrderNumber'] == this.tcpResponse[8]) {
                idx['odr.status'] = this.tcpResponse[31];
              }
            }
            sessionStorage.setItem("orderStatus", JSON.stringify(this.allOrders));
          } else if (this.tcpResponse[0] == "57" && this.tcpResponse[12] != "") {
            // this.orderinfo = true;
            for (let idx of this.allOrders) {
              if (idx['InternalOrderId'] == this.tcpResponse[12]) {
                idx['rej.Reason'] = this.tcpResponse[3];
              }
            }
            sessionStorage.setItem("orderStatus", JSON.stringify(this.allOrders))
            //
          }
        }

        for (let idx of this.allOrders) {
          idx['Price'] = Number(idx['Price']).toFixed(2);
        }
        this.allSortedData = this.allOrders;
        this.allSortedData = this.allOrders.slice();

      }

    }, (err) => {
      this, this.loader.stop();
    })
  }

  /**
   * Method to Exit through check box
   * @author Kalai
   * @since 22-05-2020
   */
  openAllOrderExitConfirmation() {
    const dialogRef = this.dialog.open(OrdercancelComponent, {
      width: '600px',
      data: { data: this.exitarray },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showOverAllExit = false;
        this.getOrderList();
      }
    });
  }



  /**
   * Method to call cancel orders service
   * @author Kalai
   * @since 19-05-202
   */
  openConfirmation(param) {
    const dialogRef = this.dialog.open(OrdercancelComponent, {
      width: '600px',
      data: { data: param },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getOrderList();
      }
    })
  }


  // scripsPOrd(scrOrder, clicTyp) {
  //   if (clicTyp == "BUY") {
  //     scrOrder["Trantype"] = "B";
  //   } else {
  //     scrOrder["Trantype"] = "S";
  //   }
  //   this.ordertiktoken = scrOrder["Token"];
  //   switch (clicTyp) {
  //     case "BUY":
  //       this.orderWindow(clicTyp, scrOrder, "mktwatch");
  //       break;
  //     case "SELL":
  //       this.orderWindow(clicTyp, scrOrder, "mktwatch");
  //       break;
  //     case "del":
  //       this.setSelectVal(scrOrder, clicTyp);
  //       break;
  //     case "modify":
  //       this.orderWindow(clicTyp, scrOrder, "orderModify");
  //   }
  // }


  /**
   * Method to Order Info POPup
   * @author  Kalai
   * @since 30-05-2020
      */
  orderInfo(data) {
    const dialogRef = this.dialog.open(OrderInfoComponent, {
      width: '800px',
      height: '430px',
      data: data
    });
  }

  /**
   * Method to Sort Open order table
   * @author Kalai
   * @since 19-06-2020
   */
  sortData(sort: Sort) {
    const data = this.orderlist.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'EntryDateTime': return this.compare(a.EntryDateTime, b.EntryDateTime, isAsc);
        case 'OrderType': return this.compare(a.OrderType, b.OrderType, isAsc);
        // case 'Buy/Sell': return this.compare(a.Buy/Sell, b.Buy/Sell, isAsc);
        case 'Symbol': return this.compare(a.Symbol, b.Symbol, isAsc);
        case 'Qty': return this.compare(a.Qty, b.Qty, isAsc);
        case 'Price': return this.compare(a.Price, b.Price, isAsc);
        case 'Status': return this.compare(a.Status, b.Status, isAsc);

        default: return 0;

      }
    });
  }

  /**
 * Method to Sort completed order table
 * @author Kalai
 * @since 19-06-2020
 */
  compSortData(sort: Sort) {
    const data = this.completedOrder.slice();
    if (!sort.active || sort.direction === '') {
      this.compSortedData = data;
      return;
    }
    this.compSortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Time': return this.compare(a.Time, b.Time, isAsc);
        case 'Market': return this.compare(a.Market, b.Market, isAsc);
        // case 'Buy/Sell': return this.compare(a.Buy/Sell, b.Buy/Sell, isAsc);
        case 'Symbol': return this.compare(a.Symbol, b.Symbol, isAsc);
        case 'TradeQty': return this.compare(a.TradeQty, b.TradeQty, isAsc);
        case 'TradedPrice': return this.compare(a.TradedPrice, b.TradedPrice, isAsc);
        case 'Status': return this.compare(a.Status, b.Status, isAsc);

        default: return 0;

      }
    });
  }

  /**
* Method to Sort All order table
* @author Kalai
* @since 19-06-2020
*/
  allSortData(sort: Sort) {
    const data = this.allOrders.slice();
    if (!sort.active || sort.direction === '') {
      this.allSortedData = data;
      return;
    }
    this.allSortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Time': return this.compare(a.Time, b.Time, isAsc);
        case 'Market': return this.compare(a.Market, b.Market, isAsc);
        // case 'Buy/Sell': return this.compare(a.Buy/Sell, b.Buy/Sell, isAsc);
        case 'Symbol': return this.compare(a.Symbol, b.Symbol, isAsc);
        case 'Qty': return this.compare(a.Qty, b.Qty, isAsc);
        case 'Price': return this.compare(a.Price, b.Price, isAsc);
        case 'Status': return this.compare(a.Status, b.Status, isAsc);

        default: return 0;

      }
    });
  }

  compare(a: number | any, b: number | any, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}


