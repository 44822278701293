import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  userInfo: any;
  changeValues: any;
  validSegment: any;
  validExchange: any;
  accountName: any[];
  emailAddr: any[];
  panNo: any[];
  cellAddr: any[];
  dpId: any[];
  bankAccountNo: any[];
  bankName: any[];
  name: any = '**********';
  constructor() { }

  ngOnInit() {
    this.userprofile()
  }
  userprofile() {
    this.userInfo = JSON.parse(localStorage.getItem("userdetails"));
    this.accountName = this.userInfo['UserDetails']['UserID'];
    this.validExchange = this.userInfo['ValidExchange'];
    this.validSegment = ['BO', 'CNC', 'CO', 'MIS', 'NRML']
    this.changeValues = ['L', 'MKT', 'SL', 'SL-M'];
    this.emailAddr = ['*************'];
    this.panNo = ['*167R'];
    this.cellAddr = ['1234567890'];
    this.dpId = ['*0400'];
    this.bankAccountNo = ['*7513'];
    this.bankName = ['***** BANK'];
  }
}
