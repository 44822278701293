import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { OtpauthenComponent } from './otpauthen/otpauthen.component';


import { ReactiveFormsModule } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule, MatButtonModule, MatSelectModule, MatIconModule, MatListModule, MatGridListModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'otpauthen', component: OtpauthenComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },

];


@NgModule({
  imports: [
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    CommonModule,
    MatButtonModule,
    FlexLayoutModule,
    // UserentryRoutingModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    FormsModule,
    RouterModule.forChild(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [HttpService]

})
export class EntrylevelRoutingModule { }
