import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SharedataService } from 'src/app/services/sharedata.service';
import { findLocaleData } from '@angular/common/src/i18n/locale_data_api';

@Component({
  selector: "app-otpauthen",
  templateUrl: "./otpauthen.component.html",
  styleUrls: ["./otpauthen.component.scss"],
})
export class OtpauthenComponent implements OnInit {
  otpForm: FormGroup;
  userId: any;
  notOKstat: any;
  timeLeft: number = 30;
  interval;
  tick = 1000;
  resendbtn: boolean = false;
  resetPwd: any;
  pwdDetails: any;
  timeInSeconds: any;
  time: any;
  runTimer: boolean;
  hasStarted: boolean;
  hasFinished: boolean;
  remainingTime: any;
  displayTime: string;


  constructor(
    public formBuilder: FormBuilder,
    private spinnerService: NgxUiLoaderService,
    public toastr: ToastrService,
    public httpService: HttpService,
    public routeTo: Router,
    public sharedata: SharedataService
  ) {
    if (
      localStorage.getItem("userid") != undefined ||
      localStorage.getItem("userid") != null
    ) {
      this.userId = JSON.parse(localStorage.getItem("userid"));
    }
  }
  ngOnInit() {
    if (localStorage.getItem("userdetails") != null || localStorage.getItem("userdetails")) {
      var userdetails = JSON.parse(localStorage.getItem("userdetails"));
      this.resetPwd = userdetails['PasswordExpiry'];
      this.pwdDetails = userdetails['PasswordExpiryDetails']
    }
    // this.timeLeft = 30;
    this.initTimer();
    this.startTimer();
    this.timerTick();
    this.createForm();
  }

  createForm() {
    this.otpForm = this.formBuilder.group({
      otp: ["", [Validators.required, Validators.minLength(4)]],
    });
  }

  getError(el) {
    switch (el) {
      case "otp":
        if (this.otpForm.get("otp").hasError("required")) {
          return "OTP required";
        }
        break;
      default:
        return "";
    }
  }

  /**
  * Method to generate otp
  */
  generateUserOTP() {
    let jsonData = {
      userId: this.userId,
    }
    this.spinnerService.start();
    this.httpService.generateMobileOTP(jsonData).subscribe((res: any) => {
      this.spinnerService.stop();
      if (res['StatusOfRequest']['status'] == 0) {
        this.resendbtn = false;
        this.timeInSeconds = 20;
        this.initTimer();
        this.startTimer();
        this.timerTick();
        this.toastr.success(res['StatusOfRequest']['Message']);
      }
    }, (err) => {
      this.spinnerService.stop();
    });
  }


  /**
   * Method to Verify OTP
   * @author kalai
   * @on 25/02/2020
   */
  sendOTP() {
    let jsonData = {
      userId: this.userId,
      otp2FA: this.otpForm.value.otp,
    };
    this.spinnerService.start();
    this.httpService.setUserOTP(jsonData).subscribe(
      (res: any) => {
        this.spinnerService.stop();
        if (res["status"] == "0" && res["SessionId"] != null) {
          // this.toastr.success("Success");
          localStorage.setItem("loginState", JSON.stringify(true));
          localStorage.setItem("sessionId", JSON.stringify(res["SessionId"]));
          if (this.resetPwd == "1" || this.resetPwd < 0) {
            this.httpService.toastr.error(this.pwdDetails['PasswordMessage']);
            this.routeTo.navigateByUrl('entrylevel/reset-password');
          } else {
            if (window.innerWidth > 720) {
              if (localStorage.getItem("userid") != undefined || localStorage.getItem("userid") != null) {
                this.routeTo.navigateByUrl('usercontrol/dashboard');
              } else {
                this.routeTo.navigateByUrl('entrylevel/login');
              }
            } else {
              if (localStorage.getItem("userid") != undefined || localStorage.getItem("userid") != null) {
                this.routeTo.navigateByUrl('mobilewindow/mobileview');
              } else {
                this.routeTo.navigateByUrl('entrylevel/login');
              }
            }
          }
        }
        if (res["status"] == "1") {
          this.notOKstat = res['Message']
          this.toastr.error(res["Message"]);
        }
      },
      (err) => {
        this.spinnerService.stop();
      }
    );
  }

  /**
   * Method to call the OTP timer
   */
  // setTimer() {
  //   this.interval = setInterval(() => {
  //     if (this.timeLeft == 0) {
  //       this.resendbtn = true;
  //     }
  //     if (this.timeLeft > 0) {
  //       this.timeLeft--;
  //     }
  //   }, 1000);
  // }


  initTimer() {
    if (!this.timeInSeconds) {
      this.timeInSeconds = 20;
    }

    this.time = this.timeInSeconds;
    this.runTimer = false;
    this.hasStarted = false;
    this.hasFinished = false;
    this.remainingTime = this.timeInSeconds;
    this.displayTime = this.getSecondsAsDigitalClock(this.remainingTime);
  }

  startTimer() {
    this.runTimer = true;
    this.hasStarted = true;
  }

  timerTick() {
    setTimeout(() => {

      if (!this.runTimer) { return; }
      this.remainingTime--;
      this.displayTime = this.getSecondsAsDigitalClock(this.remainingTime);
      if (this.remainingTime > 0) {
        this.timerTick();
      }
      else {
        this.hasFinished = true;
        this.resendbtn = true;
      }
    }, 1000);
  }


  getSecondsAsDigitalClock(inputSeconds: number) {
    var sec_num = parseInt(inputSeconds.toString(), 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    var minutesString = '';
    var secondsString = '';
    minutesString = (minutes < 10) ? "0" + minutes : minutes.toString();
    secondsString = (seconds < 10) ? "0" + seconds : seconds.toString();
    return minutesString + ':' + secondsString;
  }

}
