import { SharedataService } from '../services/sharedata.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpService } from '../services/http.service';
// import { DataTableDirective } from 'angular-datatables';

export interface DialogData {
  data: any;
}


@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.scss']
})
export class OrderInfoComponent implements OnInit {
  order: any;
  scripData: any;
  userId: any;
  orderinfo: boolean = false;
  type: any = 'info';
  noOderHis: boolean = false;

  constructor(
    public service: HttpService,
    public dialogRef: MatDialogRef<OrderInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public shareData: SharedataService
  ) {
    var tempData: any = JSON.parse(sessionStorage.getItem("orderStatus"));
    for (let idx in tempData) {
      if (this.data['Symbol'] == idx['Symbol'] && this.data['InternalOrderId'] == idx['InternalOrderId']) {
        this.data = tempData[idx]
      }
    }
    // this.data['odr.status'] = "";
    // this.shareData.orderPlaceTcp.subscribe((msg: any) => {
    //   console.log("orderStatus", msg);
    //   if (msg != "") {
    //     if (msg[0] == "75" && msg[6] != "") {
    //       // this.orderinfo = true;
    //       if (this.data['Symbol'] == msg[55]) {
    //         this.data['odr.status'] = msg[31];
    //       }
    //     } else if (msg[0] == "57" && msg[12] != "") {
    //       // this.orderinfo = true;
    //       if (this.data['InternalOrderId'] == msg[12]) {
    //         this.data['odr.status'] = msg[3];
    //       }
    //       //
    //     }
    //   } else {
    //     this.orderinfo = false;
    //   }
    // });
  }

  ngOnInit() {
    if (localStorage.getItem("userid") != null || localStorage.getItem("userid") != undefined) {
      this.userId = JSON.parse(localStorage.getItem("userid"));
    }
    this.getOrderHistory();
  }

  /**
   * Method to call the order history for the particular scrip
   * @author Kalai
   * @since 12-06-2020
   */
  getOrderHistory() {
    let jsonObj = {
      "userId": this.service.getUserId(),
      "orderNumber": this.data['OrderNumber'],
    }
    this.service.getOrderHistory(jsonObj).subscribe(resp => {
      if (resp['StatusOfRequest']['Status'] == "0") {
        if (resp['Orders'].length > 0) {
          this.noOderHis = false;
          this.scripData = resp['Orders'];
          for (let idx of this.scripData) {
            if (this.data['OrderNumber'] == idx['OrderNumber']) {
              this.data['odr.status'] = idx['Error']
            }
          }
        } else {
          this.noOderHis = true;
        }
      } else {
        this.service.toastr.error("Failed");
      }
    }, (err) => {
      this.service.toastr.error(err)
    })
  }

  closeModelBox(): void {
    this.dialogRef.close();
  }

  orderhistryType(type) {
    this.type = type;

  }

}
