import { Component, ElementRef, ViewChild, OnInit, OnDestroy, AfterViewInit, Input, HostListener, } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { ChartingLibraryWidgetOptions, IChartingLibraryWidget, widget } from 'src/assets/charting_library/charting_library.min';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from "src/app/services/http.service";
import { ToastrService } from 'ngx-toastr';
import { IndexlistComponent } from 'src/app/usercontrol/indexlist/indexlist.component';
import { OrderwindowComponent } from 'src/app/orderwindow/orderwindow.component';
import { AppComponent } from 'src/app/app.component';
import { SharedataService } from 'src/app/services/sharedata.service';
import { PositionconversionComponent } from 'src/app/positionconversion/positionconversion.component';
import { WebsocketService } from 'src/app/services/websocket.service';
import { Datafeed } from 'src/app/usercontrol/chart/datafeed';

export const constanttabs = [
  { tabs: "Pending", img: "../assets/image/All.svg" },
  { tabs: "Completed", img: "../assets/image/Completed.svg" },
  { tabs: "All Orders", img: "../assets/image/Pending.svg" }
];

@Component({
  selector: 'app-mobileview',
  templateUrl: './mobileview.component.html',
  styleUrls: ['./mobileview.component.scss']
})
export class MobileviewComponent implements OnInit {
  mw_one_tickPrice: any;
  mw_two_tickPrice: any;
  mw_three_tickPrice: any;
  mw_four_tickPrice: any;
  mw_five_tickprice: any;
  userID: any;
  indexName1: string;
  indexName2: string;
  idx_value1: any;
  idx_change1: any;
  idx_value2: any;
  idx_change2: any;
  indexNames: any;
  indexSegment: any;
  segment1: any;
  segment2: any;
  searchbox: string;
  indexSource: any;
  source1: any;
  source2: any;
  showList: boolean;
  searchList: any[];
  positionsvisiblemsg: boolean = false;
  showFilter: boolean;
  scripData: any = [];
  scripsmkt: any = [];
  nomktwtc: boolean;
  marketId: any;
  userDetails: any;
  mwDetails: any;
  defaultMw: any;
  mw_one: string;
  mw_two: string;
  mw_three: string;
  mw_four: string;
  mw_five: string;
  currGp: number;
  selectedItem: any;
  chartvisble: boolean;
  mktlistload: boolean;
  position: any;
  positionsvisible: boolean;
  totalvalue: any;
  ordertiktoken: any;
  fundsDetails: any;
  DepositRemaining: any;
  LedgerDeposit: any;
  UsedMargin: any;
  CommodityCollateral: any;
  CurrencyCollateral: any;
  DerivativeCollateral: any;
  EquityCollateral: any;
  MTMmargin: any;
  AdhocDeposit: any;
  fundvisiblemsg: boolean;
  holdingvisiblemsg: boolean = false;
  orderList: any = [];
  emptytablemsg: boolean = false;
  entrytime: any;
  CPcode: any;
  ordervisiblemsg: boolean = true;
  Qty: any;
  mwList: any;
  Trig: any;
  ordertabs: any = constanttabs;
  completedTrades: any;
  orderlist: any = [];
  emptytablelist: boolean = false;
  selectedIndex: any = 0;
  orderDetails: any;
  emptytablesegment: boolean = false;
  showOverAllExit: boolean = false;
  myModel: boolean = false;
  exitarray: any[];
  openOrder: any[];
  tempOpenOrders: any[];
  temppositions: any;
  ordertype: string;
  emptytable: boolean;
  allOrders: any = [];
  completedOrder: any;
  orderStatus: string;
  pnlMargin: any;
  holdings: any;
  holdingvisible: boolean = false;
  leftindexchart: any = 1;
  rightindexchart: any = 2;
  // private _symbol: ChartingLibraryWidgetOptions['symbol'] = 'NIFTY 50::NSE::Index';
  private _symbol: ChartingLibraryWidgetOptions['symbol'] = 'INFY::NSE';
  private _interval: ChartingLibraryWidgetOptions['interval'] = 'D';
  // BEWARE: no trailing slash is expected in feed URL
  private _datafeedUrl = HttpService.chartURL;
  private _libraryPath: ChartingLibraryWidgetOptions['library_path'] = '../assets/charting_library/';
  private _fullscreen: ChartingLibraryWidgetOptions['fullscreen'] = false;
  private _autosize: ChartingLibraryWidgetOptions['autosize'] = true;
  private _containerId: ChartingLibraryWidgetOptions['container_id'] = 'tv_chart_container';
  private _tvWidget: IChartingLibraryWidget | null = null;
  private _chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'] = 'https://saveload.tradingview.com';


  constructor(
    public routeTo: Router,
    public service: HttpService,
    public websocket: WebsocketService,
    public dialog: MatDialog,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
    public appcomponent: AppComponent,
    public shareData: SharedataService,
  ) {
    if (window.innerWidth > 720) {
      if (localStorage.getItem("userid") != undefined || localStorage.getItem("userid") != null) {
        this.routeTo.navigateByUrl('usercontrol/dashboard');
      } else {
        this.routeTo.navigateByUrl('login');
      }
    } else {
      if (localStorage.getItem("userid") != undefined || localStorage.getItem("userid") != null) {
        this.routeTo.navigateByUrl('/mobilewindow/mobileview');
      } else {
        this.routeTo.navigateByUrl('login');
      }
    }

    // TCP response
    this.shareData.webSocketData.subscribe((msg: any) => {
      for (let idx in this.scripsmkt) {
        if (msg[4] != "" && msg[8] != "") {
          if (msg[4] == this.scripsmkt[idx]['Token']) {
            // console.log("matched", msg[37], msg[8])
            this.scripsmkt[idx]['LTP'] = msg[8];
          }
        } else if (msg[4] != "" && msg[8] != "") {
          if (msg[4] == this.scripsmkt[idx]['Token']) {
            // console.log("matched", msg[37], msg[8])
            this.scripsmkt[idx]['LTP'] = msg[8];
          }
        }
      }
      // } else if (msg.length == "45") {
      //   for (let idx in this.scripsmkt) {
      //     if (msg[4] != "" && msg[8] != "") {
      //       if (msg[4] == this.scripsmkt[idx]['Token']) {
      //         console.log("matched", msg[37], msg[8])
      //         this.scripsmkt[idx]['LTP'] = msg[8];
      //       }
      //     }
      //   }
      // }
    });

    this.shareData.depthDataShare.subscribe((msg: any) => {//Depth
      if (msg[0] == "60") {
        if (msg[4] != "") {
          this.scripsmkt.map(item => {
            if (item['Token'] == msg[4]) {
              // console.log("depth", item['Symbol'], msg);
              item['bno'] = msg[17];
              item['bno1'] = msg[20];
              item['bno2'] = msg[23];
              item['bno3'] = msg[26];
              item['bno4'] = msg[29];
              item['bp'] = msg[16];
              item['bp1'] = msg[19];
              item['bp2'] = msg[22];
              item['bp3'] = msg[25];
              item['bp4'] = msg[28];
              item['bq'] = msg[15];
              item['bq1'] = msg[18];
              item['bq2'] = msg[21];
              item['bq3'] = msg[24];
              item['bq4'] = msg[27];
              item['sno'] = msg[32];
              item['sno1'] = msg[35];
              item['sno2'] = msg[38];
              item['sno3'] = msg[41];
              item['sno4'] = msg[44];
              item['sp'] = msg[31];
              item['sp1'] = msg[34];
              item['sp2'] = msg[37];
              item['sp3'] = msg[40];
              item['sp4'] = msg[43];
              item['bs'] = msg[30];
              item['bs1'] = msg[33];
              item['bs2'] = msg[36];
              item['bs3'] = msg[39];
              item['bs4'] = msg[42];
              item['tbq'] = msg[45];
              item['tsq'] = msg[46];
              item["v"] = msg[7] == undefined ? '0' : msg[7];
              item["ap"] = msg[14];
              item['weightAvgPrc'] = msg[14] == undefined ? '0' : msg[14];
              item['TTV'] = msg[60];
              item['LTT'] = msg[13];
              item['OI'] = msg[62];
              item['LUT'] = msg[59];
              item['DPR'] = msg[61];
            }
          })
        }
      }
    });
    this.shareData.indexDataShare.subscribe((msg: any) => { //Index 
      if (msg[0] == "69") {
        // console.log(msg)
      }
    });
  }


  ngOnInit() {
    this.loader.start();
    if (
      localStorage.getItem("userdetails") != null ||
      localStorage.getItem("userdetails") != undefined
    ) {
      var userDetails = JSON.parse(localStorage.getItem("userdetails"));
      this.userDetails = userDetails;
      this.userID = this.userDetails["UserDetails"]["UserID"];
      this.mwDetails = this.userDetails["MarketWatchDetails"];
      this.indexNames = this.userDetails["IndexNames"];
      this.indexSource = this.userDetails["IndexSources"];
      this.indexSegment = this.userDetails["IndexSegments"];
      this.defaultMw = this.userDetails["DefaultMarketWatch"];

      //Market watch name
      this.mw_one = this.userID + "_111";
      this.mw_two = this.userID + "_121";
      this.mw_three = this.userID + "_131";
      this.mw_four = this.userID + "_141";
      this.mw_five = this.userID + "_151";
      this.initialMarketWatchGroupName();
      // this.getMarketID();
      // this.getMarketWatch();
      this.getIndexRecords();

      this.loader.stop();
    }
    this.positionlist();
    this.getFunds();
    this.getHoldingList();
    this.getOrderList()
    this.gettradelist()
    // this.getorderssegment(this.selectedIndex);
  }

  /**
   * Method to Initialize market watch 
   */
  initialMarketWatchGroupName() {
    var userDetails: any = JSON.parse(localStorage.getItem("userdetails"));
    var marketWatchList = userDetails['MarketWatchDetails'];
    for (let index of marketWatchList) {
      var mwuserid = index['MarketWatchName'].split("_")[0];
      var mwusername = index['MarketWatchName'].split("_")[1];
      if (mwuserid) {
        if (mwuserid == this.userID) {
          if (mwusername == "111") {
            this.mw_one = index['MarketWatchID'];
          }
          if (mwusername == "121") {
            this.mw_two = index['MarketWatchID'];
          }
          if (mwusername == "131") {
            this.mw_three = index['MarketWatchID'];
          }
          if (mwusername == "141") {
            this.mw_four = index['MarketWatchID'];
          }
          if (mwusername == "151") {
            this.mw_five = index['MarketWatchID'];
          }
        }
      }
    }
    if (this.defaultMw == this.mw_one || this.defaultMw == this.mw_two || this.defaultMw == this.mw_three || this.defaultMw == this.mw_four || this.defaultMw == this.mw_five) {
      this.marketId = this.defaultMw;
      if (this.defaultMw == this.mw_one) {

        this.currGp = 0;
      }
      if (this.defaultMw == this.mw_two) {
        this.currGp = 1;
      }
      if (this.defaultMw == this.mw_three) {
        this.currGp = 2;
      }
      if (this.defaultMw == this.mw_four) {
        this.currGp = 3;
      }
      if (this.defaultMw == this.mw_five) {
        this.currGp = 4;
      }
      this.getMarketWatch(this.defaultMw);
    } else {
      this.createMarketWatch(this.mw_one);
    }

    // let isExist = this.mwDetails.find(mw => mw['MarketWatchName'] === this.mw_one || mw['MarketWatchName'] === this.mw_two || mw['MarketWatchName'] === this.mw_three || mw['MarketWatchName'] === this.mw_four || mw['MarketWatchName'] === this.mw_five);
    // if (isExist) {
    //   for (let idx of this.mwDetails) {
    //     if (idx['MarketWatchID'] == this.userDetails['DefaultMarketWatch']) {
    //       this.getMarketWatch(this.userDetails['DefaultMarketWatch']);
    //     }
    //   }
    // } else {
    //   this.createMarketWatch(this.mw_one);
    // }
  }

  /**
  * Method to Create Market Watch
  * @param value
  * @author Kalai
  * @on 03/03/2020
  */
  createMarketWatch(value) {
    this.mktlistload = true;
    this.selectedItem = value;
    let jsonObj: any = {
      internalLoginId: this.userDetails["UserDetails"]["UserInternalID"],
      marketWatchName: this.selectedItem,
      userId: this.userID,
    };
    this.service.createMW(jsonObj).subscribe(
      (resp) => {
        if (resp["StatusOfRequest_a"]["status"] == 0 && resp["StatusOfRequest_b"]["status"] == 0) {
          this.marketId = resp['MarketWatchColumnData']['MWID'];
          // If you success response to put 
          var responObj = {
            Exchange: { Id: "0", Name: "All" },
            Field4: "0.0",
            MarketId: { Id: "0", Name: "ALL" },
            MarketType: resp['MarketWatchColumnData']['MWType'],
            MarketWatchID: resp['MarketWatchColumnData']['MWID'],
            MarketWatchName: resp['MarketWatchColumnData']['MWName'],
            MarketWatchSize: "293",
          }
          var userDetails = JSON.parse(localStorage.getItem("userdetails"));
          userDetails['MarketWatchDetails'].push(responObj);
          localStorage.setItem("userdetails", JSON.stringify(userDetails));
          this.createDefaultMW(this.marketId);

          localStorage.setItem("currentGroup", JSON.stringify(this.selectedItem));
        }
        // else {
        //   // this.createDefaultMW();
        //   this.getMarketWatch(value);
        //   console.log(resp["StatusOfRequest_a"]["Message"]);
        // }
      },
      (err) => {
        if (err.error == "Unauthorized") {
          this.service.unauthorized();
        }
      }
    );
  }

  /**
  * Method to find the Search term
  * @param value
  * @author Kalai
  * @on 05-03-2020
  */
  createDefaultMW(value) {
    this.marketId = value;
    let JsonData: any = {
      marketWatchId: this.marketId,
      userId: this.userID,
    };
    this.service.defaulatMarketWatch(JsonData).subscribe(
      (resp) => {
        if (resp['Status'] == '0') {
          var userDetails = JSON.parse(localStorage.getItem("userdetails"));
          userDetails['DefaultMarketWatch'] = value;
          localStorage.setItem("userdetails", JSON.stringify(userDetails))
          this.getMarketWatch(value);
        }
      },
      (err) => {
        if (err.error == "Unauthorized") {
          this.service.unauthorized();
        }
      }
    );
  }


  /**
    * Method to show the Index List
    * @author Kalai
    * @on 06/03/202
    */
  showIndexList(position) {
    const dialogRef = this.dialog.open(IndexlistComponent, {
      width: "380px",
      height: "80%",
      data: {
        idxList: this.indexNames,
        position: position,
        nifname: this.indexName1,
        senxname: this.indexName2,
      },
      position: { left: "0", top: "50px" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getIndexRecords();
      }
    });
  }

  /**
  * Method to get index details
  * @author Kalai
  * @since 30-03-2020
  */
  getIndexRecords() {
    let JsonData: any = {
      userId: this.userID,
    };
    this.service.getIndexDetails(JsonData).subscribe((resp) => {
      var result: any = resp;
      if (resp["stat"] == null) {
        this.getIndex("Nifty 50", "Nifty Bank");
      } else if (resp["stat"] == "success") {
        this.indexName1 = "Nifty 50";
        this.indexName2 = "Nifty Bank";
        if (resp["result"].length > 0) {
          for (let idx of result["result"]) {
            if (idx["position"] == 1) {
              this.indexName1 = idx["indexName"];
              this.idx_value1 =
                idx["indexValue"] == null ? "0.00" : idx["indexValue"];
              this.idx_change1 =
                idx["indexChange"] == null ? "0.00" : idx["indexChange"];
            }
            if (idx["position"] == 2) {
              this.indexName2 = idx["indexName"];
              this.idx_value2 =
                idx["indexValue"] == null ? "0.00" : idx["indexValue"];
              this.idx_change2 =
                idx["indexChange"] == null ? "0.00" : idx["indexChange"];
            }
          }
          // this.getIndex(this.indexName1, this.indexName2);
        } else {
          this.getIndex("Nifty 50", "Nifty Bank");
        }
      }
    });
  }
  /**
  * Method to add Index
  * @author Kalai
  * @on 05-03-2020
  */
  getIndex(value1, value2) {
    var data: any = [];
    var index_pos1: any;
    var index_pos2: any;
    for (let name in this.indexNames) {
      if (value1 == this.indexNames[name]) {
        index_pos1 = name;
        this.indexName1 = this.indexNames[name];
      } else if (value2 == this.indexNames[name]) {
        index_pos2 = name;
        this.indexName2 = this.indexNames[name];
      }
    }
    for (let segment in this.indexSegment) {
      if (index_pos1 == segment) {
        this.segment1 = this.indexSegment[segment];
      } else if (index_pos2 == segment) {
        this.segment2 = this.indexSegment[segment];
      }
    }
    for (let source in this.indexSource) {
      if (index_pos1 == source) {
        this.source1 = this.indexSource[source];
      } else if (index_pos2 == source) {
        this.source2 = this.indexSource[source];
      }
    }
    data.push(
      this.source1 + "^" + this.segment1 + "^" + this.indexName1,
      this.source2 + "^" + this.segment2 + "^" + this.indexName2
    );
    let JsonObj: any = {
      userId: this.userID,
      indexes: data,
    };
    this.service.addIndexToList(JsonObj).subscribe(
      (response) => {
        var result: any = Object.keys(response);
        if (response["StatusRequest"]["Status"] == 0) {
          if (result.length > 0) {
            if (result[1] == "IndexDetails_1") {
              this.idx_change1 =
                response["IndexDetails_1"]["CurrentindicesChangeValue_1"];
              this.idx_value1 =
                response["IndexDetails_1"]["CurrentindicesValue_1"];
            }
            if (result[2] == "IndexDetails_2") {
              this.idx_change2 =
                response["IndexDetails_2"]["CurrentindicesChangeValue_2"];
              this.idx_value2 =
                response["IndexDetails_2"]["CurrentindicesValue_2"];
            }
          }
        } else {
          this.service.toastr.error(response["StatusRequest"]);
        }
      },
      (err) => {
        if (err.error == "Unauthorized") {
          this.service.unauthorized();
        }
      }
    );
  }

  /**
 * Method to find the Search term
 * @param value
 * @author Kalai
 * @on 03-03-2020
 */
  getsearchItems(value) {
    var searchvalue: any = value;
    if (searchvalue.length > 2) {
      var jsonvalue = {
        symbol: searchvalue,
        exchange: "all",
      };
      this.service.searchSymbol(jsonvalue).subscribe(
        (response) => {
          if (response["stat"] == "success") {
            this.searchList = response["result"];
            localStorage.setItem("symbolList", JSON.stringify(this.searchList));
            this.showList = true;
            for (let idx of this.searchList) {
              var indxid = this.searchList.indexOf(idx);
              this.searchList[indxid]["checked"] = false;
            }
          }
        },
        (err) => {
          if (err.error == "Unauthorized") {
            this.service.unauthorized();
          }
        }
      );
    }
  }

  /**
   * Method to Call the Depth request for Market Watch
   * @param event
   * @author Kalai
   * @on 05-03-2020
   */
  showDepth(scrips, i, type) {
    var previndex = i;
    this.scripsmkt.map((item) => {
      if (item["Token"] == scrips["Token"]) {
        if (item["showDP"]) {
          item["showDP"] = false;
        } else {
          item["showDP"] = true;
        }
      }
    });
    //To get the segID and exchID by matching token from mw
    var exchID: any;
    var segID: any;
    var internal_scripId: any;
    for (let idx in this.scripData) {
      if (this.scripData[idx]["token"] == scrips["Token"]) {
        exchID = this.scripData[idx]["exchangeId"];
        segID = this.scripData[idx]["segmentId"];
        internal_scripId = this.scripData[idx]["internalScripId"];
      }
    }
    let jsonObj: any = {
      segmentId: exchID,
      exchangeId: segID,
      internalScripToken: scrips["Token"],
      userId: this.userID,
    };
    this.service.depthNewRequest(jsonObj).subscribe(
      (resp) => {
        if (resp["StatusOfRequest"]["Status"] == 0) {
          this.scripsmkt.map((item) => {
            if (scrips["Token"] == item["Token"]) {
              item["bno"] = resp["MarketDepthData"]["BuyOrder_1"];
              item["bno1"] = resp["MarketDepthData"]["BuyOrder_2"];
              item["bno2"] = resp["MarketDepthData"]["BuyOrder_3"];
              item["bno3"] = resp["MarketDepthData"]["BuyOrder_4"];
              item["bno4"] = resp["MarketDepthData"]["BuyOrder_5"];
              item["bp"] = resp["MarketDepthData"]["BuyPrice_1"];
              item["bp1"] = resp["MarketDepthData"]["BuyPrice_2"];
              item["bp2"] = resp["MarketDepthData"]["BuyPrice_3"];
              item["bp3"] = resp["MarketDepthData"]["BuyPrice_4"];
              item["bp4"] = resp["MarketDepthData"]["BuyPrice_5"];
              item["bq"] = resp["MarketDepthData"]["BuyQty_1"];
              item["bq1"] = resp["MarketDepthData"]["BuyQty_2"];
              item["bq2"] = resp["MarketDepthData"]["BuyQty_3"];
              item["bq3"] = resp["MarketDepthData"]["BuyQty_4"];
              item["bq4"] = resp["MarketDepthData"]["BuyQty_5"];
              item["sno"] = resp["MarketDepthData"]["SellOrder_1"];
              item["sno1"] = resp["MarketDepthData"]["SellOrder_2"];
              item["sno2"] = resp["MarketDepthData"]["SellOrder_3"];
              item["sno3"] = resp["MarketDepthData"]["SellOrder_4"];
              item["sno4"] = resp["MarketDepthData"]["SellOrder_5"];
              item["sp"] = resp["MarketDepthData"]["SellPrice_1"];
              item["sp1"] = resp["MarketDepthData"]["SellPrice_2"];
              item["sp2"] = resp["MarketDepthData"]["SellPrice_3"];
              item["sp3"] = resp["MarketDepthData"]["SellPrice_4"];
              item["sp4"] = resp["MarketDepthData"]["SellPrice_5"];
              item["bs"] = resp["MarketDepthData"]["SellQty_1"];
              item["bs1"] = resp["MarketDepthData"]["SellQty_2"];
              item["bs2"] = resp["MarketDepthData"]["SellQty_3"];
              item["bs3"] = resp["MarketDepthData"]["SellQty_4"];
              item["bs4"] = resp["MarketDepthData"]["SellQty_5"];
              item["tbq"] = resp["MarketDepthData"]["Tot.BuyQty"];
              item["tsq"] = resp["MarketDepthData"]["Tot.SellQty"];
              item["v"] = resp["MarketDepthData"]["VolumeTradedToday"];
              item["ap"] = resp["MarketDepthData"]["weightedavg"];
              item["TTV"] = resp["MarketDepthData"]["TTV"];
            }
          });
        }
      },
      (err) => {
        if (err.error == "Unauthorized") {
          this.service.unauthorized();
        }
      }
    );
  }



  //Method to close menu
  closeItems() {
    if (this.showList != false) {
      setTimeout(() => {
        this.searchbox = "";
        this.searchList = [];
        this.showFilter = false;
      }, 300);
    } else {
      this.showList = false;
    }
  }

  /**
   * Method to select the market watch tabs
   */
  selectTabs(value) {
    if (value == 'chart') {
      this.chartvisble = true;
    } else {
      this.chartvisble = false;
    }
  }

  /**
  * Method to get Market Watch list
  * @author Kalai
  * @on 29/02/2020
  */
  getMarketWatch(value) {
    this.marketId = value;
    let JsonObj: any = {
      marketWatchId: this.marketId,
      userId: this.userID,
    };
    this.service.marketWatch(JsonObj).subscribe(
      (response) => {
        if (response["StatusOfRequest_a"]["Status"] == 0) {
          if (response["MarketWatchScrips"].length) {
            this.scripsmkt = response["MarketWatchScrips"];
            this.scripData = response["MarketWatchScripsDetails"];
            this.mwList = ""
            var scripToken: any = [];
            var token: any = [];
            var symbol: any = [];
            for (let idx of this.scripData) {
              scripToken.push(idx['InternalScripToken'] + "^" + idx['ExchangeId'] + "^" + idx['MarketId']);
            }
            this.appcomponent._marketId = this.marketId;
            this.appcomponent._isMWCall = scripToken.join("~");
            this.appcomponent.subscribeMessage();
            // debugger
            for (let idx of this.scripsmkt) {
              token.push(idx['Token']);
              symbol.push(idx['Symbol']);
            }
            this.getScripTickSize(token, symbol);
            if (this.scripsmkt.length > 0) {
              this.nomktwtc = false;
            } else {
              this.nomktwtc = true;
            }
            localStorage.setItem("mwDetails", JSON.stringify(response));
          } else {
            this.nomktwtc = true;
            console.log("Please add the scrip");
          }
        } else {
          if (response['StatusOfRequest_a']['Message'] == "User has been logged off") {
            this.service.unauthorized();
          } else {
            this.nomktwtc = true;
          }
        }
      },
      (err) => {
        if (err.error == "Unauthorized") {
          this.service.unauthorized();
          this.appcomponent._marketId = this.marketId;
          this.appcomponent.mwClose();
        }
      }
    );
  }

  //Footer
  footerMWGroup(event) {
    this.appcomponent.mwClose()
    switch (event.index) {
      case 0:
        var mwuserid = this.mw_one.split("_")[0];
        var mwusername = this.mw_one.split("_")[1];
        if (mwuserid) {
          if (mwuserid == this.userID) {
            this.createMarketWatch(this.mw_one)
          } else {
            this.createDefaultMW(this.mw_one)
          }
        } else {
          this.createDefaultMW(this.mw_one)
        }
        // localStorage.setItem("currMwIndex", JSON.stringify(0));
        // for (let idx of this.mwDetails) {
        //   if (idx['MarketWatchName'] === this.mw_one) {
        //     this.getMarketWatch(idx['MarketWatchID'])
        //   } else {
        //     this.createMarketWatch(this.mw_one);
        //   }
        // }
        break;
      case 1:
        var mwuserid = this.mw_two.split("_")[0];
        var mwusername = this.mw_two.split("_")[1];
        if (mwuserid) {
          if (mwuserid == this.userID) {
            this.createMarketWatch(this.mw_two)
          } else {
            this.createDefaultMW(this.mw_two)
          }
        } else {
          this.createDefaultMW(this.mw_two)
        }
        break;
      case 2:
        var mwuserid = this.mw_three.split("_")[0];
        var mwusername = this.mw_three.split("_")[1];
        if (mwuserid) {
          if (mwuserid == this.userID) {
            this.createMarketWatch(this.mw_three)
          } else {
            this.createDefaultMW(this.mw_three)
          }
        } else {
          this.createDefaultMW(this.mw_three)
        }
        break;
      case 3:
        var mwuserid = this.mw_four.split("_")[0];
        var mwusername = this.mw_four.split("_")[1];
        if (mwuserid) {
          if (mwuserid == this.userID) {
            this.createMarketWatch(this.mw_four)
          } else {
            this.createDefaultMW(this.mw_four)
          }
        } else {
          this.createDefaultMW(this.mw_four)
        }
        break;
      case 4:
        var mwuserid = this.mw_five.split("_")[0];
        var mwusername = this.mw_five.split("_")[1];
        if (mwuserid) {
          if (mwuserid == this.userID) {
            this.createMarketWatch(this.mw_five)
          } else {
            this.createDefaultMW(this.mw_five)
          }
        } else {
          this.createDefaultMW(this.mw_five)
        }
        break;
      default:
        break;
    }
  }

  getPositions() {
    this.loader.start();
    let JsonData: any = {
      userId: this.userID,
      "exchangeId": "0",
      "marketId": "0",
      "segmentId": "0",
      "instrumentType": "",
      "optionType": "",
      "group": "NA",
      "aggregate": "0",
      "strikePrice": ""
    }
    this.service.positionlists(JsonData).subscribe(resp => {
      this.loader.stop();
      if (resp['StatusOfRequest']['status'] == "0") {
        this.position = resp['PositionData'];
        if (this.position.length > 1) {
          // this.positionsvisible = true;
          this.positionsvisiblemsg = false;
          this.position = resp['PositionData'];
          for (let index in this.position) {
            this.position[index]['C/FQty'] = Number(this.position[index]['C/FQty']);
            this.position[index]['C/FAvgPr'] = Number(this.position[index]['C/FAvgPr']);
            this.position[index]['M2M'] = Number(this.position[index]['M2M']);
            this.position[index]['checked'] = false;
            this.totalvalue += Number(this.position[index]['M2M']);
          }
          this.position.pop();
          this.totalvalue = this.totalvalue.toFixed(2);
          if (this.position.length > 1) {
            this.positionsvisiblemsg = false;
          } else {
            // this.showDatafound = true;
          }
        }
      } else {
        this.positionsvisiblemsg = true;
        // this.toastr.error("Error");
      }

    },
      (err) => {
        if (err.error == "Unauthorized") {
          this.service.unauthorized();
        }
      })
  }


  /**
   * Method to logout
   * @author Kalai
   * @on 02-03-2020
   */
  showLogoutAlert() {
    localStorage.clear();
    this.routeTo.navigateByUrl('entrylevel/login');
    this.service.logoutUser();
    // const dialogRef = this.dialog.open(LogoutpopupComponent, {
    //   width: "200px",
    // });
    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result) {
    //     localStorage.clear();
    //     this.routeTo.navigateByUrl("/usercontrol/login");
    //   }
    // });
  }

  //close side menu
  closeNav() {
    document.getElementById("mySidepanel").style.width = "0px";
    if (sessionStorage.getItem("currMwIndex") != null || sessionStorage.getItem("currMwIndex") != undefined) {
      this.currGp = JSON.parse(sessionStorage.getItem("currMwIndex"));
      // console.log(this.currGp)
    }
    if (sessionStorage.getItem("currentGroup") != null || sessionStorage.getItem("currentGroup") != undefined) {
      this.selectedItem = JSON.parse(sessionStorage.getItem("currentGroup"));
    }
    if (sessionStorage.getItem("localMW") != null || sessionStorage.getItem("localMW") != undefined) {
      this.scripsmkt = JSON.parse(sessionStorage.getItem("localMW"));
    }
  }

  //open side menu
  openNav() {
    document.getElementById("mySidepanel").style.width = "250px";
  }

  /**
  * Method to placescripsOrder/DelOrder from MKTList
  * @param scrOrder
  * @param clicTyp
  * @author Kalai
  * @on 10-03-2020
  */
  scripsPOrd(scrOrder, clicTyp) {
    if (clicTyp == "BUY") {
      scrOrder["Trantype"] = "B";
    } else {
      scrOrder["Trantype"] = "S";
    }
    this.ordertiktoken = scrOrder["Token"];
    switch (clicTyp) {
      case "BUY":
        this.orderWindow(clicTyp, scrOrder, "mktwatch");
        break;
      case "SELL":
        this.orderWindow(clicTyp, scrOrder, "mktwatch");
        break;
      case "del":
        this.setSelectVal(scrOrder, clicTyp, '');
        break;
      case "modify":
        this.orderWindow(clicTyp, scrOrder, "orderModify");
    }
  }


  /**
   * Method to open the order Window Popup
   * @author Kalai
   * @on 13-03-2020
   */
  orderWindow(type, scrOrder, page) {
    const dialogRef = this.dialog.open(OrderwindowComponent, {
      width: "500px",
      autoFocus: false,
      maxWidth: "100vw",
      height: "900px",
      data: { clicTyp: type, scrOrder: scrOrder, page: page },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.allOrders()
    });
  }

  /**
   * Method to Add scrip to market watch
   * @author Kalai
   * @on 02-03-2020
   */
  setSelectVal(slctData, edit, list) {
    let jsonObj: any = {
      mwId: this.marketId,
      marketId: slctData.marketId,
      exchangeId: slctData.exchangeId,
      internalScripToken: slctData.internalToken,
      symbol: slctData.symbol,
      series: slctData.series == "EQ" ? slctData.series : "",
      instrumentName: slctData.series == "EQ" ? "" : slctData.instrumentName,
      expriyDate: slctData.series == "EQ" ? "NA" : slctData.expiryDate,
      strikePrice: (slctData.series == "EQ") || (slctData.InstrumentType == 'FUTSTK') ? "" : slctData.exchange == "CDS" ? slctData.strikePrice * 10000 : slctData.strikePrice * 100,
      optionType: slctData.series == "EQ" ? "" : slctData.optionType,
      userId: this.userID,
    };

    if (edit == "add") {
      this.service.addScripToMW(jsonObj).subscribe(
        (resp) => {
          if (resp["StatusOfRequest_a"]["status"] == 0) {
            this.showList = false;
            if (
              localStorage.getItem("symbolList") != null || localStorage.getItem("symbolList")
            ) {
              this.searchList = JSON.parse(localStorage.getItem("symbolList"));
            }
            if (this.searchList.length > 0) {
              localStorage.setItem(
                "addMarketWatch",
                JSON.stringify(resp["MarketWatchScrip"])
              );
              for (let idex of this.searchList) {
                var idxid: number = this.searchList.indexOf(idex);
                if (idex["symbol"] == slctData["symbol"]) {
                  this.searchList[idxid]["checked"] = false;
                }
              }
              this.getMarketWatch(this.marketId);
              this.toastr.success(
                "Successfully added to the Market Watch List",
                "Market Watch List"
              );
            }
          } else {
            this.toastr.error(resp["StatusOfRequest_a"]["Message"]);
          }
        },
        (err) => {
          if (err.error == "Unauthorized") {
            this.service.unauthorized();
            this.appcomponent._isConClose = this.marketId;
            // this.appcomponent.mwClose();
          }
        }
      );
    } else {
      //Delete
      var internal_scripId: any;
      if (list == "li") {
        for (let idx of this.scripData) {
          if (idx["InternalScripToken"] == slctData["internalToken"]) {
            internal_scripId = idx["InternalScripId"];
          }
        }
      } else {
        for (let idx of this.scripData) {
          if (idx["InternalScripToken"] == slctData["Token"]) {
            internal_scripId = idx["InternalScripId"];
          }
        }
      }
      jsonObj = {
        userId: this.userID,
        internalScripId: internal_scripId,
      };
      this.service.deleteMarketWatch(jsonObj).subscribe(
        (resp) => {
          if (resp["StatusOfRequest"]["status"]) {
            this.toastr.success(resp["StatusOfRequest"]["Message"]);
            this.getMarketWatch(this.marketId);
          } else {
            this.toastr.error("Error");
          }
        },
        (err) => {
          if (err.error == "Unauthorized") {
            this.service.unauthorized();
            this.loader.stop();
            this.appcomponent._marketId = this.marketId;
            this.appcomponent.mwClose();
          }
        }
      );
    }
  }

  /**
   * Method to get Position list
   * @author Kalai
   * @on 12-03-2020
   */
  positionlist() {
    this.loader.start();
    let JsonData: any = {
      userId: this.userID,
      "exchangeId": "0",
      "marketId": "0",
      "segmentId": "0",
      "instrumentType": "",
      "optionType": "",
      "group": "NA",
      "aggregate": "0",
      "strikePrice": ""
    }
    this.service.positionlists(JsonData).subscribe(resp => {
      this.loader.stop();
      if (resp['StatusOfRequest']['status'] == "0") {
        // this.position = resp['PositionData'];
        if (resp['PositionData'].length > 1) {
          this.positionsvisiblemsg = false;
          this.position = resp['PositionData'];
          for (let index in this.position) {
            this.position[index]['C/FQty'] = Number(this.position[index]['C/FQty']);
            this.position[index]['C/FAvgPr'] = Number(this.position[index]['C/FAvgPr']);
            this.position[index]['M2M'] = Number(this.position[index]['M2M']);
            this.position[index]['checked'] = false;
            this.totalvalue += Number(this.position[index]['M2M']);
          }
          this.position.pop();
          this.totalvalue = this.totalvalue.toFixed(2);
          if (this.position.length > 1) {
            this.positionsvisiblemsg = false;
          } else {
            // this.positionsvisiblemsg = true;
          }
        }
      } else {
        this.positionsvisiblemsg = true;
        // this.toastr.error("Error");
      }

    }, (err) => {
      this.service.toastr.error(err)
      this.loader.stop();
    });
  }

  /**
   * Method to get funds Details
   * @author Kalai
   * @since 18-04-2020
   */
  getFunds() {
    this.loader.start()
    let JsonObj: any = {
      "userId": this.userID,
    }
    this.service.fundsList(JsonObj).subscribe(resp => {
      this.loader.stop();
      this.fundvisiblemsg = true;
      if (resp['StatusOfRequest_a']['status'] == "0" && resp['StatusOfRequest_a']['No.ofRecords'] > 0) {
        this.fundvisiblemsg = false;
        this.fundsDetails = resp['FundsData_a'];
        // this.fundvisiblemsg = true;
        this.DepositRemaining = this.fundsDetails['DepositRemaining'];
        this.LedgerDeposit = this.fundsDetails['LedgerDeposit'];
        this.UsedMargin = this.fundsDetails['UsedMargin'];
        this.CommodityCollateral = this.fundsDetails['CommodityCollateral'];
        this.CurrencyCollateral = this.fundsDetails['CurrencyCollateral'];
        this.DerivativeCollateral = this.fundsDetails['DerivativeCollateral'];
        this.EquityCollateral = this.fundsDetails['EquityCollateral'];
        this.MTMmargin = this.fundsDetails['MTMmargin'];
        this.AdhocDeposit = this.fundsDetails['AdhocDeposit'];
        this.pnlMargin = this.fundsDetails['PnLmargin'];
        // var avalMargin: any = this.fundsDetails['DepositRemaining'];;
        // var MarginUsed: any = this.fundsDetails['UsedMargin'];;
        // var canvas = <HTMLCanvasElement>document.getElementById("canvas");
        // var ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        // this.PieChart = new Chart(ctx, {
        //   type: 'doughnut',
        //   data: {
        //     labels: ['Available Margin', 'Margin Used'],
        //     datasets: [{
        //       label: '# of Votes',
        //       pointHoverRadius: 5,
        //       data: [avalMargin, MarginUsed],
        //       backgroundColor: [
        //         'rgb(70, 191, 189)',
        //         'rgb(247, 70, 74)',
        //       ],
        //       borderColor: [
        //         'rgb(70, 191, 189)',
        //         'rgb(247, 70, 74)',
        //       ],
        //       borderWidth: 1
        //     }]
        //   },
        //   options: {
        //     cutoutPercentage: 65,
        //   }
        // });

      } else {

      }
    })
  }

  /**
  * @method Method to set trading view chart
  * @params na
  * @return na
  * @author Kalai
  * @on 11-06-2020
  */
  indexloadChart(indexchart) {
    console.log(indexchart)
    var count = Object.keys(indexchart).length
    if (count > 4) {
      this._symbol = indexchart + "::" + 'NSE' + "::" + "Index";
      localStorage.setItem('mobileindexGraph', this._symbol);
      if (this.routeTo.url == 'mobileview/mobileviewchart') {
        this.renderChart();
      } else {
        this.routeTo.navigateByUrl("mobileview/mobileviewchart");
      }
    } else {
      this._symbol = indexchart['IndexName'] + "::" + 'NSE' + "::" + "Index";
      localStorage.setItem('mobileindexGraph', this._symbol);
      if (this.routeTo.url == 'mobileview/mobileviewchart') {
        this.renderChart();
      } else {
        this.routeTo.navigateByUrl("/usercontrol/dashboard/home");
      }
    }
  }

  /**
    * Method to load char from marketwatch
    * @param val 
    */
  loadChart(val) {
    console.log(val)
    localStorage.setItem('currentGraph', JSON.stringify(val));
    let exchange = val['Exchange']['Name'];
    if (exchange.toLowerCase() === 'mcx'
      || exchange.toLowerCase() === 'cds'
      || exchange.toLowerCase() === 'nfo') {
      this._symbol = val['Symbol'] + "::" + val['Exchange']['Name'];
    } else {
      this._symbol = val['Symbol'] + "::" + val['Exchange']['Name'];
    }
    this.renderChart();
  }


  /**
    * @method Method to set trading view chart
    * @params na
    * @return na
    * @author Kalai
    * @on 11-06-2020
    */
  renderChart() {
    if (localStorage.getItem("mobileindexGraph") != null || localStorage.getItem("mobileindexGraph") != undefined) {
      this._symbol = localStorage.getItem("mobileindexGraph");
      localStorage.removeItem("mobileindexGraph")
    }
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this._symbol,
      datafeed: new Datafeed(this._datafeedUrl, 10000, this.service, this.websocket),
      interval: this._interval,
      container_id: this._containerId,
      library_path: this._libraryPath,
      locale: 'en',
      fullscreen: this._fullscreen,
      autosize: this._autosize,
      disabled_features: ['timezone_menu', 'left_toolbar', 'timeframes_toolbar', 'header_compare', 'header_symbol_search'],
      charts_storage_url: this._chartsStorageUrl
    };
    console.log(widgetOptions)
    const tvWidget = new widget(widgetOptions);
    this._tvWidget = tvWidget;
    tvWidget.onChartReady(() => {
    });
  }


  /**
    * Method to call the Holding List
    * @author Kalai
    */
  getHoldingList() {
    this.loader.start();
    let JsonObj = {
      "userId": this.userID,
      "symbol": "" //Empty string for all holdings, if u want for specific stock send symbol name
    }
    this.service.holdingListDetails(JsonObj).subscribe(resp => {
      this.loader.stop();
      this.holdingvisible = true;
      if (resp['StatusRequest']['status'] == '0' && resp['StatusRequest']['No.ofRecords'] > 1) {
        // resp['HoldingData'].pop();
        this.holdingvisible = true;
        var result: any = resp['HoldingData'];
        // this.totalHoldStock = result.length;
        result.map((item, i) => {
          result[i]['Exchange'] = "NSE";
          result[i]['CurrentMarketPrc'] = Number(result[i]['CurrentMarketPrc']).toFixed(2);
          result[i]['CurrentValue'] = Number(result[i]['CurrentValue']).toFixed(2);
        });
        this.holdings = result;

        this.emptytable = true;
      } else {
        if (resp['StatusRequest']['status'] == "1") {
          this.service.unauthorized();
        }
      }

    }, (err) => {
      this.loader.stop();
      this.service.unauthorized();
    });
  }


  /**
   * Method to get Order List
   * @author Kalai
   * @on 17-03-2020
   */
  getOrderList() {
    this.loader.start();
    let JsonData = {
      "internalLoginId": this.service.getInternalId(),
      "userId": this.service.getUserId(),
      "orderType": "-1",
      "orderStatus": "2"
    }
    this.loader.start();
    this.service.allOrderList(JsonData).subscribe(resp => {
      this.loader.stop();
      this.ordervisiblemsg = false;
      if (resp.length > 0) {
        this.orderDetails = resp;
        this.ordervisiblemsg = true;
      } else {
        this.emptytablemsg = false;
      }
    }, (err) => {
      if (err.error == 'Unauthorized') {
        this.service.unauthorized();
      }
    })
  }


  alertify() {
    this.service.alertpop();
  }

  /**
  * Method to get Trade List
  * @author Dinesh
  * @on 18-03-2020
  */
  gettradelist() {
    this.loader.start();
    let JsonData: any = {
      "internalLoginId": this.userDetails['UserDetails']['UserInternalID'],
      "userId": this.userID,
      "orderType": "-1"
    }

    this.service.tradelist(JsonData).subscribe(resp => {
      this.loader.stop();
      if (resp['StatusOfRequest']['Status'] == 0) {
        this.completedTrades = resp['TradeList'];
        if (this.completedTrades.length > 0) {
          // this.ordervisiblemsg = false;
          for (let index in this.completedTrades) {
            this.completedTrades[index]['Status'] = "Complete";
          }
        }
        this.completedTrades.pop();
        // this.allOrders.push(this.completedTrades);
        this.allOrders = [...this.completedTrades];
        if (this.allOrders.length > 1) {
          this.ordervisiblemsg = false;

        }
        this.completedOrder = this.completedTrades;
      } else if (resp['Message'] == "User has been logged off.") {
        this.service.unauthorized();
      }
      // this.completedTrades.pop();
    }, (err) => {
      if (err.error == 'Unauthorized') {
        this.service.unauthorized();
      }
    })
  }

  /**
  * WebSocket for market watch
  */
  callSocketConnMW() {
    this.appcomponent.callConnectionSocket();
  }


  /**
   * Method to define check box event
   * @author Kalai
   * @param event
   * @param index 
   */
  selectPendingOrderCheck(event, index) {
    if (index == "" && event.checked) {
      this.exitarray = [];
      this.showOverAllExit = true;
      this.exitarray = this.tempOpenOrders;
    }
    if (index != "" && event.checked) {
      this.exitarray.push(index);
      if (this.exitarray.length > 0) {
        this.showOverAllExit = true;
      }
    }
    if (index != "" && !event.checked) {
      for (let idex of this.exitarray) {
        var idxid: number = this.exitarray.indexOf(idex);
        if (this.exitarray[idxid]['InternalScripToken'] == index['InternalScripToken'] && this.exitarray[idxid]['InternalOrderId'] == index['InternalOrderId']) {
          this.exitarray.splice(idxid, 1);
        }
      }
      if (this.exitarray.length > 0) {
        this.showOverAllExit = true;
      } else if (this.exitarray.length == 0) {
        this.showOverAllExit = false;
        this.myModel = false;
      }
    }
    if (index == "" && !event.checked) {
      this.showOverAllExit = false;
      this.exitarray = [];
    }
  }


  navPage(page) {
    if (page == 'profile') {
      this.routeTo.navigateByUrl('/usercontrol/dashboard/profile')
    }
  }

  /**
* Method to get the Tick size
* @author Kalaivani
* @since 22-05-2020
*/
  getScripTickSize(token, symbol) {
    let jsonObj: any;
    if (this.mw_one == this.marketId) {
      if (sessionStorage.getItem("MW_1_Tick") != null || sessionStorage.getItem("MW_1_Tick")) {
        this.mw_one_tickPrice = JSON.parse(sessionStorage.getItem("MW_1_Tick"));
      } else {
        jsonObj = {
          "tokens": token,
          "symbols": symbol,
        }
        this.service.getTickSize(jsonObj).subscribe(resp => {
          if (resp['stat'] == "success") {
            this.mw_one_tickPrice = resp['result'];
            sessionStorage.setItem("MW_1_Tick", JSON.stringify(resp['result']));
          }
        })
      }
    } else if (this.mw_two == this.marketId) {
      if (sessionStorage.getItem("MW_2_Tick") != null || sessionStorage.getItem("MW_2_Tick")) {
        this.mw_one_tickPrice = JSON.parse(sessionStorage.getItem("MW_2_Tick"));
      } else {
        jsonObj = {
          "tokens": token,
          "symbols": symbol,
        }
        this.service.getTickSize(jsonObj).subscribe(resp => {
          if (resp['stat'] == "success") {
            this.mw_two_tickPrice = resp['result'];
            sessionStorage.setItem("MW_2_Tick", JSON.stringify(resp['result']));
          }
        })
      }
    } else if (this.mw_three == this.marketId) {
      if (sessionStorage.getItem("MW_3_Tick") != null || sessionStorage.getItem("MW_3_Tick")) {
        this.mw_one_tickPrice = JSON.parse(sessionStorage.getItem("MW_3_Tick"));
      } else {
        jsonObj = {
          "tokens": token,
          "symbols": symbol,
        }
        this.service.getTickSize(jsonObj).subscribe(resp => {
          if (resp['stat'] == "success") {
            this.mw_three_tickPrice = resp['result'];
            sessionStorage.setItem("MW_3_Tick", JSON.stringify(resp['result']));
          }
        })
      }
    } else if (this.mw_four == this.marketId) {
      if (sessionStorage.getItem("MW_4_Tick") != null || sessionStorage.getItem("MW_4_Tick")) {
        this.mw_one_tickPrice = JSON.parse(sessionStorage.getItem("MW_4_Tick"));
      } else {
        jsonObj = {
          "tokens": token,
          "symbols": symbol,
        }
        this.service.getTickSize(jsonObj).subscribe(resp => {
          if (resp['stat'] == "success") {
            sessionStorage.setItem("MW_4_Tick", JSON.stringify(resp['result']));
          }
        })
      }
    } else if (this.mw_five == this.marketId) {
      if (sessionStorage.getItem("MW_5_Tick") != null || sessionStorage.getItem("MW_5_Tick")) {
        this.mw_one_tickPrice = JSON.parse(sessionStorage.getItem("MW_5_Tick"));
      } else {
        jsonObj = {
          "tokens": token,
          "symbols": symbol,
        }
        this.service.getTickSize(jsonObj).subscribe(resp => {
          if (resp['stat'] == "success") {
            sessionStorage.setItem("MW_5_Tick", JSON.stringify(resp['result']));
          }
        })
      }
    }
  }

  /**
  * Method to convert Position
  * @author Kalai
  * @since 22-05-202
  */
  convertPosition(param) {
    const dialogRef = this.dialog.open(PositionconversionComponent, {
      width: '600px',
      data: { Data: param },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.positionlist();
      }
    });
  }
}