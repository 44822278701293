import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EntrylevelModule } from './entrylevel/entrylevel.module';
import { UsercontrolModule } from './usercontrol/usercontrol.module';
import { MobilewindowModule } from './mobilewindow/mobilewindow.module';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'entrylevel',
    pathMatch: 'full'
  },
  {
    path: "entrylevel",
    loadChildren: () => EntrylevelModule
  },
  {
    path: "usercontrol",
    loadChildren: () => UsercontrolModule
  },
  {
    path: "mobilewindow",
    loadChildren: () => MobilewindowModule
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
