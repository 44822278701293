import { Component, OnInit } from "@angular/core";
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  Inject,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { HttpService } from "src/app/services/http.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { fromEvent, Subject, of as observableOf, of, iif } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { SharedataService } from '../services/sharedata.service';
import { NgxUiLoaderComponent } from 'ngx-ui-loader/lib/core/ngx-ui-loader.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { debug } from 'util';

export interface DialogData {
  data: any;
}


@Component({
  selector: 'app-orderwindow',
  templateUrl: './orderwindow.component.html',
  styleUrls: ['./orderwindow.component.scss']
})
export class OrderwindowComponent implements OnInit {

  orderType: string = "BUY";
  userDetails: any;
  userID: any;
  scripData: any;
  myHandle = true;
  ordgenerator: FormGroup;
  private _container: HTMLElement;
  private _target: HTMLElement;
  private _handle: HTMLElement;
  private _delta = { x: 0, y: 0 };
  private _offset = { x: 0, y: 0 };
  private _destroy$ = new Subject<void>();
  private _isResized = true;
  page: any;
  pageType: any;
  orders: any = [];
  save: string;
  editedId: any;
  productType: any;
  editInstrmntname: any;
  priceValue: any;
  tempscrpt: any;
  chkStatus: any;
  is_qtyVal: any;
  is_priceVal: any;
  is_stplss: any;
  is_target: any;
  is_tralngstplss: any;
  is_discloseQty: any;
  is_trigPriceVal: any;
  modScrip: any;
  symName: any;
  exchange: any;
  exch: any;
  symtoken: any;
  editInstrmnt: any;
  cncvalid: boolean;
  tikLot: number;
  lotSize: number;
  tikPrc: number;
  scripobj: any;
  change: any;
  perchange: any;
  tikprice: any;
  hideDiscQty: boolean;
  retType: string;
  qtyzro: boolean;
  chkqty: boolean;
  prczro: boolean;
  chkPrc: boolean;
  chkTPrc: boolean;
  thPrc: boolean;
  tlPrc: boolean;
  thPrczro: boolean;
  chkstp: boolean;
  stplzero: boolean;
  chktrgt: boolean;
  trghPrc: boolean;
  trgetZero: boolean;
  chktstp: boolean;
  trgstplss: boolean;
  slLess: boolean;
  slHigh: boolean;
  trLess: boolean;
  trHigh: boolean;
  trail20: boolean;
  bgColorBStype: any;
  bo_msg: boolean;
  is_mktType: any;
  _validity_day: any;
  is_slLmt: boolean;
  is_slMkt: boolean;
  is_mis: boolean;
  is_nrml: boolean;
  _rlgr: boolean;
  _bo: boolean;
  _co: boolean;
  _amo: boolean;
  modifyData: any = [];
  is_dPriceVal: any;
  _validity_ioc: any;
  is_mkt: any;
  is_lmt: any;
  months: any;
  priceRange: any;
  currentFormControlName: string;
  discTen: number;
  chkDisc: boolean = false;
  position: { x: number; y: number };
  placedata: any[];
  curScrip: any;
  useDefaultBuySell: boolean = false;
  marketId: any;
  scripDetails: any;
  modifyScrip: any;
  setmktCall: boolean = false;
  limit: boolean;

  constructor(
    public service: HttpService,
    public fbuilder: FormBuilder,
    public dialogRef: MatDialogRef<OrderwindowComponent>,
    private _elementRef: ElementRef,
    private _zone: NgZone,
    public sharedata: SharedataService,
    public loader: NgxUiLoaderService,
    private _cd: ChangeDetectorRef,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.ordgenerator = this.fbuilder.group({
      bstype: ["BUY", Validators.required],
      complexty: ["regular", Validators.required],
      complextyType: ["day", Validators.required],
      position: ["mis", Validators.required],
      orderTypeSelect: ["L", Validators.required],
      quantity: ["0", Validators.required],
      priceVal: ["0", Validators.required],
      // disqty: ['0', Validators.required],
      trigPriceVal: ["0", Validators.required],
      stplss: ["0", Validators.required],
      target: ["0", Validators.required],
      tralngstplss: ["0", Validators.required],
      // mktType: ['day', Validators.required],
      discQty: ["0", Validators.required],
    });
  }

  ngOnInit() {
    //Draggable event
    // this._elementRef.nativeElement.style.cursor = "default";
    // this._handle = this._elementRef.nativeElement.parentElement.parentElement.parentElement;
    // this._target = this._elementRef.nativeElement.parentElement.parentElement.parentElement;
    // this._container = this._elementRef.nativeElement.parentElement.parentElement;
    // this._container.style.resize = "both";
    // this._container.style.overflow = "hidden";
    // this._setupEvents();

    if (
      localStorage.getItem("userdetails") != null ||
      localStorage.getItem("userdetails") != undefined
    ) {
      var userDetails = JSON.parse(localStorage.getItem("userdetails"));
      this.userDetails = userDetails;
      this.userID = this.userDetails["UserDetails"]["UserID"];
    }
    if (
      localStorage.getItem("mwDetails") != null ||
      localStorage.getItem("mwDetails") != undefined
    ) {
      var scripData: any;
      scripData = JSON.parse(localStorage.getItem("mwDetails"));
      this.scripDetails = scripData['MarketWatchScrips'];
      this.scripData = scripData["MarketWatchScripsDetails"];
    }
    this.curScrip = this.data["scrOrder"];
    this.marketId = this.data['marketID'];

    this.setmktVal(this.data);
  }

  private _setupEvents() {
    // this._zone.runOutsideAngular(() => {
    //   const mousedown$ = fromEvent(this._handle, "mousedown");
    //   const mousemove$ = fromEvent(document, "mousemove");
    //   const mouseup$ = fromEvent(document, "mouseup");

    //   const mousedrag$ = mousedown$.pipe(
    //     switchMap((event: MouseEvent) => {
    //       const startX = event.clientX;
    //       const startY = event.clientY;

    //       const rectX = this._container.getBoundingClientRect();
    //       if (
    //         // if the user is clicking on the bottom-right corner, he will resize the dialog
    //         startY > rectX.bottom - 15 &&
    //         startY <= rectX.bottom &&
    //         startX > rectX.right - 15 &&
    //         startX <= rectX.right
    //       ) {
    //         this._isResized = true;
    //         return observableOf(null);
    //       }

    //       return mousemove$.pipe(
    //         map((innerEvent: MouseEvent) => {
    //           innerEvent.preventDefault();
    //           this._delta = {
    //             x: innerEvent.clientX - startX,
    //             y: innerEvent.clientY - startY,
    //           };
    //         }),
    //         takeUntil(mouseup$)
    //       );
    //     }),
    //     takeUntil(this._destroy$)
    //   );

    //   mousedrag$.subscribe(() => {
    //     if (this._delta.x === 0 && this._delta.y === 0) {
    //       return;
    //     }

    //     this._translate();
    //   });

    //   mouseup$.pipe(takeUntil(this._destroy$)).subscribe(() => {
    //     if (this._isResized) {
    //       this._handle.style.width = "auto";
    //     }
    //     this._offset.x += this._delta.x;
    //     this._offset.y += this._delta.y;
    //     this._delta = { x: 0, y: 0 };
    //     this._cd.markForCheck();
    //   });
    // });
  }

  private _translate() {
    // this._target.style.left = `${this._offset.x + this._delta.x}px`;
    // this._target.style.top = `${this._offset.y + this._delta.y}px`;
    // this._target.style.position = 'relative';
    // requestAnimationFrame(() => {
    //   this._target.style.transform = `
    //     translate(${this._offset.x + this._delta.x}px,
    //               ${this._offset.y + this._delta.y}px)
    //   `;
    // });
  }

  /**
   * Method to get the tick price
   * @param event 
   * @author Kalaivani
   * @returns tickPrice
   */
  getTick(scrip, page) {
    var token: any = []; var symbol: any = [];
    if (page == "orderModify" || page == "position") {
      if (page == "position") {
        token.push(scrip['Internaltoken'])
      } else {
        token.push(scrip['InternalScripToken']);
      }
      symbol.push(scrip['Symbol']);
    } else {
      token.push(scrip['Token']);
      symbol.push(scrip['Symbol']);
    }

    let jsonObj = {
      "tokens": token,
      "symbols": symbol,
    }
    this.service.getTickSize(jsonObj).subscribe(resp => {
      if (resp['stat'] == "success") {
        var mw_one_tick = resp['result'];
        for (let idx of mw_one_tick) {
          if (idx['Token'] == this.curScrip['Token']) {
            this.tikprice = idx['TickPrice'];
            return this.tikprice;
          }
        }
      } else {
        this.service.toastr.error("Failed while getting Tick price");
      }
    })
  }

  /**
   * Method to change buy sell toggle button and to change header backgroud color
   * @param event
   * @author selvakumar K
   * @since 18-03-2020
   */
  changeBuySellEvent(event) {
    if (event) {
      this.bgColorBStype = "sell";
      this.orderType = "SELL";
      this.save = "SELL";
    } else {
      this.bgColorBStype = "buy";
      this.orderType = "BUY";
      this.save = "BUY";
    }
  }

  /**
   * Method for Cancel button
   * @author Kalaivani
   * @since 19-03-2020
   */
  reset() {
    document.getElementById("openModalButton").click();
    setTimeout((data) => {
      this.position = { x: 0, y: 0 };
    }, 800);
    //Hide all error messages
    this.qtyzro = false;
    this.prczro = false;
    this.thPrczro = false;
    this.stplzero = false;
    this.trgetZero = false;
    this.trgstplss = false;
    this.thPrc = false;
    this.tlPrc = false;
    this.chkPrc = false;
    this.slLess = false;
    this.slHigh = false;
    this.trLess = false;
    this.trHigh = false;
    this.dialogRef.close();
  }

  /**
   * Method to get the depth request
   * @author  Kalaivani
    */
  depthRequest(scrip, page, bsTyp) {
    let jsonObj: any = {
      marketId: scrip['marketId'],
      exchangeId: scrip['exchangeId'],
      internalScripToken: scrip["Token"],
      userId: this.userID,
    };
    this.service.depthNewRequest(jsonObj).subscribe((resp) => {
      if (resp["StatusOfRequest"]["Status"] == 0) {
        this.priceRange = resp["MarketDepthData"]["DPR"];
        scrip['BSEtoken'] = resp['MarketDepthData']['BSEtoken'];
        scrip['NSEtoken'] = resp['MarketDepthData']['NSEtoken'];
        scrip['NetChange'] = resp['MarketDepthData']['NetPriceChange'];
        scrip['NetChangePercentage'] = resp['MarketDepthData']['NetPercentageChange'];
        scrip['LTP'] = resp['MarketDepthData']['LTP'];
        scrip['ExpiryDate'] = resp['MarketDepthData']['ExpiryDate'];
        scrip['InstrumentType'] = resp['MarketDepthData']['InstrumentType'];
        scrip['OptionType'] = resp['MarketDepthData']['OptionType'];

        this.change = scrip["NetChange"];
        this.perchange = scrip["NetChangePercentage"];
        this.tikprice = this.tikprice;
        this.exch = scrip["Exchange"];
        this.exchange = scrip["Exchange"];
        this.priceValue = scrip["LTP"];
        this.symtoken = scrip["Token"]
        if (page == "position") {
          this.curScrip = scrip;
        } else {
          this.modifyData = scrip;
        }
        this.setInputValue(scrip, page, bsTyp)
      } else {
        this.service.toastr.error("Failed");
      }
    }, (err) => {
      this.service.toastr.error(err);
    });
  }

  /**
   * Method to call Scrip Search API
   * @author Kalaivani
   * @param scrip 
   * @param page 
   */
  getScripDetails(scrip, page, bsTyp) {
    var jsonvalue: any;
    if (page == "position") {
      jsonvalue = {
        symbol: scrip['Symbol'],
        exchange: scrip['ExchangeName'],
      };
    } else {
      jsonvalue = {
        symbol: scrip['Symbol'],
        exchange: scrip['Exchange'],
      };
    }
    // return new Promise((resolve, reject) => {
    this.service.searchSymbol(jsonvalue).subscribe(resp => {
      if (resp['stat'] == 'success') {
        this.modifyScrip = resp['result'];
        for (let idx of this.modifyScrip) {
          if (page == "orderModify" && scrip['InternalScripToken'] == idx['internalToken']) {
            scrip['bodLot'] = idx['bodLot'];
            scrip['exchangeId'] = idx['exchangeId'];
            scrip['marketId'] = idx['marketId'];
            scrip['series'] = idx['series'];
            scrip['Token'] = idx['internalToken'];
            scrip['segmentId'] = idx['segmentId'];
          }
          if (page == "position" && scrip['Internaltoken'] == idx['internalToken']) {
            scrip['bodLot'] = idx['bodLot'];
            scrip['exchangeId'] = idx['exchangeId'];
            scrip['marketId'] = idx['marketId'];
            scrip['series'] = idx['series'];
            scrip['Token'] = idx['internalToken'];
            scrip['segmentId'] = idx['segmentId'];
          }
        }
        this.depthRequest(scrip, page, bsTyp)
      } else {
        this.service.toastr.error(resp['stat']);
      }
    }, (err) => {
      this.service.toastr.error(err);
    })
    // });
  }

  /**
   * Method to Set the values for the input fields
   * @author Kalaivani
   * @since 19-03-2020
   * @param  bsTyp 
   */
  setmktVal(data) {
    var bsTyp = data["clicTyp"];
    var scrip = data["scrOrder"];
    var page = data["page"];
    this.getTick(scrip, page);
    if (page == "orderModify") {
      if (scrip['Buy/Sell'] == "Buy") {
        bsTyp = "BUY"
        this.orderType = "BUY";
      } else {
        bsTyp = "SELL";
        this.orderType = "SELL";
      }
      this.getScripDetails(scrip, page, bsTyp);
    } else if (page == "position") {
      if (bsTyp == "BUY") {
        this.orderType = "BUY";
        this.bgColorBStype = "Buy";
      } else {
        this.orderType = "SELL";
        this.bgColorBStype = "sell";
      }
      this.getScripDetails(scrip, page, bsTyp)
    } else if (page == "mktwatch") {
      if (bsTyp == "BUY") {
        this.bgColorBStype = bsTyp.toLowerCase();
        this.useDefaultBuySell = false;
        this.orderType = bsTyp;
      } else {
        this.bgColorBStype = bsTyp.toLowerCase();
        this.useDefaultBuySell = true;
        this.orderType = bsTyp;
      }
      this.ordgenerator.value["bstype"] = this.useDefaultBuySell;

      //To get the segID and exchID by matching token from mw
      var exchID: any;
      var segID: any;
      var internal_scripId: any;
      for (let idx in this.scripData) {
        if (this.scripData[idx]["InternalScripToken"] == scrip["Token"]) {
          exchID = this.scripData[idx]["ExchangeId"];
          segID = this.scripData[idx]["MarketId"];
          internal_scripId = this.scripData[idx]["internalScripId"];
        }
      }
      let jsonObj: any = {
        marketId: segID,
        exchangeId: exchID,
        internalScripToken: scrip["Token"],
        userId: this.userID,
      };
      this.service.depthNewRequest(jsonObj).subscribe((resp) => {
        if (resp["StatusOfRequest"]["Status"] == 0) {
          this.priceRange = resp["MarketDepthData"]["DPR"];
          this.setInputValue(scrip, page, bsTyp);
        }
      }, (err) => {
        this.service.toastr.error(err);
      });
    }
    this.tempscrpt = scrip;
  }


  /**
   * Method to set the value and validation
   */
  setInputValue(scrip, page, bsTyp) {
    if (page == "orderModify") {
      this.chkStatus = scrip["Status"];
      this.is_qtyVal = null;
      this.is_priceVal = null;
      this.is_stplss = null;
      this.is_target = null;
      this.is_tralngstplss = null;
      this.is_discloseQty = null;
      this.is_trigPriceVal = null;
      this.modScrip = scrip;
      this.page = page;
      this.symName = scrip["Symbol"];
      this.exchange = scrip["Exchange"];
      this.exch = scrip["Exchange"];
      this.ordgenerator.patchValue({ priceVal: Number(scrip["Price"]).toFixed(2) });
      this.priceValue = scrip["Price"];
      this.symtoken = scrip["Token"];
      this.ordgenerator.patchValue({ priceVal: scrip["Price"] });
      this.editInstrmnt = scrip["Symbol"];
      if (scrip["InstrumentType"] == "OPTIDX" || scrip["InstrumentType"] == "OPTSTK" || scrip["InstrumentType"] == "OPTFUT" || scrip["InstrumentType"] == "OPTCUR") {
        let strike = scrip.strikePrice.split(".")[1] > 0 ? scrip.strikePrice : scrip.strikePrice.split(".")[0];
        this.editInstrmntname = scrip.Sym + " " + new Date(scrip["ExpiryDate"]).getDate() + this.months[new Date(scrip["ExpiryDate"]).getMonth()] + " " + Number(strike).toFixed(2) + " " + scrip["optionType"];
      }
      //  else if (scrip["InstrumentType"].startsWith("FUT")) {
      //   this.editInstrmntname = scrip["Symbol"] + " " + new Date(scrip["ExpiryDate"]).getDate() +
      //     this.months[new Date(scrip["ExpiryDate"]).getMonth()] + "FUT";
      // } 
      else if (scrip["Exchange"] == "NSE" || scrip["Exchange"] == "BSE" || scrip["ExchangeName"] == "NSE" || scrip["ExchangeName"] == "BSE") {
        this.editInstrmntname = scrip["Symbol"];
      }
      this.ordgenerator.patchValue({
        bstype: scrip["Buy/Sell"] == "Buy" ? "BUY" : "SELL",
      });
      if (scrip["Exchange"] == "BSE" || scrip["Exchange"] == "NSE" || scrip["ExchangeName"] == "NSE" || scrip["ExchangeName"] == "BSE") {
        this.cncvalid = true;
      } else {
        this.cncvalid = false;
      }
      this.ordgenerator.patchValue({ position: "mis" });
      if (scrip["Intra/CNC"] == "Intra") {
        this.ordgenerator.patchValue({ position: "mis" });
      } else if (scrip["Intra/CNC"] == "cnc") {
        this.ordgenerator.patchValue({ position: "cnc" });
      }
      // else if (scrip["OrderType"] == "Normal") {
      //   this.ordgenerator.patchValue({ complexty: "nrml" });
      // } 
      else {
        this.ordgenerator.patchValue({ complexty: "regular" });
      }
      this.ordgenerator.patchValue({ orderTypeSelect: 'L' });
      this.ordgenerator.patchValue({ discQty: scrip["DiscloseQty"] });
      this.ordgenerator.patchValue({ quantity: scrip["Qty"] });
      if (scrip["OrderType"] == "SL") {
        this.ordgenerator.patchValue({ trigPriceVal: scrip["Trig.Price"] });
        this.is_priceVal = true;
        this.is_stplss = true;
        this.is_target = true;
        this.is_tralngstplss = true;
        this.is_discloseQty = true;
      }
      if (scrip["OrderType"] == "Normal") {
        this.ordgenerator.patchValue({ priceVal: scrip["Price"] });
        this.ordgenerator.patchValue({ trigPriceVal: scrip["Trig.Price"] });
        this.is_trigPriceVal = null;
        this.is_stplss = true;
        this.is_target = true;
        this.is_tralngstplss = true;
        this.is_discloseQty = true;
      }

      if (scrip["Exchange"] !== "NFO" || scrip['ExchangeName'] !== "NFO") {
        this.ordgenerator.patchValue({ quantity: scrip["Qty"] });
        this.tikLot = Number(1);
        this.is_discloseQty = null;
      } else {
        this.ordgenerator.patchValue({ quantity: scrip["Qty"] });
        this.tikLot = Number(scrip["bodLot"]);
        this.is_discloseQty = true;
      }
      this.lotSize = Number(scrip["bodLot"]);
      this.save = "Modify";
      this.tikLot = Number(scrip['Qty']);
      this.tikPrc = this.tikprice;

    } else if (page == "position") {
      this.page = page;
      if (scrip['InstrumentType'] == 'OPTIDX' || scrip['InstrumentType'] == 'OPTSTK' || scrip['InstrumentType'] == 'OPTFUT' || scrip['InstrumentType'] == 'OPTCUR') {
        let strike = scrip.Stikeprc.split('.')[1] > 0 ? scrip.Stikeprc : scrip.Stikeprc.split('.')[0];
        this.editInstrmntname = scrip.Symbol + ' ' + new Date(scrip.Expdate).getDate() + this.months[new Date(scrip.Expdate).getMonth()] + ' ' + Number(strike).toFixed(2) + ' ' + scrip.Opttype;
      }
      //  else if (scrip['InstrumentType'].startsWith('FUT')) {
      //   this.editInstrmntname = scrip['Symbol'] + ' ' + new Date(scrip['Expdate']).getDate() + this.months[new Date(scrip.Expdate).getMonth()] + ' FUT';
      // } 
      else if (scrip['ExchangeName'] == 'NSE' || scrip['ExchangeName'] == 'BSE') {
        this.editInstrmntname = scrip['Symbol']
      }
      this.symName = scrip['Symbol'];
      this.exch = scrip["ExchangeName"];
      this.priceValue = scrip["LTP"];
      this.symtoken = scrip["Token"];
      this.ordgenerator.patchValue({ priceVal: scrip["LTP"] });
      this.editInstrmnt = scrip['Symbol'];
      this.ordgenerator.patchValue({ bstype: bsTyp });

      this.ordgenerator.patchValue({ orderTypeSelect: "L" });
      if (scrip['ExchangeName'] == 'BSE' || scrip['ExchangeName'] == "NSE") {
        this.cncvalid = true;
      } else {
        this.cncvalid = false;
      }

      this.ordgenerator.patchValue({ position: "mis" });
      if (scrip["Intra/CNC"] == "Intra") {
        this.ordgenerator.patchValue({ position: "mis" });
      } else if (scrip["Intra/CNC"] == "cnc") {
        this.ordgenerator.patchValue({ position: "cnc" });
      }
      else {
        this.ordgenerator.patchValue({ complexty: "regular" });
      }
      this.ordgenerator.patchValue({ orderTypeSelect: 'L' });
      this.ordgenerator.patchValue({ discQty: null });
      this.ordgenerator.patchValue({ quantity: scrip["C/FQty"] });

      if (scrip['ExchangeName'] !== "NFO") {
        this.ordgenerator.patchValue({ quantity: scrip['C/FQty'] });
      } else {
        this.ordgenerator.patchValue({ quantity: scrip['bodLot'] });
      }

      if (scrip["Exchange"] !== "NFO" || scrip['ExchangeName'] !== "NFO") {
        this.ordgenerator.patchValue({ quantity: scrip["C/FQty"] });
        this.tikLot = Number(1);
        this.is_discloseQty = null;
      } else {
        this.ordgenerator.patchValue({ quantity: scrip["C/FQty"] });
        this.tikLot = Number(scrip["bodLot"]);
        this.is_discloseQty = true;
      }
      this.lotSize = Number(scrip["bodLot"]);
      this.save = bsTyp;
      this.tikLot = Number(scrip['bodLot']);
      this.tikPrc = this.tikprice;

      this.formtypselect();
      this.save = bsTyp;
      this.lotSize = Number(scrip["bodLot"]);
      this.save = bsTyp;
      this.tikLot = Number(scrip['bodLot']);
      this.tikPrc = this.tikprice;
      document.getElementById("openModalButton").click();


      //   this.ordgenerator.patchValue({ position: "mis" });
      //   if (scrip["Intra/CNC"] == "Intra") {
      //     this.ordgenerator.patchValue({ position: "mis" });
      //   } else if (scrip["Intra/CNC"] == "cnc") {
      //     this.ordgenerator.patchValue({ position: "cnc" });
      //   }
      //   // else if (scrip["OrderType"] == "Normal") {
      //   //   this.ordgenerator.patchValue({ complexty: "nrml" });
      //   // } 
      //   else {
      //     this.ordgenerator.patchValue({ complexty: "regular" });
      //   }
      //   this.ordgenerator.patchValue({ orderTypeSelect: 'L' });
      //   this.ordgenerator.patchValue({ discQty: scrip["DiscloseQty"] });
      //   this.ordgenerator.patchValue({ quantity: scrip["Qty"] });
      //   if (scrip["OrderType"] == "SL") {
      //     this.ordgenerator.patchValue({ trigPriceVal: scrip["Trig.Price"] });
      //     this.is_priceVal = true;
      //     this.is_stplss = true;
      //     this.is_target = true;
      //     this.is_tralngstplss = true;
      //     this.is_discloseQty = true;
      //   }
      //   if (scrip["OrderType"] == "Normal") {
      //     this.ordgenerator.patchValue({ priceVal: scrip["Price"] });
      //     this.ordgenerator.patchValue({ trigPriceVal: scrip["Trig.Price"] });
      //     this.is_trigPriceVal = null;
      //     this.is_stplss = true;
      //     this.is_target = true;
      //     this.is_tralngstplss = true;
      //     this.is_discloseQty = true;
      //   }

      //   if (scrip["Exchange"] !== "NFO" || scrip['ExchangeName'] !== "NFO") {
      //     this.ordgenerator.patchValue({ quantity: scrip["Qty"] });
      //     this.tikLot = Number(1);
      //     this.is_discloseQty = null;
      //   } else {
      //     this.ordgenerator.patchValue({ quantity: scrip["Qty"] });
      //     this.tikLot = Number(scrip["Qty"]);
      //     this.is_discloseQty = true;
      //   }
      //   this.lotSize = Number(scrip["bodLot"]);
      //   this.save = bsTyp;
      //   this.tikLot = Number(scrip['Qty']);
      //   this.tikPrc = this.tikprice;

    } else {
      this.scripobj = scrip;
      this.page = page;
      this.symName = scrip["Symbol"];
      if (scrip["Exchange"]["Name"] == "BSE" || scrip["Exchange"]["Name"] == "NSE") {
        this.cncvalid = true;
      } else {
        this.cncvalid = false;
      }
      this.change = scrip["NetChange"];
      this.perchange = scrip["NetChangePercentage"];
      this.tikprice = this.tikprice;
      this.exch = scrip["Exchange"]["Name"];
      this.exchange = scrip["Exchange"]["Name"];
      this.priceValue = scrip["LTP"];
      this.symtoken = scrip["Token"];
      this.ordgenerator.patchValue({ priceVal: scrip["LTP"] });
      this.ordgenerator.patchValue({ complextyType: "day" });
      this.editInstrmnt = scrip["Symbol"];
      this.editInstrmntname = scrip["Symbol"];
      this.ordgenerator.patchValue({ bstype: bsTyp });
      this.ordgenerator.patchValue({ complexty: "regular" });
      this.ordgenerator.patchValue({ orderTypeSelect: "L" });
      this.ordgenerator.patchValue({ position: "mis" });
      this.save = bsTyp;
      this.orderType = bsTyp;
      if (scrip["Exchange"]["Name"] !== "NFO") {
        this.ordgenerator.patchValue({ quantity: scrip["BLot"] });
        this.tikLot = Number(scrip["BLot"]);
        this.is_discloseQty = null;
        this.hideDiscQty = true;
      } else {
        this.ordgenerator.patchValue({ quantity: scrip["BLot"] });
        this.tikLot = Number(scrip["BLot"]);
        this.hideDiscQty = false;
      }
      // this.tikLot = Number(scrip['bodlot']);
      this.lotSize = Number(scrip["BLot"]);
      // let tik = Number(scrip["tcksize"]);
      // let multi = Number(scrip["multiplier"]);
      // let preci = Number(scrip["decimalPrecision"]);
      this.tikPrc = this.tikprice;
    }
    this.formtypselect();
    document.getElementById("openModalButton").click();
  }


  /**
   * Method to disable particular input fields
   * @author Kalaivani
   * @since 19-03-2020
   */
  formtypselect() {
    this.qtyzro = false;
    this.chkqty = false;
    this.prczro = false;
    this.chkPrc = false;
    this.chkTPrc = false;
    this.thPrc = false;
    this.tlPrc = false;
    this.thPrczro = false;
    this.chkstp = false;
    this.stplzero = false;
    this.chktrgt = false;
    this.trghPrc = false;
    this.trgetZero = false;
    this.chktstp = false;
    this.trgstplss = false;
    this.slLess = false;
    this.slHigh = false;
    this.trLess = false;
    this.trHigh = false;
    this.trail20 = false;
    this.bgColorBStype = this.orderType;
    var bstype = this.orderType;
    switch (bstype) {
      case "BUY":
        this.bgColorBStype = "buy";
        this.setComplexity();
        break;
      case "SELL":
        this.bgColorBStype = "sell";
        this.setComplexity();
        break;
    }
  }


  /**
   * Method to validate input fields
   * @author Kalaivani
   * @since 20-03-2020
   * @param value
   */
  formtypselectType(value) {
    if (value == "day") {
      this.retType = "DAY";
      this.is_discloseQty = null;
      if (this.exch == "NFO" || (this.exch == "CDS" && value == "day")) {
        this.is_discloseQty = true;
      } else {
        this.is_discloseQty = null;
      }
    } else {
      this.retType = "IOC";
      this.is_discloseQty = true;
    }
  }

  /**
   * Method to validate the various types of orders & Market
   * @author Kalaivani
   * @since 19-03-2020
   */
  setComplexity() {
    var complexty = this.ordgenerator.value["complexty"];
    var position = this.ordgenerator.value["position"];
    var orderType = this.ordgenerator.value["orderTypeSelect"];
    if (this.page == "orderModify") {
      if (complexty == "regular" && position == "mis" && orderType == "L") {
        this.productType = 1;
        this.limit = true;
        this.is_slLmt = true;
        this.is_slMkt = true;
        this.is_mis = true;
        this.is_nrml = true;
        this._rlgr = true;
        this._bo = true;
        this._co = true;
        this._amo = true;
        this.is_trigPriceVal = true;
        // this.ordgenerator.patchValue({ trigPriceVal: "" });
        this.is_stplss = true;
        // this.ordgenerator.patchValue({ stplss: "" });
        this.is_target = true;
        // this.ordgenerator.patchValue({ target: "" });
        this.is_tralngstplss = true;
        // this.ordgenerator.patchValue({ tralngstplss: "" });
        this.is_priceVal = true;
        // this.ordgenerator.patchValue({ priceVal: "" });
        this.is_qtyVal = null;
        this.is_dPriceVal = null;
        this._validity_ioc = null;

      } else if (complexty == "regular" && position == "mis" && orderType == "MKT") {
        this.productType = 1;
        this.limit = false;
        this.is_slLmt = true;
        this.is_slMkt = true;
        this.is_mis = true;
        this.is_nrml = true;
        this._rlgr = true;
        this._bo = true;
        this._co = true;
        this._amo = true;
        this.is_trigPriceVal = true;
        // this.ordgenerator.patchValue({ trigPriceVal: "" });
        this.is_stplss = true;
        // this.ordgenerator.patchValue({ stplss: "" });
        this.is_target = true;
        // this.ordgenerator.patchValue({ target: "" });
        this.is_tralngstplss = true;
        // this.ordgenerator.patchValue({ tralngstplss: "" });
        this.is_priceVal = true;
        // this.ordgenerator.patchValue({ priceVal: "" });
        this.is_qtyVal = null;
        this.is_dPriceVal = null;
        this._validity_ioc = null;
      } else if (complexty == "regular" && position == "mis" && orderType == "SL") {
        this.productType = 3;
        this.is_mis = false;
        this.is_nrml = false;
        this.is_lmt = false;
        this.is_mkt = false;
        this.is_slLmt = false;
        this.is_slMkt = false;
        this.is_qtyVal = null;
        this.is_priceVal = null;
        this.is_trigPriceVal = null;
        this._rlgr = false;
        this._bo = true;
        this._co = true;
        this._amo = false;
        this._validity_day = false;
        this._validity_ioc = true;
        this.is_stplss = false;
        this.is_tralngstplss = false;
        this.is_target = false;
        this.is_discloseQty = null;
      } else if (complexty == "regular" && position == "mis" && orderType == "SL-M") {
        this.productType = 3;
        this.is_mis = true;
        this.is_nrml = true;
        this._rlgr = true;
        this._bo = true;
        this._co = true;
        this._amo = true;
        this.is_mkt = null;
        this.is_slMkt = null;
        this.is_qtyVal = null;
        this.is_dPriceVal = null;
        this.is_trigPriceVal = null;
        this.is_stplss = true;
        this._validity_ioc = null;
        // this.ordgenerator.patchValue({ stplss: "" });
        this.is_target = true;
        // this.ordgenerator.patchValue({ target: "" });
        this.is_tralngstplss = true;
        // this.ordgenerator.patchValue({ tralngstplss: "" });
        this.is_priceVal = true;
        // this.ordgenerator.patchValue({ priceVal: "" });

      } else if (complexty == "regular" && position == "cnc" && orderType == "L") {
        this.productType = 1;
        this.limit = true;
        this.is_mis = true;
        this.is_nrml = true;
        this._rlgr = true;
        this._bo = true;
        this._co = true;
        this._amo = true;
        this._validity_ioc = null;
        // this.ordgenerator.patchValue({ priceVal: this.priceValue });
        this.is_trigPriceVal = true;
        // this.ordgenerator.patchValue({ trigPriceVal: "" });
        this.is_stplss = true;
        // this.ordgenerator.patchValue({ stplss: "" });
        this.is_target = true;
        // this.ordgenerator.patchValue({ target: "" });
        this.is_tralngstplss = true;
        // this.ordgenerator.patchValue({ tralngstplss: "" });
        this.is_priceVal = null;
        this.is_dPriceVal = null;
        this.is_slLmt = true;
        this.is_slMkt = true;
        this.is_mkt = null;

      } else if (complexty == "regular" && position == "cnc" && orderType == "MKT") {
        this.productType = 1;
        this.limit = false;
        this.is_slLmt = true;
        this.is_slMkt = true;
        this.is_mis = true;
        this.is_nrml = true;
        this._rlgr = true;
        this._bo = true;
        this._co = true;
        this._amo = true;
        this.is_trigPriceVal = true;
        // this.ordgenerator.patchValue({ trigPriceVal: "" });
        this.is_stplss = true;
        // this.ordgenerator.patchValue({ stplss: "" });
        this.is_target = true;
        // this.ordgenerator.patchValue({ target: "" });
        this.is_tralngstplss = true;
        // this.ordgenerator.patchValue({ tralngstplss: "" });
        this.is_priceVal = true;
        // this.ordgenerator.patchValue({ priceVal: "" });
        this.is_qtyVal = null;
        this.is_dPriceVal = null;
        this._validity_ioc = null;

      } else if (complexty == "regular" && position == "cnc" && orderType == "SL") {
        this.productType = 3;
        this.is_mis = true;
        this.is_nrml = true;
        this._rlgr = true;
        this._bo = true;
        this._co = true;
        this._amo = true;
        this.is_qtyVal = null;
        this.is_trigPriceVal = null;
        this.is_priceVal = null;
        this.is_dPriceVal = null;
        this.is_stplss = true;
        // this.ordgenerator.patchValue({ stplss: "" });
        this.is_target = true;
        // this.ordgenerator.patchValue({ target: "" });
        this.is_tralngstplss = true;
        // this.ordgenerator.patchValue({ tralngstplss: "" });
        // this.ordgenerator.patchValue({ priceVal: this.priceValue });
        // this.ordgenerator.patchValue({ trigPriceVal: this.priceValue });
        this.is_mkt = null;
        this._validity_ioc = true;
        this.is_slLmt = null;
        this.is_slMkt = null;


      } else if (complexty == "regular" && position == "cnc" && orderType == "SL-M") {
        this.productType = 3;
        this.is_mis = true;
        this.is_nrml = true;
        this._rlgr = true;
        this._bo = true;
        this._co = true;
        this._amo = true;
        this.is_mkt = null;
        this.is_slMkt = null;
        this.is_qtyVal = null;
        this.is_dPriceVal = null;
        this.is_trigPriceVal = null;
        this.is_stplss = true;
        this._validity_ioc = true;
        // this.ordgenerator.patchValue({ stplss: "" });
        this.is_target = true;
        // this.ordgenerator.patchValue({ target: "" });
        this.is_tralngstplss = true;
        // this.ordgenerator.patchValue({ tralngstplss: "" });
        this.is_priceVal = true;
      } else {
        this.is_nrml = null;
        this.is_mkt = null;
        this.is_slMkt = null;
        this.is_qtyVal = null;
        this.is_dPriceVal = null;
        this.is_trigPriceVal = null;
        this.is_stplss = true;
        this.ordgenerator.patchValue({ stplss: "" });
        this.is_target = true;
        this.ordgenerator.patchValue({ target: "" });
        this.is_tralngstplss = true;
        this.ordgenerator.patchValue({ tralngstplss: "" });
        this.is_priceVal = true;
        this.ordgenerator.patchValue({ priceVal: "" });
        this.ordgenerator.patchValue({ trigPriceVal: this.priceValue });
        this._validity_ioc = true;
        this.is_mis = null;
        this._rlgr = null;
        this._bo = true;
        this._co = true;
        this._amo = null;
      }
    } else {
      if (complexty == "regular" && position == "mis" && orderType == "L") {
        this.productType = 1;
        this.is_mis = false;
        this.is_nrml = false;
        this.is_lmt = false;
        this.is_mkt = false;
        this.is_slLmt = false;
        this.is_slMkt = false;
        this.is_qtyVal = null;
        this.is_priceVal = null;
        this.is_trigPriceVal = true;
        this._rlgr = false;
        this._bo = true;
        this._co = true;
        this._amo = false;
        this._validity_day = false;
        this._validity_ioc = false;
        this.is_stplss = false;
        this.is_tralngstplss = false;
        this.is_target = false;
        this.is_discloseQty = null;
      } else if (complexty == "regular" && position == "mis" && orderType == "MKT") {
        this.productType = 1;
        this.is_mis = false;
        this.is_nrml = false;
        this.is_lmt = false;
        this.is_mkt = false;
        this.is_slLmt = false;
        this.is_slMkt = false;
        this.is_qtyVal = null;
        this.is_priceVal = true;
        this.is_trigPriceVal = true;
        this._rlgr = false;
        this._bo = true;
        this._co = true;
        this._amo = false;
        this._validity_day = false;
        this._validity_ioc = false;
        this.is_stplss = false;
        this.is_tralngstplss = false;
        this.is_target = false;
        this.is_discloseQty = null;
      } else if (complexty == "regular" && position == "mis" && orderType == "SL") {
        this.productType = 3;
        this.is_mis = false;
        this.is_nrml = false;
        this.is_lmt = false;
        this.is_mkt = false;
        this.is_slLmt = false;
        this.is_slMkt = false;
        this.is_qtyVal = null;
        this.is_priceVal = null;
        this.is_trigPriceVal = null;
        this._rlgr = false;
        this._bo = true;
        this._co = true;
        this._amo = false;
        this._validity_day = false;
        this._validity_ioc = true;
        this.is_stplss = false;
        this.is_tralngstplss = false;
        this.is_target = false;
        this.is_discloseQty = null;
      } else if (complexty == "regular" && position == "mis" && orderType == "SL-M") {
        this.productType = 3;
        this.is_mis = false;
        this.is_nrml = false;
        this.is_lmt = false;
        this.is_mkt = false;
        this.is_slLmt = false;
        this.is_slMkt = false;
        this.is_qtyVal = null;
        this.is_priceVal = true;
        this.is_trigPriceVal = null;
        this._rlgr = false;
        this._bo = true;
        this._co = true;
        this._amo = false;
        this._validity_day = false;
        this._validity_ioc = false;
        this.is_stplss = false;
        this.is_tralngstplss = false;
        this.is_target = false;
        this.is_discloseQty = null;

      } else if (complexty == "regular" && position == "cnc" && orderType == "L") {
        this.productType = 1;
        this.is_mis = false;
        this.is_nrml = false;
        this.is_lmt = false;
        this.is_mkt = false;
        this.is_slLmt = false;
        this.is_slMkt = false;
        this.is_qtyVal = null;
        this.is_priceVal = null;
        this.is_trigPriceVal = true;
        this._rlgr = false;
        this._bo = true;
        this._co = true;
        this._amo = false;
        this._validity_day = false;
        this._validity_ioc = false;
        this.is_stplss = false;
        this.is_tralngstplss = false;
        this.is_target = false;
        this.is_discloseQty = null;

      } else if (complexty == "regular" && position == "cnc" && orderType == "MKT") {
        this.productType = 1;
        this.is_mis = false;
        this.is_nrml = false;
        this.is_lmt = false;
        this.is_mkt = false;
        this.is_slLmt = false;
        this.is_slMkt = false;
        this.is_qtyVal = null;
        this.is_priceVal = true;
        this.is_trigPriceVal = true;
        this._rlgr = false;
        this._bo = true;
        this._co = true;
        this._amo = false;
        this._validity_day = false;
        this._validity_ioc = false;
        this.is_stplss = false;
        this.is_tralngstplss = false;
        this.is_target = false;
        this.is_discloseQty = null;

      } else if (complexty == "regular" && position == "cnc" && orderType == "SL") {
        this.productType = 3;
        this.is_mis = false;
        this.is_nrml = false;
        this.is_lmt = false;
        this.is_mkt = false;
        this.is_slLmt = false;
        this.is_slMkt = false;
        this.is_qtyVal = null;
        this.is_priceVal = null;
        this.is_trigPriceVal = null;
        this._rlgr = false;
        this._bo = true;
        this._co = true;
        this._amo = false;
        this._validity_day = false;
        this._validity_ioc = false;
        this.is_stplss = false;
        this.is_tralngstplss = false;
        this.is_target = false;
        this.is_discloseQty = null;

      } else if (complexty == "regular" && position == "cnc" && orderType == "SL-M") {
        this.productType = 3;
        this.is_mis = false;
        this.is_nrml = false;
        this.is_lmt = false;
        this.is_mkt = false;
        this.is_slLmt = false;
        this.is_slMkt = false;
        this.is_qtyVal = null;
        this.is_priceVal = true;
        this.is_trigPriceVal = null;
        this._rlgr = false;
        this._bo = true;
        this._co = true;
        this._amo = false;
        this._validity_day = false;
        this._validity_ioc = false;
        this.is_stplss = false;
        this.is_tralngstplss = false;
        this.is_target = false;
        this.is_discloseQty = null;

      } else if (complexty == "regular" && position == "cnc" && orderType == "SL-M") {
        this.productType = 3;
        this.is_mis = false;
        this.is_nrml = false;
        this.is_lmt = false;
        this.is_mkt = false;
        this.is_slLmt = false;
        this.is_slMkt = false;
        this.is_qtyVal = null;
        this.is_priceVal = true;
        this.is_trigPriceVal = null;
        this._rlgr = false;
        this._bo = true;
        this._co = true;
        this._amo = false;
        this._validity_day = false;
        this._validity_ioc = false;
        this.is_stplss = false;
        this.is_tralngstplss = false;
        this.is_target = false;
        this.is_discloseQty = null;
      }
    }
  }

  /**
   * Method to check the tick value
   * @param inputtyp
   * @author Kalaivani
   * @since 20-03-2020
   */
  checkTick(inputtyp) {
    var priceVal: any = this.ordgenerator.value["priceVal"];
    var trigPriceVal = this.ordgenerator.value["trigPriceVal"];
    var qtyy = this.ordgenerator.value["quantity"];
    var trget = this.ordgenerator.value["target"];
    var stloss = this.ordgenerator.value["stplss"];
    var trlingstplss = this.ordgenerator.value["tralngstplss"];
    var discQty = Number(this.ordgenerator.value["discQty"]);
    switch (inputtyp) {
      case "price":
        var price = this.floatSafeRemainder(
          parseFloat(priceVal.toString()),
          this.tikPrc
        );
        if (price != 0) {
          this.chkPrc = true;
        } else {
          this.chkPrc = false;
        }
        break;
      case "qty":
        var price = this.floatSafeRemainder(parseFloat(qtyy), this.tikLot);
        if (price != 0) {
          this.chkqty = true;
        } else {
          this.chkqty = false;
        }
        break;
      case "tprice":
        var price = this.floatSafeRemainder(
          parseFloat(trigPriceVal),
          this.tikPrc
        );
        if (price != 0) {
          this.chkTPrc = true;
        } else {
          this.chkTPrc = false;
        }
        break;
      case "target":
        var price = this.floatSafeRemainder(parseFloat(trget), this.tikPrc);
        if (price != 0) {
          this.chktrgt = true;
        } else {
          this.chktrgt = false;
        }
        break;
      case "stoploss":
        var price = this.floatSafeRemainder(parseFloat(stloss), this.tikPrc);
        if (price != 0) {
          this.chkstp = true;
        } else {
          this.chktrgt = false;
        }
        break;
      case "discQty":
        if (this.exch["Exchange"] == "MCX") {
          this.discTen = Math.ceil(discQty * (25 / 100));
        } else {
          this.discTen = Math.ceil(discQty * (10 / 100));
        }

        if (discQty == 0 || (discQty >= this.discTen && discQty <= qtyy)) {
          this.chkDisc = false;
        } else {
          this.chkDisc = true;
          return false;
        }
        break;
    }
  }

  /**
   * Method to validate
   * @param val
   * @param step
   */
  floatSafeRemainder(val, step) {
    var valDecCount = (val.toString().split('.')[1] || '').length;
    var stepDecCount = (step.toString().split('.')[1] || '').length;
    var decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
    var valInt = parseInt(val.toFixed(decCount).replace('.', ''));
    var stepInt = parseInt(step.toFixed(decCount).replace('.', ''));
    return (valInt % stepInt) / Math.pow(10, decCount);
  }


  /**
   * Method to place order
   * @author Kalaivani
   * @since 23-03-2020
   *
   */
  orderplace() {
    var buySellindicator: any;
    var product_type: any;
    if (this.ordgenerator.value.bstype == false || this.ordgenerator.value.bstype == "BUY") {
      buySellindicator = 1;
    } else {
      buySellindicator = 2;
    }
    if (this.ordgenerator.value["position"] == "cnc") {
      product_type = "Y";
    } else {
      product_type = "N";
    }
    if (this.page == "orderModify") {
      var intern_odr_Id: any = [];
      intern_odr_Id.push(this.modifyData['InternalOrderId']);
      let jsonObj = {
        "internalLoginId": this.service.getInternalId(),
        "internalScripToken": this.modifyData['InternalScripToken'],
        "userId": this.service.getUserId(),
        "nseToken": this.modifyData['NSEtoken'] == "0" ? '-1' : this.modifyData['NSEtoken'],
        "bseToken": this.modifyData['BSEtoken'] == "0" ? '-1' : this.modifyData['BSEtoken'],
        "exchangeId": this.modifyData['exchangeId'],
        "marketId": this.modifyData['marketId'],
        "symbol": this.modifyData['Symbol'],
        "expiryDate": this.modifyData['ExpiryDate'] == "" ? 'NA' : this.modifyData['ExpiryDate'],
        "strikePrice": this.modifyData['StrikePrice'] == "" ? 'NA' : this.modifyData['StrikePrice'],
        "optionType": this.modifyData['OptionType'] == "" ? 'NA' : this.modifyData['OptionType'],
        "series": this.modifyData['series'],
        "qty": this.ordgenerator.value.quantity,
        "price": this.limit == false ? "0.00" : this.ordgenerator.value.priceVal,
        "trigPrice": this.productType == "3" ? this.ordgenerator.value.trigPriceVal : "",
        "discQty": this.ordgenerator.value["discQty"],
        "productType": product_type,
        "buyOrSell": buySellindicator,
        "orderStatus": this.modifyData['Status'],
        "clientOrBroker": "1",
        "orderType": (this.modifyData["Exchange"] == "NSE" || this.modifyData["Exchange"] == "BSE") && this.modifyData['series'] == "EQ" ? "NA" : this.productType,
        "participantType": "",
        "institutionalClient": "",
        "goodTillDate": "0",
        "gtc": "NA",
        "ioc": "0",
        "oeBookType": (this.modifyData["Exchange"] == "NSE" || this.modifyData["Exchange"] == "BSE") && this.modifyData["series"] == "EQ" ? this.productType : "NA",
        "oeSegment": this.modifyData["series"] == "EQ" ? "1" : "",
        "oeGroup": this.modifyData["series"] == "EQ" && this.modifyData["Exchange"] == "BSE" ? "A" : "NA",
        "blockDeal": "N",
        "instrumentType": this.modifyData["Series"] == "EQ" ? "NA" : this.modifyData['InstrumentType'],
        "orderNumber": this.modifyData['OrderNumber'],
        "internalOrderId": intern_odr_Id
      }
      this.service.modifyScrip(jsonObj).subscribe(resp => {
        this.loader.stop();
        this.dialogRef.close();
        if (resp['StatusOfRequest']['Status'] == "0") {
          // this.sharedata.excuteOrders(true);
          this.service.toastr.success("Order modified successfully");
        } else {
          this.service.toastr.error("Order modify failed");
        }
      }, (err) => {
        this.loader.stop();
        this.service.toastr.error(err);
      })
    } else if (this.page == "mktwatch" || this.page == "position") {
      let JsonObj: any;
      if (this.page == "mktwatch") {
        JsonObj = {
          internalLoginId: this.userDetails["UserDetails"]["UserInternalID"],
          internalScripToken: this.curScrip["Token"],
          userId: this.userID,
          nseToken:
            this.curScrip["NSEtoken"] == "0" ? "-1" : this.curScrip["NSEtoken"],
          bseToken:
            this.curScrip["BSEtoken"] == "0" ? "-1" : this.curScrip["BSEtoken"],
          exchangeId: this.curScrip["Exchange"]["Id"],
          marketId: this.curScrip["Market"]["Id"],
          symbol: this.curScrip["Symbol"],
          expiryDate: this.curScrip["OEexpiryDate"],
          strikePrice:
            this.curScrip["Series"] == "EQ"
              ? "NA"
              : this.curScrip["Market"]["Name"] == "Currency"
                ? this.curScrip["StrikePrice"] * 10000
                : this.curScrip["StrikePrice"] * 100,
          optionType:
            this.curScrip["Series"] == "EQ" ? "" : this.curScrip["OptionType"],
          series: this.curScrip["Series"],
          qty: this.ordgenerator.value.quantity,
          price: this.limit == false ? "0.00" : this.ordgenerator.value.priceVal,
          trigPrice:
            this.productType == "3" ? this.ordgenerator.value.trigPriceVal : "",
          discQty: this.ordgenerator.value["discQty"],
          productType: product_type,
          buyOrSell: buySellindicator,
          orderStatus: "",
          clientOrBroker: "1",
          orderType: (this.curScrip["Exchange"]['Name'] == "NSE" || this.curScrip["Exchange"]['Name'] == "BSE") && this.curScrip['Series'] == "EQ" ? "NA" : this.productType,
          participantType: "",
          institutionalClient: "",
          goodTillDate: "",
          gtc: "",
          ioc: "0",
          oeBookType: (this.curScrip["Exchange"]['Name'] == "NSE" || this.curScrip["Exchange"]['Name'] == "BSE") && this.curScrip["Series"] == "EQ" ? this.productType : "NA",
          oeSegment: this.curScrip["Series"] == "EQ" ? "1" : "",
          oeGroup:
            this.curScrip["Series"] == "EQ" && this.curScrip["Exchange"]['Name'] == "BSE"
              ? "A"
              : "NA",
          blockDeal: "N",
          instrumentType: this.curScrip["Series"] == "EQ" ? "NA" : this.curScrip['InstrumentType'],
        };
      } else if (this.page == "position") {
        JsonObj = {
          internalLoginId: this.userDetails["UserDetails"]["UserInternalID"],
          internalScripToken: this.curScrip["Token"],
          userId: this.userID,
          nseToken:
            this.curScrip["NSEtoken"] == "0" ? "-1" : this.curScrip["NSEtoken"],
          bseToken:
            this.curScrip["BSEtoken"] == "0" ? "-1" : this.curScrip["BSEtoken"],
          exchangeId: this.curScrip["ExchangeId"],
          marketId: this.curScrip["MarketId"],
          symbol: this.curScrip["Symbol"],
          expiryDate: this.curScrip["ExpiryDate"],
          strikePrice: this.curScrip["series"] == "EQ" ? "NA" : this.curScrip["MarketId"] == "4" ? this.curScrip["StrikePrice"] * 10000 : this.curScrip["StrikePrice"] * 100,
          optionType: this.curScrip["series"] == "EQ" ? "" : this.curScrip["OptionType"],
          series: this.curScrip["series"],
          qty: this.ordgenerator.value.quantity,
          price: "0.00",
          trigPrice:
            this.productType == "3" ? this.ordgenerator.value.trigPriceVal : "",
          discQty: this.ordgenerator.value["discQty"],
          productType: product_type,
          buyOrSell: buySellindicator,
          orderStatus: "",
          clientOrBroker: "1",
          orderType: (this.curScrip["ExchangeName"] == "NSE" || this.curScrip["ExchangeName"] == "BSE") && this.curScrip['series'] == "EQ" ? "NA" : this.productType,
          participantType: "",
          institutionalClient: "",
          goodTillDate: "",
          gtc: "",
          ioc: "0",
          oeBookType: (this.curScrip["ExchangeName"] == "NSE" || this.curScrip["ExchangeName"] == "BSE") && this.curScrip["series"] == "EQ" ? this.productType : "NA",
          oeSegment: this.curScrip["series"] == "EQ" ? "1" : "",
          oeGroup:
            this.curScrip["series"] == "EQ" && this.curScrip["ExchangeName"] == "BSE"
              ? "A"
              : "NA",
          blockDeal: "N",
          instrumentType: this.curScrip["series"] == "EQ" ? "NA" : this.curScrip['InstrumentType'],
        };
      }
      this.service.placeOrder(JsonObj).subscribe((resp) => {
        this.loader.stop();
        if (resp["StatusOfRequest"]["Status"] == "0") {
          this.service.toastr.success("Order submitted successfully");
          this.dialogRef.close(true);
          // this.toastr.success("Order placed Successfully");
          // this.sharedata.excuteOrders(true);
        } else {
          this.toastr.error(resp['StatusOfRequest']['Message']);
          this.dialogRef.close();
        }
      }, (err) => {
        this.service.toastr.error(err);
        this.loader.stop();
      });
    }
  }

}
