import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { IfStmt, ReturnStatement } from "@angular/compiler";
import { HttpService } from "src/app/services/http.service";
// import { HttpService } from 'src/app/services/http.service';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Router } from "@angular/router";
import { ChartingLibraryWidgetOptions, IChartingLibraryWidget, widget } from '../../../assets/charting_library/charting_library.min';
import { DEC } from "@angular/material";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { IndexlistComponent } from "../indexlist/indexlist.component";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { debug } from "util";
import { Datafeed } from '../chart/datafeed';
// import { LogoutpopupComponent } from 'src/app/logoutpopup/logoutpopup.component';
import { OrderwindowComponent } from 'src/app/orderwindow/orderwindow.component';
import { AppComponent } from 'src/app/app.component';
import { WebsocketService } from 'src/app/services/websocket.service';
import { SharedataService } from 'src/app/services/sharedata.service';
import { ScripinfoComponent } from 'src/app/scripinfo/scripinfo.component';
// import { LogoutpopupComponent } from '../logoutpopup/logoutpopup.component';
import { HttpClient } from '@angular/common/http';
import { NotificationsComponent } from 'src/app/notifications/notifications.component';

declare function customChangeSymbol(): any;


@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  searchbox: string;
  favoriteSeason: any;
  showList: boolean;
  searchList: any = [];
  showFilter: boolean = false;
  currGp: number = 0;
  // mktWatch: any = "mw";
  userDetails: any = [];
  userID: any;
  mwDetails: any = [];
  mktload: boolean;
  nomktwtc: boolean;
  leftindexchart: any = 1;
  rightindexchart: any = 2;
  ordertiktoken: any;
  mktlistload: Boolean = false;
  scripsmkt: any = [];
  mw_one: any;
  mw_two: any;
  mw_three: any;
  mw_four: any;
  mw_five: any;
  depthData: any;
  selectedItem: any;
  marketId: any;
  indexNames: any = [];
  indexSource: any = [];
  indexSegment: any = [];
  indexName1: any;
  indexName2: any;
  idx_value1: any = "0.00";
  idx_change1: any = "0.00";
  idx_change2: any = "0.00";
  idx_value2: any = "0.00";
  scripData: any = [];
  segment1: any;
  segment2: any;
  source1: any;
  source2: any;
  defaultMw: any;
  nifindexChange: number = 0;
  senxindexChange: number = 0;
  isHandset$: any;
  mwList: any;
  internalID: string;
  sessionID: string;
  dpCall: string;
  mw_one_tickPrice: any;
  mw_two_tickPrice: any;
  mw_three_tickPrice: any;
  mw_four_tickPrice: any;
  mw_five_tickprice: any;
  addScrip: boolean = false;
  isMWCall: any;
  badgeValue: any = "";

  // private _symbol: ChartingLibraryWidgetOptions['symbol'] = 'NIFTY 50::NSE::Index';
  private _symbol: ChartingLibraryWidgetOptions['symbol'] = 'INFY::NSE';
  private _interval: ChartingLibraryWidgetOptions['interval'] = 'D';
  // BEWARE: no trailing slash is expected in feed URL
  private _datafeedUrl = HttpService.chartURL;
  private _libraryPath: ChartingLibraryWidgetOptions['library_path'] = '../assets/charting_library/';
  private _fullscreen: ChartingLibraryWidgetOptions['fullscreen'] = false;
  private _autosize: ChartingLibraryWidgetOptions['autosize'] = true;
  private _containerId: ChartingLibraryWidgetOptions['container_id'] = 'tv_chart_container';
  private _tvWidget: IChartingLibraryWidget | null = null;
  private _chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'] = 'https://saveload.tradingview.com';
  latestInfo: any = [];


  constructor(
    public routeTo: Router,
    public service: HttpService,
    public dialog: MatDialog,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
    public shareData: SharedataService,
    public appcomponent: AppComponent,
    public websocket: WebsocketService,
    public http: HttpClient,
  ) {
    if (window.innerWidth > 720) {
      if (localStorage.getItem("userid") != undefined || localStorage.getItem("userid") != null) {
        this.routeTo.navigateByUrl('usercontrol/dashboard');
      } else {
        this.routeTo.navigateByUrl('entrylevel/login');
      }
    } else {
      if (localStorage.getItem("userid") != undefined || localStorage.getItem("userid") != null) {
        this.routeTo.navigateByUrl('/mobilewindow/mobileview');
      } else {
        this.routeTo.navigateByUrl('entrylevel/login');
      }
    }
    // TCP response
    this.shareData.webSocketData.subscribe((msg: any) => {
      for (let idx in this.scripsmkt) {
        if (msg[4] != "" && msg[8] != "") {
          if (msg[4] == this.scripsmkt[idx]['Token']) {
            // console.log("matched", msg[37], msg[8])
            this.scripsmkt[idx]['LTP'] = msg[8];
          }
        } else if (msg[4] != "" && msg[8] != "") {
          if (msg[4] == this.scripsmkt[idx]['Token']) {
            // console.log("matched", msg[37], msg[8])
            this.scripsmkt[idx]['LTP'] = msg[8];
          }
        }
      }
      // } else if (msg.length == "45") {
      //   for (let idx in this.scripsmkt) {
      //     if (msg[4] != "" && msg[8] != "") {
      //       if (msg[4] == this.scripsmkt[idx]['Token']) {
      //         console.log("matched", msg[37], msg[8])
      //         this.scripsmkt[idx]['LTP'] = msg[8];
      //       }
      //     }
      //   }
      // }
    });

    this.shareData.depthDataShare.subscribe((msg: any) => {//Depth
      if (msg[0] == "60") {
        if (msg[4] != "") {
          this.scripsmkt.map(item => {
            if (item['Token'] == msg[4]) {
              // console.log("depth", item['Symbol'], msg);
              item['bno'] = msg[17];
              item['bno1'] = msg[20];
              item['bno2'] = msg[23];
              item['bno3'] = msg[26];
              item['bno4'] = msg[29];
              item['bp'] = msg[16];
              item['bp1'] = msg[19];
              item['bp2'] = msg[22];
              item['bp3'] = msg[25];
              item['bp4'] = msg[28];
              item['bq'] = msg[15];
              item['bq1'] = msg[18];
              item['bq2'] = msg[21];
              item['bq3'] = msg[24];
              item['bq4'] = msg[27];
              item['sno'] = msg[32];
              item['sno1'] = msg[35];
              item['sno2'] = msg[38];
              item['sno3'] = msg[41];
              item['sno4'] = msg[44];
              item['sp'] = msg[31];
              item['sp1'] = msg[34];
              item['sp2'] = msg[37];
              item['sp3'] = msg[40];
              item['sp4'] = msg[43];
              item['bs'] = msg[30];
              item['bs1'] = msg[33];
              item['bs2'] = msg[36];
              item['bs3'] = msg[39];
              item['bs4'] = msg[42];
              item['tbq'] = msg[45];
              item['tsq'] = msg[46];
              item["v"] = msg[7] == undefined ? '0' : msg[7];
              item["ap"] = msg[14];
              item['weightAvgPrc'] = msg[14] == undefined ? '0' : msg[14];
              item['TTV'] = msg[60];
              item['LTT'] = msg[13];
              item['OI'] = msg[62];
              item['LUT'] = msg[59];
              item['DPR'] = msg[61];
            }
          })
        }
      } else if (msg[0] == "61") {
        // console.log(msg)
      }
    });
    this.shareData.indexDataShare.subscribe((msg: any) => { //Index 
      if (msg[0] == "69") {
        // console.log(msg)
      }
    });
    this.shareData.badgeLengthshare.subscribe((msg: any) => { //notifications
      if (msg !== "") {
        this.badgeValue = msg == 0 ? "" : msg;
        this.latestInfo = [];
        localStorage.setItem("notifyMsg", JSON.stringify(this.latestInfo))
      }
    });
    this.shareData.orderPlaceTcp.subscribe((msg: any) => {
      if (msg !== "") {
        if (this.routeTo.url == "/usercontrol/dashboard/order") {
          this.badgeValue = msg == 0 ? "" : msg;
          this.latestInfo = [];
          localStorage.setItem("notifyMsg", JSON.stringify(this.latestInfo))
        } else {
          if (localStorage.getItem("notifyMsg") != null || localStorage.getItem("notifyMsg") != undefined) {
            this.latestInfo = JSON.parse(localStorage.getItem("notifyMsg"));
            this.latestInfo.push(msg);
          } else {
            this.latestInfo.push(msg);
          }
          localStorage.setItem("notifyMsg", JSON.stringify(this.latestInfo));
        }

        if (localStorage.getItem("notifyMsg") != null || localStorage.getItem("notifyMsg") != undefined) {
          this.latestInfo = JSON.parse(localStorage.getItem("notifyMsg"));
          this.badgeValue = this.latestInfo.length;
        }
      }
    });
  }


  ngOnInit() {
    this.loader.start();
    if (localStorage.getItem("userdetails") != null || localStorage.getItem("userdetails") != undefined) {
      var userDetails = JSON.parse(localStorage.getItem("userdetails"));
      this.userDetails = userDetails;
      this.userID = this.userDetails["UserDetails"]["UserID"];
      this.mwDetails = this.userDetails["MarketWatchDetails"];
      this.indexNames = this.userDetails["IndexNames"];
      this.indexSource = this.userDetails["IndexSources"];
      this.indexSegment = this.userDetails["IndexSegments"];
      this.defaultMw = this.userDetails["DefaultMarketWatch"];
      this.internalID = this.userDetails["UserDetails"]['UserInternalID'];
      if (localStorage.getItem("sessionId") != null || localStorage.getItem("sessionId") != undefined) {
        this.sessionID = JSON.parse(localStorage.getItem("sessionId"))
      }

      //Market watch name
      this.mw_one = this.userID + "_111";
      this.mw_two = this.userID + "_121";
      this.mw_three = this.userID + "_131";
      this.mw_four = this.userID + "_141";
      this.mw_five = this.userID + "_151";

    }

    this.initialMarketWatchGroupName();
    this.getIndexRecords();
    this.loader.stop();

  }

  initialMarketWatchGroupName() {
    var userDetails: any = JSON.parse(localStorage.getItem("userdetails"));
    var marketWatchList = userDetails['MarketWatchDetails'];
    for (let index of marketWatchList) {
      var mwuserid = index['MarketWatchName'].split("_")[0];
      var mwusername = index['MarketWatchName'].split("_")[1];
      if (mwuserid) {
        if (mwuserid == this.userID) {
          if (mwusername == "111") {
            this.mw_one = index['MarketWatchID'];
          }
          if (mwusername == "121") {
            this.mw_two = index['MarketWatchID'];
          }
          if (mwusername == "131") {
            this.mw_three = index['MarketWatchID'];
          }
          if (mwusername == "141") {
            this.mw_four = index['MarketWatchID'];
          }
          if (mwusername == "151") {
            this.mw_five = index['MarketWatchID'];
          }
        }
      }
    }
    if (this.defaultMw == this.mw_one || this.defaultMw == this.mw_two || this.defaultMw == this.mw_three || this.defaultMw == this.mw_four || this.defaultMw == this.mw_five) {
      this.marketId = this.defaultMw;
      if (this.defaultMw == this.mw_one) {
        localStorage.setItem("mw_one", JSON.stringify(this.mw_one))
        this.currGp = 0;
      }
      if (this.defaultMw == this.mw_two) {
        this.currGp = 1;
        localStorage.setItem("mw_two", JSON.stringify(this.mw_two))
      }
      if (this.defaultMw == this.mw_three) {
        this.currGp = 2;
        localStorage.setItem("mw_three", JSON.stringify(this.mw_three))

      }
      if (this.defaultMw == this.mw_four) {
        this.currGp = 3;
        localStorage.setItem("mw_four", JSON.stringify(this.mw_four))

      }
      if (this.defaultMw == this.mw_five) {
        localStorage.setItem("mw_five", JSON.stringify(this.mw_three))
        this.currGp = 4;
      }
      this.getMarketWatch(this.defaultMw);
    } else {
      this.createMarketWatch(this.mw_one);
    }


  }

  /**
 * WebSocket for market watch
 */
  callSocketConnMW() {
    this.appcomponent.callConnectionSocket();
  }


  //open side menu
  openNav() {
    document.getElementById("mySidepanel").style.width = "250px";
  }

  //close side menu
  closeNav() {
    document.getElementById("mySidepanel").style.width = "0";
  }

  //Method to close menu
  closeItems() {
    if (this.showList != false) {
      setTimeout(() => {
        this.searchbox = "";
        this.searchList = [];
        this.showFilter = false;
      }, 300);
    } else {
      this.showList = false;
    }
  }

  //To find the current market group
  // getMarketID(value) {
  //     //   var mwName: any;
  //   this.currGp = JSON.parse(localStorage.getItem("currMwIndex"));
  //   if (this.currGp == 0) {
  //     mwName = this.mw_one;
  //   } else if (this.currGp == 1) {
  //     mwName = this.mw_two;
  //   } else if (this.currGp == 2) {
  //     mwName = this.mw_three;
  //   } else if (this.currGp == 3) {
  //     mwName = this.mw_four;
  //   } else if (this.currGp == 4) {
  //     mwName = this.mw_five;
  //   } else {
  //     this.marketId = value;
  //   }

  //   //To get particular market watch Id
  //   for (let idx of this.mwDetails) {
  //     if (idx["MarketWatchName"] == mwName) {
  //       this.marketId = idx["MarketWatchID"];
  //     }
  //   }
  //   return this.marketId;
  // }

  /**
   * Method to get Market Watch list
   * @author Kalai
   * @on 29/02/2020
   */
  getMarketWatch(value) {
    this.mktlistload = true;
    this.marketId = value;
    let JsonObj: any = {
      marketWatchId: this.marketId,
      userId: this.userID,
    };
    this.service.marketWatch(JsonObj).subscribe(
      (response) => {
        this.mktlistload = false;
        if (response["StatusOfRequest_a"]["Status"] == 0) {
          if (response["MarketWatchScrips"].length) {
            this.callSocketConnMW();
            this.scripsmkt = response["MarketWatchScrips"];
            this.scripData = response["MarketWatchScripsDetails"];
            this.mwList = ""
            var scripToken: any = [];
            var token: any = [];
            var symbol: any = [];
            let depths = new Array();
            // for (let idx of this.scripData) {
            //   scripToken.push(idx['InternalScripToken'] + "^" + idx['ExchangeId'] + "^" + idx['MarketId']);
            //   let dp = this.dpCall = "OPENMBP|" + this.service.getInternalId() + "|" + this.service.getSessionToken() + "|" + idx['InternalScripToken'] + "^" + idx['ExchangeId'] + "^" + idx['MarketId'];
            //   depths.push(dp);
            // }
            // let temp = 0;
            // let interval = setInterval(() => {
            //   console.log(depths[temp]);
            //   this.service.mWatch.next(depths[temp]);
            //   temp += 1;
            //   if (depths.length == temp) {
            //     clearInterval(interval);
            //   }
            // }, 1000)
            for (let idx of this.scripData) {
              scripToken.push(idx['InternalScripToken'] + "^" + idx['ExchangeId'] + "^" + idx['MarketId']);
            }
            this.appcomponent._marketId = this.marketId;
            this.appcomponent._isMWCall = scripToken.join("~");
            this.appcomponent.subscribeMessage();
            for (let idx of this.scripsmkt) {
              token.push(idx['Token']);
              symbol.push(idx['Symbol']);
            }
            // this.getScripTickSize(token, symbol);
            if (this.scripsmkt.length > 0) {
              this.nomktwtc = false;
            } else {
              this.nomktwtc = true;
            }
            localStorage.setItem("mwDetails", JSON.stringify(response));
          } else {
            this.nomktwtc = true;
          }
        } else {
          if (response['StatusOfRequest_a']['Message'] == "User has been logged off") {
            this.service.unauthorized();
          }
          this.nomktwtc = true;
        }
      },
      (err) => {
        if (err.error == "Unauthorized") {
          this.service.unauthorized();
          this.appcomponent._marketId = this.marketId;
          this.appcomponent.mwClose();
        }
      }
    );
  }

  /**
   * Method to Add scrip to market watch
   * @author Kalai
   * @on 02-03-2020
   */
  setSelectVal(slctData, edit, list) {
    this.loader.start();
    let jsonObj: any = {
      mwId: this.marketId,
      marketId: slctData.marketId,
      exchangeId: slctData.exchangeId,
      internalScripToken: slctData.internalToken,
      symbol: slctData.symbol,
      series: slctData.series == "EQ" ? slctData.series : "",
      instrumentName: slctData.series == "EQ" ? "" : slctData.instrumentName,
      expriyDate: slctData.series == "EQ" ? "NA" : slctData.expiryDate,
      strikePrice: (slctData.series == "EQ") || (slctData.InstrumentType == 'FUTSTK') ? "" : slctData.exchange == "CDS" ? slctData.strikePrice * 10000 : slctData.strikePrice * 100,
      optionType: slctData.series == "EQ" ? "" : slctData.optionType,
      userId: this.userID,
    };
    if (edit == "add") {
      this.service.addScripToMW(jsonObj).subscribe(
        (resp) => {
          this.loader.stop();
          if (resp["StatusOfRequest_a"]["status"] == 0) {
            this.showList = false;
            this.addScrip = true;
            if (
              localStorage.getItem("symbolList") != null || localStorage.getItem("symbolList")
            ) {
              this.searchList = JSON.parse(localStorage.getItem("symbolList"));
            }
            if (this.searchList.length > 0) {
              localStorage.setItem(
                "addMarketWatch",
                JSON.stringify(resp["MarketWatchScrip"])
              );
              for (let idex of this.searchList) {
                var idxid: number = this.searchList.indexOf(idex);
                if (idex["symbol"] == slctData["symbol"]) {
                  this.searchList[idxid]["checked"] = false;
                }
              }
              this.getMarketWatch(this.marketId);
              this.toastr.success(
                "Successfully added to the Market Watch List",
                "Market Watch List"
              );
            }
          } else {
            this.toastr.error(resp["StatusOfRequest_a"]["Message"]);
          }
        },
        (err) => {
          if (err.error == "Unauthorized") {
            this.service.unauthorized();
            this.loader.stop();
            this.appcomponent._marketId = this.marketId;
            this.appcomponent.mwClose();
          }
        }
      );
    } else {
      //Delete
      var internal_scripId: any;
      if (list == "li") {
        for (let idx of this.scripData) {
          if (idx["InternalScripToken"] == slctData["internalToken"]) {
            internal_scripId = idx["InternalScripId"];
          }
        }
      } else {
        for (let idx of this.scripData) {
          if (idx["InternalScripToken"] == slctData["Token"]) {
            internal_scripId = idx["InternalScripId"];
          }
        }
      }
      jsonObj = {
        userId: this.userID,
        internalScripId: internal_scripId,
      };
      this.service.deleteMarketWatch(jsonObj).subscribe(
        (resp) => {
          this.loader.stop();
          if (resp["StatusOfRequest"]["status"]) {
            this.toastr.success(resp["StatusOfRequest"]["Message"]);
            this.getMarketWatch(this.marketId);
          } else {
            this.toastr.error("Error");
          }
        },
        (err) => {
          if (err.error == "Unauthorized") {
            this.service.unauthorized();
            this.loader.stop();
            this.appcomponent._marketId = this.marketId;
            this.appcomponent.mwClose();
          }
        }
      );
    }
  }

  /**
   * Method to Call the Depth request for Market Watch
   * @param event
   * @author Kalai
   * @on 05-03-2020
   */
  showDepth(scrips, i, type) {
    var previndex = i;
    this.scripsmkt.map((item) => {
      if (item["Token"] == scrips["Token"]) {
        if (item["showDP"]) {
          item["showDP"] = false;
          // this.sendMessage();
        } else {
          item["showDP"] = true;
          // this.sendMessage();
        }
      }
    });
    //To get the segID and exchID by matching token from mw
    var exchID: any;
    var marketID: any;
    var internal_scripId: any;
    var internalToken: any;
    for (let idx in this.scripData) {
      if (this.scripData[idx]["InternalScripToken"] == scrips["Token"]) {
        exchID = this.scripData[idx]["ExchangeId"];
        marketID = this.scripData[idx]["MarketId"];
        internal_scripId = this.scripData[idx]["internalScripId"];
        internalToken = this.scripData[idx]['InternalScripToken'];
      }
    }

    if (scrips['showDP']) {
      let jsonObj: any = {
        marketId: marketID,
        exchangeId: exchID,
        internalScripToken: scrips["Token"],
        userId: this.userID,
      };
      this.service.depthNewRequest(jsonObj).subscribe(
        (resp) => {
          if (resp["StatusOfRequest"]["Status"] == 0) {
            this.dpCall = "";
            this.dpCall = "OPENMBP|" + this.internalID + "|" + this.sessionID + "|" + internalToken + "^" + exchID + "^" + marketID;
            console.log(this.dpCall);
            let temp = 0;
            // let interval = setInterval(() => {
            this.service.mWatch.next(this.dpCall);
            // }, 1000)
            // clearInterval(interval);

            this.scripsmkt.map((item) => {
              if (scrips["Token"] == item["Token"]) {
                item["bno"] = resp["MarketDepthData"]["BuyOrder_1"];
                item["bno1"] = resp["MarketDepthData"]["BuyOrder_2"];
                item["bno2"] = resp["MarketDepthData"]["BuyOrder_3"];
                item["bno3"] = resp["MarketDepthData"]["BuyOrder_4"];
                item["bno4"] = resp["MarketDepthData"]["BuyOrder_5"];
                item["bp"] = resp["MarketDepthData"]["BuyPrice_1"];
                item["bp1"] = resp["MarketDepthData"]["BuyPrice_2"];
                item["bp2"] = resp["MarketDepthData"]["BuyPrice_3"];
                item["bp3"] = resp["MarketDepthData"]["BuyPrice_4"];
                item["bp4"] = resp["MarketDepthData"]["BuyPrice_5"];
                item["bq"] = resp["MarketDepthData"]["BuyQty_1"];
                item["bq1"] = resp["MarketDepthData"]["BuyQty_2"];
                item["bq2"] = resp["MarketDepthData"]["BuyQty_3"];
                item["bq3"] = resp["MarketDepthData"]["BuyQty_4"];
                item["bq4"] = resp["MarketDepthData"]["BuyQty_5"];
                item["sno"] = resp["MarketDepthData"]["SellOrder_1"];
                item["sno1"] = resp["MarketDepthData"]["SellOrder_2"];
                item["sno2"] = resp["MarketDepthData"]["SellOrder_3"];
                item["sno3"] = resp["MarketDepthData"]["SellOrder_4"];
                item["sno4"] = resp["MarketDepthData"]["SellOrder_5"];
                item["sp"] = resp["MarketDepthData"]["SellPrice_1"];
                item["sp1"] = resp["MarketDepthData"]["SellPrice_2"];
                item["sp2"] = resp["MarketDepthData"]["SellPrice_3"];
                item["sp3"] = resp["MarketDepthData"]["SellPrice_4"];
                item["sp4"] = resp["MarketDepthData"]["SellPrice_5"];
                item["bs"] = resp["MarketDepthData"]["SellQty_1"];
                item["bs1"] = resp["MarketDepthData"]["SellQty_2"];
                item["bs2"] = resp["MarketDepthData"]["SellQty_3"];
                item["bs3"] = resp["MarketDepthData"]["SellQty_4"];
                item["bs4"] = resp["MarketDepthData"]["SellQty_5"];
                item["tbq"] = resp["MarketDepthData"]["Tot.BuyQty"];
                item["tsq"] = resp["MarketDepthData"]["Tot.SellQty"];
                item["v"] = resp["MarketDepthData"]["Vol.TradedToday"];
                item["ap"] = resp["MarketDepthData"]["Avg.TradePrice"];
                item["TTV"] = resp["MarketDepthData"]["TTV"];
              }
            });
          }
        },
        (err) => {
          if (err.error == "Unauthorized") {
            this.service.unauthorized();
            this.appcomponent._marketId = this.marketId;
            this.appcomponent.mwClose();
          }
        }
      );
    } else {
      let close = "CLOSEMBP|" + this.service.getInternalId() + "|" + this.service.getSessionToken() + "|" + internalToken + "^" + exchID + "^" + marketID;
      console.log(close);
      setTimeout(() => {
        this.service.mWatch.next(close);
      }, 500)
    }
  }

  //Footer
  footerMWGroup(event) {
    this.appcomponent.mwClose()
    // var conObj = "REMOVE|" + this.service.getInternalId() + "|" + this.service.getSessionToken() + "|" + this.marketId;
    // console.log(conObj);
    // this.service.mWatch.next(conObj);
    switch (event.index) {
      case 0:
        var mwuserid = this.mw_one.split("_")[0];
        var mwusername = this.mw_one.split("_")[1];
        if (mwuserid) {
          if (mwuserid == this.userID) {
            this.createMarketWatch(this.mw_one);
            localStorage.setItem("mw_one", JSON.stringify(this.mw_one))
          } else {
            this.createDefaultMW(this.mw_one)
            localStorage.setItem("mw_one", JSON.stringify(this.mw_one))
          }
        } else {
          localStorage.setItem("mw_one", JSON.stringify(this.mw_one))
          this.createDefaultMW(this.mw_one)
        }
        // localStorage.setItem("currMwIndex", JSON.stringify(0));
        // for (let idx of this.mwDetails) {
        //   if (idx['MarketWatchName'] === this.mw_one) {
        //     this.getMarketWatch(idx['MarketWatchID'])
        //   } else {
        //     this.createMarketWatch(this.mw_one);
        //   }
        // }
        break;
      case 1:
        var mwuserid = this.mw_two.split("_")[0];
        var mwusername = this.mw_two.split("_")[1];
        if (mwuserid) {
          if (mwuserid == this.userID) {
            localStorage.setItem("mw_two", JSON.stringify(this.mw_two))
            this.createMarketWatch(this.mw_two)
          } else {
            localStorage.setItem("mw_two", JSON.stringify(this.mw_two))
            this.createDefaultMW(this.mw_two)
          }
        } else {
          localStorage.setItem("mw_two", JSON.stringify(this.mw_two))
          this.createDefaultMW(this.mw_two)
        }
        break;
      case 2:
        var mwuserid = this.mw_three.split("_")[0];
        var mwusername = this.mw_three.split("_")[1];
        if (mwuserid) {
          if (mwuserid == this.userID) {
            localStorage.setItem("mw_three", JSON.stringify(this.mw_three))
            this.createMarketWatch(this.mw_three)
          } else {
            localStorage.setItem("mw_three", JSON.stringify(this.mw_three))
            this.createDefaultMW(this.mw_three)
          }
        } else {
          localStorage.setItem("mw_three", JSON.stringify(this.mw_three))
          this.createDefaultMW(this.mw_three)
        }
        break;
      case 3:
        var mwuserid = this.mw_four.split("_")[0];
        var mwusername = this.mw_four.split("_")[1];
        if (mwuserid) {
          if (mwuserid == this.userID) {
            localStorage.setItem("mw_four", JSON.stringify(this.mw_four))
            this.createMarketWatch(this.mw_four)
          } else {
            localStorage.setItem("mw_four", JSON.stringify(this.mw_four))
            this.createDefaultMW(this.mw_four)
          }
        } else {
          localStorage.setItem("mw_four", JSON.stringify(this.mw_four))
          this.createDefaultMW(this.mw_four)
        }
        break;
      case 4:
        var mwuserid = this.mw_five.split("_")[0];
        var mwusername = this.mw_five.split("_")[1];
        if (mwuserid) {
          if (mwuserid == this.userID) {
            localStorage.setItem("mw_five", JSON.stringify(this.mw_five))
            this.createMarketWatch(this.mw_five)
          } else {
            localStorage.setItem("mw_five", JSON.stringify(this.mw_five))
            this.createDefaultMW(this.mw_five)
          }
        } else {
          localStorage.setItem("mw_five", JSON.stringify(this.mw_five))
          this.createDefaultMW(this.mw_five)
        }
        break;
      default:
        break;
    }
  }

  /**
   * Method to Create Market Watch
   * @param value
   * @author Kalai
   * @on 03/03/2020
   */
  createMarketWatch(value) {
    this.mktlistload = true;
    this.selectedItem = value;
    let jsonObj: any = {
      internalLoginId: this.userDetails["UserDetails"]["UserInternalID"],
      marketWatchName: this.selectedItem,
      userId: this.userID,
    };
    this.service.createMW(jsonObj).subscribe(
      (resp) => {
        this.mktlistload = false;
        if (resp["StatusOfRequest_a"]["status"] == 0 && resp["StatusOfRequest_b"]["status"] == 0) {
          this.marketId = resp['MarketWatchColumnData']['MWID'];
          // If you success response to put 
          var responObj = {
            Exchange: { Id: "0", Name: "All" },
            Field4: "0.0",
            MarketId: { Id: "0", Name: "ALL" },
            MarketType: resp['MarketWatchColumnData']['MWType'],
            MarketWatchID: resp['MarketWatchColumnData']['MWID'],
            MarketWatchName: resp['MarketWatchColumnData']['MWName'],
            MarketWatchSize: "293",
          }
          var userDetails = JSON.parse(localStorage.getItem("userdetails"));
          userDetails['MarketWatchDetails'].push(responObj);
          localStorage.setItem("userdetails", JSON.stringify(userDetails));
          this.createDefaultMW(this.marketId);

          localStorage.setItem("currentGroup", JSON.stringify(this.selectedItem));
        }
        // else {
        //   // this.createDefaultMW();
        //   this.getMarketWatch(value);
        //   console.log(resp["StatusOfRequest_a"]["Message"]);
        // }
      },
      (err) => {
        if (err.error == "Unauthorized") {
          this.service.unauthorized();
          this.appcomponent._marketId = this.marketId;
          this.appcomponent.mwClose();
        }
      }
    );
  }


  /**
   * Method to find the Search term
   * @param value
   * @author Kalai
   * @on 05-03-2020
   */
  createDefaultMW(value) {
    this.marketId = value;
    let JsonData: any = {
      marketWatchId: this.marketId,
      userId: this.userID,
    };
    this.service.defaulatMarketWatch(JsonData).subscribe(
      (resp) => {
        if (resp['Status'] == '0') {
          var userDetails = JSON.parse(localStorage.getItem("userdetails"));
          userDetails['DefaultMarketWatch'] = value;
          localStorage.setItem("userdetails", JSON.stringify(userDetails))
          this.getMarketWatch(value);
        }
      },
      (err) => {
        if (err.error == "Unauthorized") {
          this.service.unauthorized();
          this.appcomponent._marketId = this.marketId;
          this.appcomponent.mwClose();
        }
      }
    );
  }

  /**
   * Method to find the Search term
   * @param value
   * @author Kalai
   * @on 03-03-2020
   */
  getsearchItems(value) {
    var searchvalue: any = value;
    if (searchvalue.length > 2) {
      var jsonvalue = {
        symbol: searchvalue,
        exchange: "all",
      };
      this.service.searchSymbol(jsonvalue).subscribe(
        (response) => {
          if (response["stat"] == "success") {
            this.searchList = response["result"];
            localStorage.setItem("symbolList", JSON.stringify(this.searchList));
            this.showList = true;
            for (let idx of this.searchList) {
              var indxid = this.searchList.indexOf(idx);
              this.searchList[indxid]["checked"] = false;
            }
            this.searchList.map(idex => {
              this.scripsmkt.map(idex2 => {
                if (idex2['Exchange']['Name'] == "BSE") {
                  if (idex2['BSEtoken'] == idex['token']) {
                    idex['checked'] = true;
                  }
                } else if (idex2['Exchange']['Name'] == "NSE")
                  if (idex2['NSEtoken'] == idex['token']) {
                    idex['checked'] = true;
                  }
              });
            });
          }
        },
        (err) => {
          if (err.error == "Unauthorized") {
            this.service.unauthorized();
            this.appcomponent._marketId = this.marketId;
            this.appcomponent.mwClose();
          }
        }
      );
    }
  }

  /**
   * Method to logout
   * @author Kalai
   * @on 02-03-2020
   */
  showLogoutAlert() {
    localStorage.clear();
    this.routeTo.navigateByUrl('entrylevel/login');
    this.service.logoutUser();
    // const dialogRef = this.dialog.open(LogoutpopupComponent, {
    //   width: "400px",
    // });
    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result) {
    //     localStorage.clear();
    //     this.routeTo.navigateByUrl("/usercontrol/login");
    //   }
    // });
  }

  /**
   * Method to add Index
   * @author Kalai
   * @on 05-03-2020
   */
  getIndex(value1, value2) {
    var data: any = [];
    var index_pos1: any;
    var index_pos2: any;
    for (let name in this.indexNames) {
      if (value1 == this.indexNames[name]) {
        index_pos1 = name;
        this.indexName1 = this.indexNames[name];
        this.leftindexchart = this.indexName1;
      } else if (value2 == this.indexNames[name]) {
        index_pos2 = name;
        this.indexName2 = this.indexNames[name];
        this.rightindexchart = this.indexName2;
      }
    }
    for (let segment in this.indexSegment) {
      if (index_pos1 == segment) {
        this.segment1 = this.indexSegment[segment];
      } else if (index_pos2 == segment) {
        this.segment2 = this.indexSegment[segment];
      }
    }
    for (let source in this.indexSource) {
      if (index_pos1 == source) {
        this.source1 = this.indexSource[source];
      } else if (index_pos2 == source) {
        this.source2 = this.indexSource[source];
      }
    }
    data.push(
      this.source1 + "^" + this.segment1 + "^" + this.indexName1,
      this.source2 + "^" + this.segment2 + "^" + this.indexName2
    );
    let JsonObj: any = {
      userId: this.userID,
      indexes: data,
    };
    this.service.addIndexToList(JsonObj).subscribe(
      (response) => {
        var result: any = Object.keys(response);
        if (response["StatusRequest"]["Status"] == 0) {
          if (result.length > 0) {
            if (result[1] == "IndexDetails_2") {
              this.idx_change1 =
                response["IndexDetails_1"]["CurrentindicesChangeValue_1"];
              this.idx_value1 =
                response["IndexDetails_1"]["CurrentindicesValue_1"];
            }
            if (result[2] == "IndexDetails_1") {
              this.idx_change2 =
                response["IndexDetails_2"]["CurrentindicesChangeValue_2"];
              this.idx_value2 =
                response["IndexDetails_2"]["CurrentindicesValue_2"];
            }
          }
        } else {
          this.service.toastr.error(response["StatusRequest"]);
        }
      },
      (err) => {
        if (err.error == "Unauthorized") {
          this.service.unauthorized();
          this.appcomponent._marketId = this.marketId;
          this.appcomponent.mwClose();
        }
      }
    );
  }

  /**
   * Method to show the Index List
   * @author Kalai
   * @on 06/03/202
   */
  showIndexList(position) {
    const dialogRef = this.dialog.open(IndexlistComponent, {
      width: "380px",
      height: "80%",
      data: {
        idxList: this.indexNames,
        position: position,
        nifname: this.indexName1,
        senxname: this.indexName2,
      },
      position: { left: "0", top: "50px" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getIndexRecords();
      }
    });
  }

  /**
   * Method to placescripsOrder/DelOrder from MKTList
   * @param scrOrder
   * @param clicTyp
   * @author Kalai
   * @on 10-03-2020
   */
  scripsPOrd(scrOrder, clicTyp, list) {
    if (clicTyp == "BUY") {
      scrOrder["Trantype"] = "B";
    } else {
      scrOrder["Trantype"] = "S";
    }
    this.ordertiktoken = scrOrder["Token"];
    switch (clicTyp) {
      case "BUY":
        this.orderWindow(clicTyp, scrOrder, "mktwatch");
        break;
      case "SELL":
        this.orderWindow(clicTyp, scrOrder, "mktwatch");
        break;
      case "del":
        this.setSelectVal(scrOrder, clicTyp, list);
        break;
      case "modify":
        this.orderWindow(clicTyp, scrOrder, "orderModify");
    }
  }

  /**
   * Method to open the order Window Popup
   * @author Kalai
   * @on 13-03-2020
   */
  orderWindow(type, scrOrder, page) {
    const dialogRef = this.dialog.open(OrderwindowComponent, {
      width: "600px",
      autoFocus: false,
      height: "300px",
      data: { clicTyp: type, scrOrder: scrOrder, page: page, marketID: this.marketId },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  /**
   * Method to get index details
   * @author Kalai
   * @since 30-03-2020
   */
  getIndexRecords() {
    let JsonData: any = {
      userId: this.userID,
    };
    this.service.getIndexDetails(JsonData).subscribe((resp) => {
      var result: any = resp;
      if (resp['result'].length < 1) {
        this.getIndex("Nifty 50", "Nifty Bank");
      } else if (resp["stat"] == "success") {
        this.indexName1 = "Nifty 50";
        this.indexName2 = "Nifty Bank";
        if (resp["result"].length > 0) {
          for (let idx of result["result"]) {
            if (idx["position"] == 1) {
              this.indexName1 = idx["indexName"];
              this.leftindexchart = this.indexName1;
              this.idx_value1 =
                idx["indexValue"] == null ? "0.00" : idx["indexValue"];
              this.idx_change1 =
                idx["indexChange"] == null ? "0.00" : idx["indexChange"];
            }
            if (idx["position"] == 2) {
              this.indexName2 = idx["indexName"];
              this.rightindexchart = this.indexName2;
              this.idx_value2 =
                idx["indexValue"] == null ? "0.00" : idx["indexValue"];
              this.idx_change2 =
                idx["indexChange"] == null ? "0.00" : idx["indexChange"];
            }
          }
          this.getIndex(this.indexName1, this.indexName2);
        } else {
          this.getIndex("Nifty 50", "Nifty Bank");
        }
      }
    });
  }

  /**
   * Method to load char from marketwatch
   * @param val 
   */
  loadChart(val) {
    console.log(val)
    localStorage.setItem('currentGraph', JSON.stringify(val));
    let exchange = val['Exchange']['Name'];
    if (exchange.toLowerCase() === 'mcx'
      || exchange.toLowerCase() === 'cds'
      || exchange.toLowerCase() === 'nfo') {
      this._symbol = val['Symbol'] + "::" + val['Exchange']['Name'];
    } else {
      this._symbol = val['Symbol'] + "::" + val['Exchange']['Name'];
    }
    this.renderChart();
  }

  /**
   * @method Method to set trading view chart
   * @params na
   * @return na
   * @author Kalai
   * @on 11-06-2020
   */
  indexloadChart(indexchart) {
    console.log(indexchart)
    var count = Object.keys(indexchart).length
    if (count > 4) {
      this._symbol = indexchart + "::" + 'NSE' + "::" + "Index";
      localStorage.setItem('indexGraph', this._symbol);
      if (this.routeTo.url == '/usercontrol/dashboard/home') {
        this.renderChart();
      } else {
        this.routeTo.navigateByUrl("/usercontrol/dashboard/home");
      }
    } else {
      this._symbol = indexchart['IndexName'] + "::" + 'NSE' + "::" + "Index";
      localStorage.setItem('indexGraph', this._symbol);
      if (this.routeTo.url == '/usercontrol/dashboard/home') {
        this.renderChart();
      } else {
        this.routeTo.navigateByUrl("/usercontrol/dashboard/home");
      }
    }
  }


  /**
   * Method to get index symbolId from service
   * @param data
   * @since Selvakumar K
   */
  getActiveIndexId(data) {
    var jsonData = this.service.baseURL + "chart/symbols" + "?symbol=" + data['symbol'] + "::" + data['exchDisp'];
    this.http.get(jsonData).subscribe((res) => {
      // console.log(res)
      var result: any = res;
      data['symbolid'] = result['ticker'];
      localStorage.setItem("_customSymbol", JSON.stringify(data));
      customChangeSymbol();
    }, (err) => {
      console.log(err.error)
    });
  }


  /**
    * @method Method to set trading view chart
    * @params na
    * @return na
    * @author Kalai
    * @on 11-06-2020
    */
  renderChart() {
    if (localStorage.getItem("indexGraph") != null || localStorage.getItem("indexGraph") != undefined) {
      this._symbol = localStorage.getItem("indexGraph");
      localStorage.removeItem("indexGraph")
    }
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this._symbol,
      datafeed: new Datafeed(this._datafeedUrl, 10000, this.service, this.websocket),
      interval: this._interval,
      container_id: this._containerId,
      library_path: this._libraryPath,
      locale: 'en',
      fullscreen: this._fullscreen,
      autosize: this._autosize,
      disabled_features: ['timezone_menu', 'left_toolbar', 'timeframes_toolbar', 'header_compare', 'header_symbol_search'],
      charts_storage_url: this._chartsStorageUrl
    };
    console.log(widgetOptions)
    const tvWidget = new widget(widgetOptions);
    this._tvWidget = tvWidget;
    tvWidget.onChartReady(() => {
    });
  }


  /**
   * Method to view the scrip information
   * @author Kalai
   * @since 18-05-2020
   */
  showSecurityInfo(scrip, index) {
    const dialogRef = this.dialog.open(ScripinfoComponent, {
      width: '650px',
      height: '420px',
      data: scrip
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.routeTo.navigateByUrl('login');
      }
    });
  }

  /**
  * Method to get the Tick size
  * @author Kalaivani
  * @since 22-05-2020
  */
  getScripTickSize(token, symbol) {
    let jsonObj: any;
    if (this.mw_one == this.marketId) {
      // if (localStorage.getItem("MW_1_Tick") != null || localStorage.getItem("MW_1_Tick")) {
      //   this.mw_one_tickPrice = JSON.parse(localStorage.getItem("MW_1_Tick"));
      // } else {
      jsonObj = {
        "tokens": token,
        "symbols": symbol,
      }
      this.service.getTickSize(jsonObj).subscribe(resp => {
        if (resp['stat'] == "success") {
          this.mw_one_tickPrice = resp['result'];
          localStorage.setItem("MW_1_Tick", JSON.stringify(resp['result']));
        }
      })
      // }
    } else if (this.mw_two == this.marketId) {
      // if (localStorage.getItem("MW_2_Tick") != null || localStorage.getItem("MW_2_Tick")) {
      //   this.mw_one_tickPrice = JSON.parse(localStorage.getItem("MW_2_Tick"));
      // } else {
      jsonObj = {
        "tokens": token,
        "symbols": symbol,
      }
      this.service.getTickSize(jsonObj).subscribe(resp => {
        if (resp['stat'] == "success") {
          this.mw_two_tickPrice = resp['result'];
          localStorage.setItem("MW_2_Tick", JSON.stringify(resp['result']));
        }
      })
      // }
    } else if (this.mw_three == this.marketId) {
      // if (localStorage.getItem("MW_3_Tick") != null || localStorage.getItem("MW_3_Tick")) {
      //   this.mw_one_tickPrice = JSON.parse(localStorage.getItem("MW_3_Tick"));
      // } else {
      jsonObj = {
        "tokens": token,
        "symbols": symbol,
      }
      this.service.getTickSize(jsonObj).subscribe(resp => {
        if (resp['stat'] == "success") {
          this.mw_three_tickPrice = resp['result'];
          localStorage.setItem("MW_3_Tick", JSON.stringify(resp['result']));
        }
      })
      // }
    } else if (this.mw_four == this.marketId) {
      // if (localStorage.getItem("MW_4_Tick") != null || localStorage.getItem("MW_4_Tick")) {
      //   this.mw_one_tickPrice = JSON.parse(localStorage.getItem("MW_4_Tick"));
      // } else {
      jsonObj = {
        "tokens": token,
        "symbols": symbol,
      }
      this.service.getTickSize(jsonObj).subscribe(resp => {
        if (resp['stat'] == "success") {
          localStorage.setItem("MW_4_Tick", JSON.stringify(resp['result']));
        }
      })
      // }
    } else if (this.mw_five == this.marketId) {
      // if (localStorage.getItem("MW_5_Tick") != null || localStorage.getItem("MW_5_Tick")) {
      //   this.mw_one_tickPrice = JSON.parse(localStorage.getItem("MW_5_Tick"));
      // } else {
      jsonObj = {
        "tokens": token,
        "symbols": symbol,
      }
      this.service.getTickSize(jsonObj).subscribe(resp => {
        if (resp['stat'] == "success") {
          localStorage.setItem("MW_5_Tick", JSON.stringify(resp['result']));
        }
      })
      // }
    }
  }

  /**
   * Method to open the Notification popup
   * @author Kalai
   * @since 17-06-2020
   */
  notify() {
    const dialogRef = this.dialog.open(NotificationsComponent, {
      width: "600px",
      height: "400px"
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        localStorage.clear();
      }
    });
  }
}
