import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
// import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  userId: any;
  hide = true;
  notOKstat: any;
  password: string = 'password';

  constructor(
    // private spinner: NgxSpinnerService,
    public formBuilder: FormBuilder,
    public toastr: ToastrService,
    private loader: NgxUiLoaderService,
    public httpService: HttpService,
    public routeTo: Router) {
  }


  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.loginForm = this.formBuilder.group({
      'userid': ["", Validators.required],
      'password': ["", Validators.required],
    });
  }

  getError(el) {
    switch (el) {
      case 'user':
        if (this.loginForm.get('userid').hasError('required')) {
          return 'User Id required';
        }
        break;
      case 'pass':
        if (this.loginForm.get('password').hasError('required')) {
          return 'Password required';
        }
        break;
      default:
        return '';
    }
  }

  alertify() {
    this.httpService.alertpop();
  }

  onSubmit() {
    let jsonData = {
      userId: this.loginForm.value.userid,
      password: this.loginForm.value.password
    }
    this.loader.start();
    this.httpService.userLogin(jsonData).subscribe((res: any) => {
      this.loader.stop();
      if (res['UserConfiguration']['StatusOfRequest'] == 0) {
        this.userId = this.loginForm.value.userid;
        localStorage.setItem("userid", JSON.stringify(this.loginForm.value.userid))
        localStorage.setItem("userdetails", JSON.stringify(res));
        this.generateUserOTP();
      } else {
        this.notOKstat = res['UserConfiguration']['ErrorMessage'];
        this.toastr.error(res['UserConfiguration']['ErrorMessage']);
      }
    }, (err) => {
      this.loader.stop();
    });
  }

  /**
   * Method to generate otp
   */
  generateUserOTP() {
    let jsonData = {
      userId: this.userId,
    }
    this.loader.start();
    this.httpService.generateMobileOTP(jsonData).subscribe((res: any) => {
      this.loader.stop();
      if (res['StatusOfRequest']['status'] == 0) {
        this.toastr.success(res['StatusOfRequest']['Message']);
        this.routeTo.navigateByUrl('entrylevel/otpauthen')
      }
    }, (err) => {
      this.loader.stop();
    });
  }

  showHide() {
    if (this.password == "password") {
      this.password = "text";
    } else {
      this.password = "password";
    }
  }

  /**
    * Method to navigate Forget Password
    * @author Kalai
    * @since 15-05-2020
    */
  goToForgetPassword() {
    this.routeTo.navigateByUrl('entrylevel/forgotpassword')
  }
}