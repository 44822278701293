import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {
  userId: any;
  public forgotPass: FormGroup;
  forgotemsg: string = "";


  constructor(
    public formBuilder: FormBuilder,
    private spinnerService: NgxUiLoaderService,
    public toastr: ToastrService,
    public httpService: HttpService,
    public routeTo: Router,
    public formbuilder: FormBuilder,
  ) { }

  ngOnInit() {
    if (localStorage.getItem("userid") != null || localStorage.getItem("userid") != undefined) {
      this.userId = JSON.parse(localStorage.getItem("userid"));
    }
    this.forgotPass = this.formbuilder.group({
      userId: ['', [Validators.required, Validators.minLength(4), Validators.pattern('^[a-zA-Z0-9]+$')]],
    });
    // this.forgotPassCall();
  }

  /**
   * Method to call the Forgot password Service
   * @author Kalai
   * @since 15-05-2020
   */
  forgotPassCall() {
    let JsonObj = {
      "userId": this.forgotPass.value.userId,
    }
    this.httpService.forgotPwdLink(JsonObj).subscribe(resp => {
      if (resp['status'] == "1") {
        this.forgotemsg = resp['Message'];
      } else {
        this.forgotemsg = resp['Message'];
      }
    })
  }

  /**
   * Method to go back Login
   * @author Kalai
   * @since 22-05-2020
   */
  backToLogin() {
    this.routeTo.navigateByUrl('entrylevel/login')
  }
}
