import { Component } from '@angular/core';
import { HttpService } from './services/http.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { debug } from 'util';
import { SharedataService } from './services/sharedata.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'PhillipCapitalWeb';
  userDetails: any;
  userID: any;
  _marketId: any;
  _isMWCall: any;
  internalID: any;
  sessionID: any;
  _isDPCall: any;
  _isConClose: any;

  constructor(public http: HttpService, public shareData: SharedataService) {
    if (localStorage.getItem("userdetails") != null || localStorage.getItem("userdetails") != undefined) {
      var userDetails = JSON.parse(localStorage.getItem("userdetails"));
      this.userDetails = userDetails;
      this.userID = this.userDetails["UserDetails"]["UserID"];
      this.internalID = this.userDetails["UserDetails"]['UserInternalID'];
    }
    if (localStorage.getItem("sessionId") != null || localStorage.getItem("sessionId") != undefined) {
      this.sessionID = JSON.parse(localStorage.getItem("sessionId"))
    }
    // websocket
    if (localStorage.getItem("loginState") != null || localStorage.getItem("loginState") != undefined) {
      var logState: boolean = JSON.parse(localStorage.getItem("loginState"));
      if (logState) {
        this.http.mWatch.subscribe(msg => {
          // console.log("cn Connected", msg.split("|"));
          if (msg.split("|")[0] == "75") {//order gateway
            var orderStatus: any = msg.split("|");
            orderStatus.push({ msg })
            if (msg.split("|")[2] != "") {
              var message: any; var msgNum: any;
              msgNum = msg.split("|")[1];
              if (msgNum == "2231") {
                message = "Order Entry Rejection";
                // this.http.toastr.error(message);
              } else if (msgNum == "2073") {
                message = "Order Entry Confirmation ";
                // this.http.toastr.success(message);
              } else if (msgNum == "2075") {
                message = "Order Cancellation  Cofirmation";
                // this.http.toastr.error(message);
              } else if (msgNum == "2012") {
                message = "Order Price Confirmation";
                // this.http.toastr.success(message);
              } else if (msgNum == " 1999") {
                message = "Order Mirage";
                // this.http.toastr.success(message);
              }
            }
            var orderMsg: any;
            var symbol = msg.split("|")[55];
            var orderNumber: any = msg.split("|")[6];
            if (msg.split("|")[31] != "" && msg.split("|")[55] != "") {
              this.http.toastr.success(msg.split("|")[31] + "  for the " + symbol + " of " + orderNumber);
              orderMsg = msg.split("|")[31] + "  for the " + symbol + " of " + orderNumber;
              console.log(orderMsg)
            } else {
              if (message != undefined) {
                this.http.toastr.success(message + " for the " + symbol + " of " + orderNumber);
                orderMsg = message + " for the " + symbol + " of" + orderNumber;
                console.log(orderMsg)
              }
            }
            this.shareData.orderGatewayTcp(orderMsg);
          } else if (msg.split("|")[0] == "57") {//order confirmation
            var confirmedOdr: any = msg.split("|");
            var confMesg: any = []; var confData: any = [];
            confData = msg.split("|");
            confMesg = confData[3]
            // this.shareData.orderGatewayTcp(confMesg);
            // this.http.toastr.success(confMesg);
          } else if (msg.split("|")[0] == "61") {
            this.shareData.socketData(msg.split("|"));
          } else if (msg.split("|")[0] == "69") {
            this.shareData.indexTickData(msg.split("|"));
          } else if (msg.split("|")[0] == "60") {
            this.shareData.depthData(msg.split("|"));
          } else if (msg.split("|")[0] == "82") {
            this.shareData.position(msg.split("|"));
          } else if (msg.split("|")[0] == "76") {
            var tradeMsg: any;
            if (msg.split("|")[1] == "2222") {
              tradeMsg = "Trade Confirmation";
            } else if (msg.split("|")[1] == "1649") {
              tradeMsg = "Spread Trade Confirmation";
            } else if (msg.split("|")[1] == "2212") {
              tradeMsg = "On Stop Notification ";
            }
            this.shareData.position(msg.split("|"));
          } else if (msg.split("|")[0] == "71") {
            localStorage.setItem("marketStatus", JSON.stringify(msg.split("|")));
            this.shareData.marketStatusShare(msg.split("|"));
          } else if (msg.split("|")[1] == "99") {
            if (msg.split("|")[3] == "The user has logged in from elsewhere") {
              this.http.unauthorized();
            } else if (msg.split("|")[3] == "The user has logged in from elsewhere") {
              this.http.unauthorized();
            }
          }
        });
      }
    }
  }

  callConnectionSocket() {
    var conObj = "CONNECT|" + this.http.getInternalId() + "|" + this.http.getSessionToken() + "|" + "TCP||||Y";
    console.log(conObj)
    this.http.mWatch.next(conObj);
    // this.subscribeCon()
  }

  subscribeCon() {
    // 
  }

  subscribeMessage() {
    // setTimeout(() => {=
    if (this._isMWCall) {
      var mwTouch = "OPEN|" + this.http.getInternalId() + "|" + this.http.getSessionToken() + "|" + this._marketId + "|" + this._isMWCall;
      console.log(mwTouch)
      this.http.mWatch.next(mwTouch);
    }
    // else if (this._isDPCall) {
    //   console.log(this._isDPCall)
    //   this.http.mWatch.next(this._isDPCall);
    // }
    // }, 1000);

    // this.subscribeCon();
  }

  // mwSubscribe() {
  //   this.http.mWatch.subscribe(msg => {
  //     console.log(msg);

  //   });
  // }

  // if (this._isMWCall) {
  //   var mwTouch = "OPEN|" + this.http.getInternalId() + "|" + this.http.getSessionToken() + "|" + this._marketId + "|" + this._isMWCall;
  //   console.log(mwTouch)
  //   this.http.mWatch.next(mwTouch);
  //   this.http.mWatch.subscribe((msg: any) => {
  //     console.log("Touch", msg)
  //     this.shareData.socketData(msg)
  //   });
  // }
  // else if (this._isDPCall) {
  //     console.log(this._isDPCall)
  //     this.http.depthWatch.next(this._isDPCall);
  //     this.http.depthWatch.subscribe((meg: any) => {
  //       console.log("Depth", meg)
  //     })
  //   }
  // this.http.mWatch.subscribe(msg => {
  //   console.log("cn Connected", msg);
  //   // this._isConnect = true;
  //   // this.sendSubscribeMessage();
  //   // }
  // });
  // }


  // sendSubscribeMessage() {
  //   if (this._isMWCall) {
  //     var mwTouch = "OPEN|" + this.http.getInternalId() + "|" + this.http.getSessionToken() + "|" + this._marketId + "|" + this._isMWCall;
  //     console.log(mwTouch)
  //     this.http.mWatch.next(mwTouch);
  //     this.http.mWatch.subscribe((msg: any) => {
  //       console.log("Touch", msg)
  //       this.shareData.socketData(msg)
  //     });
  //   }
  //   if (this._isDPCall) {
  //     console.log(this._isDPCall)
  //     this.http.depthWatch.next(this._isDPCall);
  //     this.http.depthWatch.subscribe((meg: any) => {
  //       console.log("Depth", meg)
  //     })
  //   }
  // }


  mwClose() {
    var conObj = "REMOVE|" + this.http.getInternalId() + "|" + this.http.getSessionToken() + "|" + this._marketId;
    console.log(conObj);
    this.http.mWatch.next(conObj);
  }
}