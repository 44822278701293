import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { from } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedataService } from 'src/app/services/sharedata.service';
import { PositionconversionComponent } from 'src/app/positionconversion/positionconversion.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PositionInfoComponent } from 'src/app/position-info/position-info.component';
import { AppComponent } from 'src/app/app.component';
import { OrderwindowComponent } from 'src/app/orderwindow/orderwindow.component';
import { SquareoffComponent } from 'src/app/squareoff/squareoff.component';
import { Router } from '@angular/router';
import { Sort } from '@angular/material';

export interface position {
  Symbol: any;
  Qty: Number;
  AvgBuyPrice: Number;
  AvgSellPrice: Number;
  LTP: Number;
  M2M: Number;
}

@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.scss']
})
export class PositionComponent implements OnInit {
  segmentlist: any = ['All', 'MCX', 'NFO', 'CDS'];
  showDatafound: boolean = true;
  emptytablemsg: boolean = false;
  ordertiktoken: any;
  setSelectVal: any;
  exitarray: any;
  userDetails: any;
  userID: any;
  sengmenttypes: number = 0;
  totalvalue: any = 0;
  positioncheck: any = false;
  position: any = [];
  showOverAllExit: boolean = true;
  latestInfo: any[];
  badgeValue: number;
  sortedData: position[];

  constructor(public httpService: HttpService,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
    public appcomponent: AppComponent,
    public sharedata: SharedataService,
    public routeTo: Router,
    // public odrWindow: OrderwindowComponent,
    public dialog: MatDialog,

  ) {
    if (localStorage.getItem("userdetails") != null || localStorage.getItem("userdetails") != undefined) {
      var userDetails = JSON.parse(localStorage.getItem("userdetails"));
      this.userDetails = userDetails;

      this.userID = this.userDetails['UserDetails']['UserID'];
    }
    this.positionlist();

    // Order Place
    this.sharedata.executeOrderShare.subscribe((res: Boolean) => {
      if (res) {
        this.positionlist();
      }
    });
    this.sharedata.positionshare.subscribe((msg: any) => {
      // if (msg) {
      if (msg[0] == "82") {
        if (msg !== "") {
          if (this.routeTo.url == "/usercontrol/dashboard/position") {
            this.badgeValue = 0;
            this.latestInfo = [];
            localStorage.setItem("notifyMsg", JSON.stringify(this.latestInfo))
          } else {
            if (localStorage.getItem("notifyMsg") != null || localStorage.getItem("notifyMsg") != undefined) {
              this.latestInfo = JSON.parse(localStorage.getItem("notifyMsg"));
              this.latestInfo.push(msg);
            } else {
              this.latestInfo.push(msg);
            }
            localStorage.setItem("notifyMsg", JSON.stringify(this.latestInfo));
          }

          if (localStorage.getItem("notifyMsg") != null || localStorage.getItem("notifyMsg") != undefined) {
            this.latestInfo = JSON.parse(localStorage.getItem("notifyMsg"));
            this.badgeValue = this.latestInfo.length;
          }
        }

        this.positionlist();
      } else if (msg[0] == "92") {
        this.positionlist();
      } else if (msg[0] == "76") {
        this.positionlist();
      }
    });
    // Position LTP
    // TCP response
    this.sharedata.webSocketData.subscribe((msg: any) => {
      for (let idx in this.position) {
        if (msg[4] != "" && msg[8] != "") {
          if (msg[4] == this.position[idx]['Internaltoken']) {
            this.position[idx]['LTP'] = msg[8];
          }
        } else if (msg[4] != "" && msg[8] != "") {
          if (msg[4] == this.position[idx]['Internaltoken']) {
            this.position[idx]['LTP'] = msg[8];
          }
        }
      }
    });
  }

  ngOnInit() {

  }


  /**
    * Method to get Position list
    * @author Kalai
    * @on 12-03-2020
    */
  positionlist() {
    this.loader.start();
    let JsonData: any = {
      userId: this.userID,
      "exchangeId": "0",
      "marketId": "0",
      "segmentId": "0",
      "instrumentType": "",
      "optionType": "",
      "group": "NA",
      "aggregate": "0",
      "strikePrice": ""
    }
    this.httpService.positionlists(JsonData).subscribe(resp => {
      this.loader.stop();
      if (resp['StatusOfRequest']['status'] == "0") {
        this.position = resp['PositionData'];
        if (this.position.length > 1) {
          this.showDatafound = false;
          this.position = resp['PositionData'];
          this.position.pop();
          var scripToken: any = [];
          var token: any = [];
          var symbol: any = [];
          this.totalvalue = 0;
          for (let index in this.position) {
            this.position[index]['Qty'] = Number(this.position[index]['C/FQty']);
            this.position[index]['C/FAvgPr'] = Number(this.position[index]['C/FAvgPr']);
            this.position[index]['M2M'] = Number(this.position[index]['M2M']);
            this.position[index]['checked'] = false;
            this.totalvalue += Number(this.position[index]['M2M']);
            scripToken.push(this.position[index]['Internaltoken'] + "^" + this.position[index]['ExchangeId'] + "^" + this.position[index]['MarketId']);
          }
          console.log(this.totalvalue)
          // this.appcomponent._marketId = this.marketId;
          this.appcomponent._isMWCall = scripToken.join("~");
          this.appcomponent.subscribeMessage();
          this.totalvalue = Number(this.totalvalue.toFixed(2));
          this.sortedData = this.position;
          this.sortedData = this.position.slice();
          if (this.position.length > 1) {
            this.showDatafound = false;
          } else {
            // this.showDatafound = true;
          }
        }
      } else {
        this.showDatafound = true;
        // this.toastr.error("Error");
      }

    }, (err) => {
      this.httpService.toastr.error(err)
      this.loader.stop();
    });
  }

  /**
   * Method for the check box event
   * @author Kalai
   * @since 11-06-2020
   * @param event 
   * @param index 
   */
  positionSelect(event, index) {
    if (index == '' && event.checked) {
      this.exitarray = [];
      this.showOverAllExit = false;
      for (let object of this.position) {
        var indexid: number = this.position.indexOf(object);
        if (this.position[indexid]['Qty'] == 0) {
          this.position[indexid]['checked'] = false;
        } else {
          this.showOverAllExit = true;
          this.position[indexid]['checked'] = true;
          this.exitarray.push(object);
        }
      }
    } else if (index != "" && event.checked) {
      this.exitarray.push(index);
      if (this.exitarray.length > 0) {
        this.showOverAllExit = true;
      }
    } else if (index != "" && !event.checked) {
      for (let idex of this.exitarray) {
        var idxid: number = this.exitarray.indexOf(idex);
        if (this.exitarray[idxid]['token'] == index['token']) {
          this.exitarray.splice(idxid, 1);
          this.positioncheck = false;
        }
      }
      if (this.exitarray.length > 0) {
        this.showOverAllExit = true;
      } else if (this.exitarray.length == 0) {
        this.showOverAllExit = false;
        this.positioncheck = false;
      }
    } else if (index == "" && !event.checked) {
      this.showOverAllExit = false;
      for (let obj2 of this.position) {
        var indexid2: number = this.position.indexOf(obj2);
        if (this.position[indexid2]['Qty'] == 0) {
          this.position[indexid2]['checked'] = false;
        } else {
          this.position[indexid2]['checked'] = false;
        }
      }
      this.exitarray = [];
    }
  }


  alertify() {
    this.httpService.alertpop();
  }

  /**
   * Method to select the Checkbox
   * @author Kalai  
   * @since 27-04-2020
   * @params selected, event
    */
  selectCheckBox(event, selected) {

  }

  /**
   * Method to convert Position
   * @author Kalai
   * @since 22-05-202
   */
  convertPosition(param) {
    const dialogRef = this.dialog.open(PositionconversionComponent, {
      width: '400px',
      data: { Data: param },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.positionlist();
      }
    });
  }

  /**
   * Method to open position Information
   * @author Kalai
   * @since 08-06-2020
   */
  openPositionInfo(object) {
    const dialogRef = this.dialog.open(PositionInfoComponent, {
      width: '600px',
      height: '430px',
      data: { data: object },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.positionlist();
    });
  }

  /**
   * Method to select the position event
   * @author  Kalai
   * @since 08-06-2020
   */
  getPositionSegments(event) {


  }

  /**
   * Method to add the position scrip
   * @author Kalai
   * @since 10-06-2020
   * @param object 
   */
  goToPositionAdd(object) {
    if (object.BQty > 0) {
      this.orderwindow('BUY', object, 'position');
    } else {
      this.orderwindow('SELL', object, 'position');
    }
  }


  /**
   * Method to Exit the single position
   * @author Kalai
   * @since 10-06-2020
   * @param object 
   */
  goToPositionExit(object: any) {
    if (object.BQty > 0) {
      this.orderwindow('SELL', object, 'position');
    } else {
      this.orderwindow('BUY', object, 'position');
    }
  }


  /**
   * Metho to open order window popup
   */
  orderwindow(bsType, object, page) {
    const dialogRef = this.dialog.open(OrderwindowComponent, {
      width: "600px",
      autoFocus: false,
      height: "300px",
      data: { clicTyp: bsType, scrOrder: object, page: page },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.positionlist();
    });
  }

  /**
   * Method to open popup for Square off position
   * @author Kalai
   * @since 11-06-2020
  
   */
  openAllPositionExitConfirmation() {
    const dialogRef = this.dialog.open(SquareoffComponent, {
      width: '600px',
      data: { data: this.exitarray },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.positionlist();
      }
    });
    // this.showAllPositionExitConfir(this.exitarray);
  }

  /**
   * Method to Sort Posotion table
   * @author Kalai
   * @since 19-06-2020
   */
  sortData(sort: Sort) {
    const data = this.position.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'EntryDateTime': return this.compare(a.EntryDateTime, b.EntryDateTime, isAsc);
        case 'OrderType': return this.compare(a.OrderType, b.OrderType, isAsc);
        // case 'Buy/Sell': return this.compare(a.Buy/Sell, b.Buy/Sell, isAsc);
        case 'Symbol': return this.compare(a.Symbol, b.Symbol, isAsc);
        case 'Qty': return this.compare(a.Qty, b.Qty, isAsc);
        case 'Price': return this.compare(a.Price, b.Price, isAsc);
        case 'Status': return this.compare(a.Status, b.Status, isAsc);

        default: return 0;

      }
    });
  }

  compare(a: number | any, b: number | any, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}



