import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MobileviewComponent } from './mobileview/mobileview.component';
import { MobileviewchartComponent } from './mobileviewchart/mobileviewchart.component';

const routes: Routes = [
  // { path: '', redirectTo: 'mobileview', pathMatch: 'full' },
  {
    path: 'mobileview', component: MobileviewComponent,
    children: [
      { path: 'mobileview', redirectTo: '', pathMatch: 'full' },
      // { path: 'mobileview', component: MobileviewComponent },
      { path: 'mobileviewchart', component: MobileviewchartComponent },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MobilewindowRoutingModule { }
