import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { JsonPipe } from '@angular/common';


export interface DialogData {
  data: any;
}

@Component({
  selector: 'app-indexlist',
  templateUrl: './indexlist.component.html',
  styleUrls: ['./indexlist.component.scss']
})
export class IndexlistComponent implements OnInit {
  indexName1: any;
  indexName2: any;
  positions: any;
  indexList: any = [];
  userDetails: any = [];
  indexNames: any = [];
  userID: any;
  mwDetails: any;
  indexSource: any;
  indexSegment: any;
  segment1: any;
  source1: any;
  indexName: any = [];

  constructor(
    public dialogRef: MatDialogRef<IndexlistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public service: HttpService,
  ) { }

  ngOnInit() {
    if (localStorage.getItem("userdetails") != null || localStorage.getItem("userdetails") != undefined) {
      this.userDetails = JSON.parse(localStorage.getItem("userdetails"));
      this.userID = this.userDetails['UserDetails']['UserID'];
      this.mwDetails = this.userDetails['MarketWatchDetails'];
      this.indexNames = this.userDetails['IndexNames'];
      this.indexSource = this.userDetails['IndexSources'];
      this.indexSegment = this.userDetails['IndexSegments'];
      this.indexList = this.userDetails['IndexNames'];
    }
    this.indexName1 = this.data['nifname'];
    this.indexName2 = this.data['senxname'];
    this.positions = this.data['position'];

    for (let i = this.indexList.length; i >= 0; i--) {
      if (this.indexList[i] != "S&P BSE SENSEX" && this.indexList[i] != "Nifty 50" && this.indexList[i] != "Nifty Bank") {
        this.indexList.splice(i, 1);
      }
    }
    for (let index of this.indexList) {
      if (this.positions > 0) {
        if (index != this.indexName1 && index != this.indexName2) {
          this.indexName.push(index);
        } else {
        }
      } else {
        if (index != this.indexName2 && index != this.indexName1) {
          this.indexName.push(index);
        }
      }
    }
  }

  /**
   * Method to get the selected Inded
   * @author Kalai
   * @on 09-03-2020
   */

  /**
   * Method to Save the selected Index
   * @author Kalaivani
   * @since 31-03-2020
   */
  // selectedIndex(object){
  //   localStorage.setItem("selectedIndex", JSON.stringify(object));
  // }

  selectedIndex(index) {
    var jsonvalue = {
      userId: this.userID,
      position: this.positions,
      indexName: index
    }
    this.service.insertIndexDetails(jsonvalue).subscribe(resp => {
      var result: any = resp;
      if (result['stat'] == "success") {
        this.dialogRef.close(true);
      } else {
        // Add Toast
      }
    })
  }


}
