import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ElementRef } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MatSidenavModule, MatIconModule, MatButtonModule, MatMenuModule, MatCardModule, MatToolbarModule, MatListModule, MatSidenavContent, MatSidenav, MatSlideToggleModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogModule } from '@angular/material';

import {
  ToastrModule, ToastContainerModule, ToastNoAnimation,
  ToastNoAnimationModule
} from 'ngx-toastr';
import { HttpService } from './services/http.service';
import { MatTableModule } from '@angular/material'
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Add Angular Matetrial components
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { OrderwindowComponent } from './orderwindow/orderwindow.component';
import { ScripinfoComponent } from './scripinfo/scripinfo.component';
import { OrdercancelComponent } from './ordercancel/ordercancel.component';
import { PositionconversionComponent } from './positionconversion/positionconversion.component';
import { OrderInfoComponent } from './order-info/order-info.component';
import { PositionInfoComponent } from './position-info/position-info.component';
import { PositionComponent } from './usercontrol/position/position.component';
import { SquareoffComponent } from './squareoff/squareoff.component';
import { NotificationsComponent } from './notifications/notifications.component';




@NgModule({
  declarations: [
    AppComponent,
    OrderwindowComponent,
    ScripinfoComponent,
    OrdercancelComponent,
    PositionconversionComponent,
    OrderInfoComponent,
    PositionInfoComponent,
    SquareoffComponent,
    NotificationsComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    MatDialogModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSlideToggleModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatTableModule,
    MatCheckboxModule,
    MatExpansionModule,
    ToastrModule.forRoot({
      timeOut: 500,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgxUiLoaderModule,
    FlexLayoutModule,
    ToastNoAnimationModule.forRoot(),
    ToastContainerModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MatRadioModule
  ],
  entryComponents: [
    OrderwindowComponent,
    ScripinfoComponent,
    OrdercancelComponent,
    NotificationsComponent,
    PositionconversionComponent,
    OrderInfoComponent,
    PositionInfoComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [HttpService,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
