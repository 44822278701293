import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FundsComponent } from './funds/funds.component';
import { OrderComponent } from './order/order.component';
import { HoldingComponent } from './holding/holding.component';
import { PositionComponent } from './position/position.component';
import { HomeComponent } from './home/home.component';
import { IndexlistComponent } from './indexlist/indexlist.component';
// import { LogoutpopupComponent } from './logoutpopup/logoutpopup.component';
import { ProfileComponent } from './profile/profile.component';
// import { OrderwindowComponent } from './orderwindow/orderwindow.component';
import { SettingsComponent } from './settings/settings.component';
import { MarketStatusComponent } from './market-status/market-status.component';
import { CustSupportComponent } from './cust-support/cust-support.component';
// import { MobileviewComponent } from './mobileview/mobileview.component';
const routes: Routes = [

  {
    path: 'dashboard', component: DashboardComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'position', component: PositionComponent },
      { path: 'holding', component: HoldingComponent },
      { path: 'order', component: OrderComponent },
      { path: 'funds', component: FundsComponent },
      { path: 'home', component: HomeComponent },
      { path: 'indexlist', component: IndexlistComponent },
      // { path: 'logoutpopup', component: LogoutpopupComponent },
      { path: 'profile', component: ProfileComponent },
      // { path: 'orderwindow', component: OrderwindowComponent },
      { path: 'market-status', component: MarketStatusComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'cust-support', component: CustSupportComponent },

    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsercontrolRoutingModule { }
