import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpService } from '../services/http.service';
import { ToastrService } from 'ngx-toastr';

export interface DialogData {
  data: any;
}
@Component({
  selector: 'app-ordercancel',
  templateUrl: './ordercancel.component.html',
  styleUrls: ['./ordercancel.component.scss']
})
export class OrdercancelComponent implements OnInit {
  Exitarray: any = [];
  displayedColumns: string[] = ['Buy/Sell', 'Symbol', 'Qty', 'OrderType', 'Segment']
  constructor(
    public service: HttpService,
    public toastr: ToastrService,
    private spinnerService: NgxUiLoaderService,
    public dialogRef: MatDialogRef<OrdercancelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public routeTo: Router
  ) { }

  ngOnInit() {
    this.Exitarray = this.data['data'];
  }

  /**
   * @method Method to Exit Orders
   * @params na
   * @return na
   * @author kalai 
   * @on 19-05-2020
   */
  orderCancel() {
    var internalid = [];
    for (let i of this.Exitarray) {
      internalid.push(i['InternalOrderId']);
    }
    let jsonObj = {
      "internalOrderId": internalid,
      "userId": this.service.getUserId(),
      "oeTransactionCode": "2070",
      "exchangeId": ""
    }
    this.service.cancelOrdersCall(jsonObj).subscribe(resp => {
      if (resp['StatusOfRequest']['Status'] == "0") {
        this.service.toastr.success("Order cancelled successfully");
        this.dialogRef.close(true);
      } else {
        this.service.toastr.error("failed")
      }
    }, (err) => {
      this.service.toastr.error(err)
    });
  }

  closeOrders() {
    this.Exitarray = [];
    this.dialogRef.close();
  }
}
