import { Component, OnInit, Input } from '@angular/core';
import { ChartingLibraryWidgetOptions, IChartingLibraryWidget, widget } from '../../../assets/charting_library/charting_library.min';
import { HttpService } from 'src/app/services/http.service';
import { Datafeed } from '../chart/datafeed';
import { WebsocketService } from 'src/app/services/websocket.service';
import { SharedataService } from 'src/app/services/sharedata.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
// import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  funds: any = []; v
  userDetails: any;
  userID: any;
  fundsDetails: any;
  PieChart: any = [];
  DepositRemaining: any = "0.00";
  LedgerDeposit: any = "0.00";
  UsedMargin: any = "0.00";
  CommodityCollateral: any = "0.00";
  CurrencyCollateral: any = "0.00";
  DerivativeCollateral: any = "0.00";
  EquityCollateral: any = "0.00";
  MTMmargin: any = "0.00";
  AdhocDeposit: any;
  fundvisible: boolean;
  segmentlist: any = ['All', 'MCX', 'NFO', 'CDS'];
  // showDatafound: boolean = true;
  emptytablemsg: boolean = false;
  ordertiktoken: any;
  setSelectVal: any;
  exitarray: any;
  totalvalue: any = 0;
  positioncheck: any = false;
  position: any = [];
  showOverAllExit: boolean = true;
  completedOrder: any = [];
  orderList: any = [];
  entrytime: any;
  CPcode: any;
  Qty: any;
  Trig: any;
  // ordertabs: any = constanttabs;
  completedTrades: any;
  orderlist: any = [];
  emptytablelist: boolean = false;
  selectedIndex: any = 0;
  orderDetails: any;
  emptytablesegment: boolean = false;
  myModel: boolean = false;
  openOrder: any[];
  tempOpenOrders: any[];
  temppositions: any;
  ordertype: string;
  emptytable: boolean;
  allOrders: any = [];

  orderStatus: string;
  mwDetails: any;
  positionsvisible: boolean = false;

  // private _symbol: ChartingLibraryWidgetOptions['symbol'] = 'NIFTY 50::NSE::Index';
  private _symbol: ChartingLibraryWidgetOptions['symbol'] = 'INFY::NSE';
  private _interval: ChartingLibraryWidgetOptions['interval'] = 'D';
  // BEWARE: no trailing slash is expected in feed URL
  private _datafeedUrl = HttpService.chartURL;
  private _libraryPath: ChartingLibraryWidgetOptions['library_path'] = '../assets/charting_library/';
  private _fullscreen: ChartingLibraryWidgetOptions['fullscreen'] = false;
  private _autosize: ChartingLibraryWidgetOptions['autosize'] = true;
  private _containerId: ChartingLibraryWidgetOptions['container_id'] = 'tv_chart_container';
  private _tvWidget: IChartingLibraryWidget | null = null;
  tValue: any;
  router: any;
  private _chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'] = 'https://saveload.tradingview.com';
  holdingvisible: boolean;
  ordervisible: boolean = false;
  pnlMargin: any;
  holdings: any = [];


  @Input()
  set symbol(symbol: ChartingLibraryWidgetOptions['symbol']) {
    this._symbol = symbol || this._symbol;
  }

  @Input()
  set intervalChart(interval1: ChartingLibraryWidgetOptions['interval']) {
    this._interval = interval1 || this._interval;
  }

  @Input()
  set datafeedUrl(datafeedUrl: string) {
    this._datafeedUrl = datafeedUrl || this._datafeedUrl;
  }

  @Input()
  set libraryPath(libraryPath: ChartingLibraryWidgetOptions['library_path']) {
    this._libraryPath = libraryPath || this._libraryPath;
  }

  @Input()
  set fullscreen(fullscreen: ChartingLibraryWidgetOptions['fullscreen']) {
    this._fullscreen = fullscreen || this._fullscreen;
  }

  @Input()
  set autosize(autosize: ChartingLibraryWidgetOptions['autosize']) {
    this._autosize = autosize || this._autosize;
  }

  @Input()
  set containerId(containerId: ChartingLibraryWidgetOptions['container_id']) {
    this._containerId = containerId || this._containerId;
  }

  constructor(public httpService: HttpService,
    public sharedata: SharedataService,
    public websocket: WebsocketService,
    private loader: NgxUiLoaderService,
    public toastr: ToastrService,
  ) {
    if (
      localStorage.getItem("userdetails") != null ||
      localStorage.getItem("userdetails") != undefined
    ) {
      var userDetails = JSON.parse(localStorage.getItem("userdetails"));
      this.userDetails = userDetails;
      this.userID = this.userDetails["UserDetails"]["UserID"];
      this.mwDetails = this.userDetails["MarketWatchDetails"];
    }
    //this.renderChart();
    this.positionlist();
    this.getFunds();
    this.getOrderList();
    // this.gettradelist();
    this.getHoldingList();
    this.sharedata.ordersMsgShare.subscribe(res => {
      if (res) {
        this.positionlist();
        this.getOrderList();
        // this.gettradelist();
        this.getHoldingList();
      }
    });

    this.sharedata.webSocketData.subscribe((msg: any) => {
      for (let idx in this.position) {
        if (msg[4] != "" && msg[8] != "") {
          if (msg[4] == this.position[idx]['Internaltoken']) {
            this.position[idx]['LTP'] = msg[8];
          }
        } else if (msg[4] != "" && msg[8] != "") {
          if (msg[4] == this.position[idx]['Internaltoken']) {
            this.position[idx]['LTP'] = msg[8];
          }
        }
      }
    });
  }


  ngOnInit() {
    this.renderChart();
  }

  /**
   * Method to  get HOlding list mini
   * @author Kalai
   * @since 14-05-2020
   */
  getHoldingList() {
    this.loader.start();
    let JsonObj = {
      "userId": this.userID,
      "symbol": "" //Empty string for all holdings, if u want for specific stock send symbol name
    }
    this.httpService.holdingListDetails(JsonObj).subscribe(resp => {
      this.holdingvisible = false;
      this.loader.stop();
      if (resp['StatusRequest']['status'] == '0' && resp['StatusRequest']['No.ofRecords'] > 1) {
        // resp['HoldingData'].pop();
        this.holdingvisible = true;
        var result: any = resp['HoldingData'];
        result.map((item, i) => {
          result[i]['CurrentMarketPrc'] = Number(result[i]['CurrentMarketPrc']).toFixed(2);
          result[i]['CurrentValue'] = Number(result[i]['CurrentValue']).toFixed(2);
        });
        this.holdings = result;
        this.emptytable = true;
      } else {
        if (resp['StatusRequest']['status'] == "1") {
          if (resp['StatusRequest']['Message'] == "User Not Logged In") {
            this.httpService.unauthorized();
          }
        }
      }
    }, (err) => {
      this.loader.stop();
      this.httpService.unauthorized();
    });
  }

  /**
   * Method to get Position list mini
   * @author Kalai
   * @on 12-03-2020
   */
  positionlist() {
    this.loader.start();
    let JsonData: any = {
      userId: this.userID,
      "exchangeId": "0",
      "marketId": "0",
      "segmentId": "0",
      "instrumentType": "",
      "optionType": "",
      "group": "NA",
      "aggregate": "0",
      "strikePrice": ""
    }
    this.httpService.positionlists(JsonData).subscribe(resp => {
      this.loader.stop();
      this.totalvalue = 0;
      this.positionsvisible = true;
      if (resp['StatusOfRequest']['status'] == "0") {
        this.position = resp['PositionData'];
        if (this.position.length > 1) {
          this.positionsvisible = false;
          for (let index in this.position) {
            this.position[index]['C/FQty'] = Number(this.position[index]['C/FQty']);
            this.position[index]['C/FAvgPr'] = Number(this.position[index]['C/FAvgPr']);
            this.position[index]['M2M'] = Number(this.position[index]['M2M']);
            this.position[index]['checked'] = false;
            this.totalvalue += Number(this.position[index]['M2M'].toFixed(2));
          }
          console.log(this.position)
          this.position.pop();
          // this.totalvalue = Number(this.totalvalue).toFixed(2);
          if (this.position.length > 1) {
            this.positionsvisible = false;
          } else {
            // this.positionsvisible = true;
          }
        } else {
          this.positionsvisible = true;
        }
      } else {
        if (resp['StatusRequest']['status'] == "1") {
          if (resp['StatusOfRequest_a']['Message'] == "User has been logged off") {
            this.httpService.unauthorized();
          }
        }
        this.positionsvisible = true;
        // this.toastr.error("Error");
      }

    }, (err) => {
      this.loader.stop();
    });
  }

  /**
  * Method to get funds Details mini
  * @author Kalai
  * @since 18-04-2020
  */
  getFunds() {
    this.loader.start()
    let JsonObj: any = {
      "userId": this.userID,
    }
    this.httpService.fundsList(JsonObj).subscribe(resp => {
      this.loader.stop();
      this.fundvisible = false;
      if (resp['StatusOfRequest_a']['status'] == "0" && resp['StatusOfRequest_a']['No.ofRecords'] > 0) {
        this.fundsDetails = resp['FundsData_a'];
        this.fundvisible = true;
        this.DepositRemaining = this.fundsDetails['DepositRemaining'];
        this.LedgerDeposit = this.fundsDetails['LedgerDeposit'];
        this.UsedMargin = this.fundsDetails['UsedMargin'];
        this.CommodityCollateral = this.fundsDetails['CommodityCollateral'];
        this.CurrencyCollateral = this.fundsDetails['CurrencyCollateral'];
        this.DerivativeCollateral = this.fundsDetails['DerivativeCollateral'];
        this.EquityCollateral = this.fundsDetails['EquityCollateral'];
        this.MTMmargin = this.fundsDetails['MTMmargin'];
        this.AdhocDeposit = this.fundsDetails['AdhocDeposit'];
        this.pnlMargin = this.fundsDetails['pnlMargin'];
      } else {
        if (resp['StatusOfRequest_a']['status'] == "1") {
          if (resp['StatusOfRequest_a']['Message'] == "User has been logged off") {
            this.httpService.unauthorized();
          }
          // this.httpService.unauthorized();
        }
      }
    })
  }

  /**
   * Chart
   */
  renderChart() {
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this._symbol,
      // datafeed: new Datafeed(this._datafeedUrl, 10000, this.odgenserv, this.websocket),
      datafeed: new Datafeed(this._datafeedUrl, 10000, this.httpService, this.websocket),
      interval: this._interval,
      container_id: this._containerId,
      library_path: this._libraryPath,
      locale: 'en',
      fullscreen: this._fullscreen,
      autosize: this._autosize,
      disabled_features: ['timezone_menu', 'left_toolbar', 'timeframes_toolbar', 'header_compare', 'header_symbol_search'],
      charts_storage_url: this._chartsStorageUrl
    };
    const tvWidget = new widget(widgetOptions);
    this._tvWidget = tvWidget;
    tvWidget.onChartReady(() => {
    });
  }

  /**
  * Method to get Order List mini
  * @author Dinesh
  * @on 17-03-2020
  */
  getOrderList() {
    this.loader.start();
    let JsonData = {
      "internalLoginId": this.httpService.getInternalId(),
      "userId": this.httpService.getUserId(),
      "orderType": "-1",
      "orderStatus": "2"
    }
    this.httpService.allOrderList(JsonData).subscribe(resp => {
      this.loader.stop();
      this.ordervisible = false;
      if (resp.length > 0) {
        this.orderDetails = resp;
        for (let idx of this.orderDetails) {
          idx['Price'] = Number(idx['Price']).toFixed(2);
        }
        this.ordervisible = true;
      } else {
        this.emptytablemsg = false;
      }
    }, (err) => {
      if (err.error == 'Unauthorized') {
        this.httpService.unauthorized();
      }
    })
  }


  alertify() {
    this.httpService.alertpop();
  }
}
