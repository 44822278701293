import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule, MatButtonModule, MatCardModule, MatToolbarModule, MatListModule, MatIconModule, MatSidenavContent, MatSidenav } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';

import { MobilewindowRoutingModule } from './mobilewindow-routing.module';
import { MobileviewComponent } from './mobileview/mobileview.component';
import { OrderwindowComponent } from '../orderwindow/orderwindow.component';
import { MobileviewchartComponent } from './mobileviewchart/mobileviewchart.component';

@NgModule({
  declarations: [MobileviewComponent, MobileviewchartComponent],
  imports: [
    CommonModule,
    MobilewindowRoutingModule,
    MatButtonModule,
    MatMenuModule,
    FormsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatDialogModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatListModule,    // MatSidenav,
    CommonModule,
    MatRadioModule,
    FlexLayoutModule,
    MatCheckboxModule,
  ],
  // exports: [
  //   LogoutpopupComponent,
  //   OrderwindowComponent
  // ],
  // entryComponents: [
  //   LogoutpopupComponent,
  //   OrderwindowComponent
  // ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class MobilewindowModule { }
