import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from '../services/http.service';
import { ToastrService } from 'ngx-toastr';

export interface DialogData {
  data: any;
}

@Component({
  selector: 'app-squareoff',
  templateUrl: './squareoff.component.html',
  styleUrls: ['./squareoff.component.scss']
})
export class SquareoffComponent implements OnInit {
  ExitPosition: any = [];
  qty: number;
  type: number;
  product: number;
  validity: number;
  price: number;
  symbol: any;
  tempArr: any;
  ExitArr: any = [];
  displayedColumns: string[] = ['Trantype', 'Tsym', 'Netqty', 'LTP', 'Pcode', 'Type'];

  constructor(
    public service: HttpService,
    public toastr: ToastrService,
    public dialogRef: MatDialogRef<SquareoffComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public routeTo: Router
  ) { }

  ngOnInit() {
  }


  /**
   * @method Method to Exit positions
   * @params na
   * @return na
   * @author kalai 
   * @on 11-06-2020
   */
  goToPositionExit() {
  }

}
