import { Component, OnInit } from '@angular/core';
import { SharedataService } from '../services/sharedata.service';
import { MatDialogRef } from '@angular/material';
import { JsonPipe } from '@angular/common';
import { ElementArrayFinder } from 'protractor';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  latestInfo: any = [];
  info2Show: any = [];
  noData: boolean = true;
  constructor(
    public notifications: SharedataService,
    public dialogRef: MatDialogRef<NotificationsComponent>,
  ) {
    var notifyNos: number = 0;
    this.notifications.orderPlaceTcp.subscribe((msg: any) => {
      if (msg !== "") {
        if (localStorage.getItem("notifyMsg") != null || localStorage.getItem("notifyMsg") != undefined) {
          this.latestInfo = JSON.parse(localStorage.getItem("notifyMsg"));
          this.latestInfo.push(msg);
        } else {
          this.latestInfo.push(msg);
        }
        localStorage.setItem("notifyMsg", JSON.stringify(this.latestInfo));
      }

      if (localStorage.getItem("notifyMsg") != null || localStorage.getItem("notifyMsg") != undefined) {
        this.info2Show = JSON.parse(localStorage.getItem("notifyMsg"));
        notifyNos = this.info2Show.length;
        if (this.info2Show.length > 0) {
          this.notifications.notifyLength(notifyNos);
          this.noData = false;
        } else {
          this.noData = true;
        }
      }
    });
  }

  ngOnInit() {
  }

  /**
   * Method to close notifications
   * @author Kalai
   * @since 17-06-2020
   */
  close() {
    this.dialogRef.close()
  }

  /**
   * Method to clear Notifications
   * @author Kalai
   * @since 18-06-2020
   */
  clearAll() {
    this.info2Show = [];
    this.noData = true;
  }

}
