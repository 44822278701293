import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  changepass: FormGroup;
  userDetails: any = [];
  userID: any;
  password: any;
  show: boolean = false;
  buttonName: any = 'Show';
  constructor(public httpService: HttpService,
    public formBuilder: FormBuilder,
    public toastr: ToastrService,
    public routeTo: Router) { }

  ngOnInit() {
    if (localStorage.getItem("userdetails") != null || localStorage.getItem("userdetails") != undefined) {
      var userDetails = JSON.parse(localStorage.getItem("userdetails"));
      this.userDetails = userDetails;
      this.userID = this.userDetails['UserDetails']['UserID'];
    }

    this.createForm();
  }
  //to show the password only  when they click the arrow icon
  toggle() {
    this.show = !this.show;
    if (this.show)
      this.buttonName = "Hide";
    else
      this.buttonName = "Show";
  }


  createForm() {
    this.changepass = this.formBuilder.group({
      'oldPass': ["", Validators.required],
      'newPass': ["", Validators.required],
      'confirmPass': ["", Validators.required],
    });
  }

  getError(el) {
    switch (el) {
      case 'old':
        if (this.changepass.get('oldPass').hasError('required')) {
          return 'old password required';
        }
        break;
      case 'new':
        if (this.changepass.get('newPass').hasError('required')) {
          return ' new Password required';
        }
        break;
      case 'confirm':
        if (this.changepass.get('confirmPass').hasError('required')) {
          return 'confirm Password required';
        }
        break;
      default:
        return '';
    }
  }


  /**
   * Method to change the password 
   * @author Dinesh
   * @since 19.03.2020
   */
  changepassword() {
    if (this.changepass.value.newPass == this.changepass.value.confirmPass) {
      let JsonData = {
        "oldPass": this.changepass.value.oldPass,
        "newPass": this.changepass.value.newPass,
        "confirmPass": this.changepass.value.confirmPass,
        "userId": this.userID,
      }
      this.httpService.changePassword(JsonData).subscribe(resp => {
        if (resp['status'] == '0') {
          this.toastr.success("Password changed Successfully");
          this.routeTo.navigateByUrl('/entrylevel/login');
        } else {
          this.toastr.error(resp['Message']);
        }
      }, (err) => {
        this.httpService.toastr.error(err);
      })
    } else {
      this.httpService.toastr.error("Password didn't match");
    }
  }

  cancel() {
    this.changepass.value.clear();
  }
}
