import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { debug } from 'util';
import { Chart } from 'chart.js';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-funds',
  templateUrl: './funds.component.html',
  styleUrls: ['./funds.component.scss']
})
export class FundsComponent implements OnInit {
  funds: any = []; v
  userDetails: any;
  userID: any;
  fundsDetails: any;
  PieChart: any = [];
  EquitySales: any;
  DepositRemaining: any = "0.00";
  LedgerDeposit: any = "0.00";
  UsedMargin: any = "0.00";
  CommodityCollateral: any = "0.00";
  CurrencyCollateral: any = "0.00";
  DerivativeCollateral: any = "0.00";
  EquityCollateral: any = "0.00";
  MTMmargin: any = "0.00";
  public context: CanvasRenderingContext2D;
  @ViewChild('myCanvas') myCanvas: ElementRef;
  AdhocDeposit: any;

  constructor(
    public service: HttpService,
    private loader: NgxUiLoaderService,
  ) { }

  ngOnInit() {
    if (localStorage.getItem("userdetails") != null || localStorage.getItem("userdetails") != undefined) {
      var userDetails = JSON.parse(localStorage.getItem("userdetails"));
      this.userDetails = userDetails;
      this.userID = this.userDetails["UserDetails"]["UserID"];
    }
    this.getFunds();
  }

  getPayInPayOutTokenKey(value) {
    alert("In Progress");
  }

  /**
   * Method to get funds Details
   * @author Kalai
   * @since 18-04-2020
   */
  getFunds() {
    this.loader.start()
    let JsonObj: any = {
      "userId": this.userID,
    }
    this.service.fundsList(JsonObj).subscribe(resp => {
      this.loader.stop();
      if (resp['StatusOfRequest_a']['status'] == "0" && resp['StatusOfRequest_a']['No.ofRecords'] > 0) {
        this.fundsDetails = resp['FundsData_a'];
        this.DepositRemaining = this.fundsDetails['DepositRemaining'];
        this.LedgerDeposit = this.fundsDetails['LedgerDeposit'];
        this.UsedMargin = this.fundsDetails['UsedMargin'];
        this.CommodityCollateral = this.fundsDetails['CommodityCollateral'];
        this.CurrencyCollateral = this.fundsDetails['CurrencyCollateral'];
        this.DerivativeCollateral = this.fundsDetails['DerivativeCollateral'];
        this.EquityCollateral = this.fundsDetails['EquityCollateral'];
        this.MTMmargin = this.fundsDetails['MTMmargin'];
        this.EquitySales = this.fundsDetails['EquitySales'];
        this.AdhocDeposit = this.fundsDetails['AdhocDeposit'];
        var avalMargin: any = this.fundsDetails['DepositRemaining'];
        var MarginUsed: any = this.fundsDetails['UsedMargin'];
        var canvas = <HTMLCanvasElement>document.getElementById("canvas");
        var ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        this.PieChart = new Chart(ctx, {
          type: 'doughnut',
          data: {
            labels: ['Available Margin', 'Margin Used'],
            datasets: [{
              label: '# of Votes',
              pointHoverRadius: 5,
              data: [avalMargin, MarginUsed],
              backgroundColor: [
                'rgb(70, 191, 189)',
                'rgb(247, 70, 74)',
              ],
              borderColor: [
                'rgb(70, 191, 189)',
                'rgb(247, 70, 74)',
              ],
              borderWidth: 1
            }]
          },
          options: {
            cutoutPercentage: 65,
          }
        });

      } else {

      }
    })
  }
}
