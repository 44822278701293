import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsercontrolRoutingModule } from './usercontrol-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatSidenavModule, MatButtonModule, MatCardModule, MatBadgeModule, MatToolbarModule, MatListModule, MatIconModule, MatSidenavContent, MatSidenav } from '@angular/material';
import { PositionComponent } from './position/position.component';
import { MatSortModule } from '@angular/material/sort';
import { HoldingComponent } from './holding/holding.component';
import { OrderComponent } from './order/order.component';
import { FundsComponent } from './funds/funds.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IndexlistComponent } from './indexlist/indexlist.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ProfileComponent } from './profile/profile.component';
// Add Angular Matetrial components
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material';
import { FlexLayoutModule } from "@angular/flex-layout";
import { SettingsComponent } from './settings/settings.component';
import { MarketStatusComponent } from './market-status/market-status.component';
import { CustSupportComponent } from './cust-support/cust-support.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
  declarations: [
    DashboardComponent,
    PositionComponent,
    HoldingComponent,
    OrderComponent,
    FundsComponent,
    HomeComponent,
    IndexlistComponent,
    ProfileComponent,
    SettingsComponent,
    MarketStatusComponent,
    CustSupportComponent,
  ],
  imports: [
    MatButtonModule,
    MatSortModule,
    MatMenuModule,
    FormsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatDialogModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatListModule,    // MatSidenav,
    CommonModule,
    MatRadioModule,
    UsercontrolRoutingModule,
    FlexLayoutModule,
    MatCheckboxModule
  ],
  exports: [],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UsercontrolModule { }
