import { Component, OnInit } from '@angular/core';
import { SharedataService } from 'src/app/services/sharedata.service';

@Component({
  selector: 'app-market-status',
  templateUrl: './market-status.component.html',
  styleUrls: ['./market-status.component.scss']
})
export class MarketStatusComponent implements OnInit {
  openMkt: any = "Normal Market Closed";
  constructor(
    public sharedata: SharedataService,
  ) {

    this.sharedata.marketStatus.subscribe((msg: any) => {
      if (msg[0] == "71") {
        console.log(msg)
      }
    });
    if (localStorage.getItem("marketStatus") != null || localStorage.getItem("marketStatus") != undefined) {
      var msg = JSON.parse(localStorage.getItem("marketStatus"));
      this.openMkt = msg[4];
    }
  }

  ngOnInit() {
  }

}
