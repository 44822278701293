import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WebsocketService } from './websocket.service';
import { map } from 'rxjs/operators';
import * as pako from 'pako'
import { SharedataService } from './sharedata.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  // Websocket
  // WS_URL = "ws://localhost:8080/PhillipCapital/webSocket?userId=" + this.getUserId();
  WS_URL = "ws://pctrade.phillipcapital.in:8080/PhillipCapital/webSocket";//LIVE
  // Base Url
  // baseURL: string = "http://192.168.1.73:8080/PhillipCapital/";
  baseURL: string = "http://pctrade.phillipcapital.in:8080/PhillipCapital/";
  // baseURL: string = "http://localhost:8080/PhillipCapital/";

  public static chartURL: string = "http://pctrade.phillipcapital.in:8080/PhillipCapital/chart";


  headers: any = new HttpHeaders({ 'Content-Type': 'application/json' });
  //Chart 
  //chartsymbols: string = "chart/symbols";
  charthistory: string = "chart/history";
  chartsymbol_info: string = "chart/symbol_info";
  chartconfig: string = "chart/config";
  chartmarks: string = "chart/marks";
  charttimescale_marks: string = "chart/timescale_marks";

  userloginurl: string = "customer/login";
  userOTPUrl: string = "customer/validate2FA";
  setOTPUrl: string = "customer/verify2FA";
  openmwURL: string = "marketWatch/openMW";
  searchsym: string = "scrip/searchScrip";
  addScripTomw: string = "scrip/addScrip";
  createMw: string = "marketWatch/createMW";
  depthRequest: string = "marketWatch/marketDepth";
  defaultMW: string = "marketWatch/setDefaultMW";
  addIndex: string = "marketWatch/addIndex";
  deleteMw: string = "scrip/deleteScrip";
  orderPlace: string = "orders/placeOrder";
  listorders: string = "orders/listOrders";
  positionlist: string = "positionsHoldings/listPosition";
  tradelists: string = "orders/listTrades";
  tickSize: string = "scrip/tickSize";
  changepassword: string = "customer/changePwd";
  funds: string = "settings/getFundsQuery";
  forgotPwd: string = "customer/forgotPwd";
  scripInfo: string = "scrip/scripInfo";
  activityLog: string = "orders/activityLog";
  cancelOrder: string = "orders/cancelOrders";
  modifyOrder: string = "orders/modifyOrder";
  convertPosition: string = "positionsHoldings/convertPosition";
  allOrders: string = "orders/allOrders";
  logout: string = "customer/logout";
  orderHistory: string = "orders/orderHistory"


  public mWatch: Subject<any>;
  indexRec: string = "settings/getIndexDetails";
  insertIndex: string = "settings/insertIndexDetails";
  // static chartURL: any;
  holdingList: string = "positionsHoldings/listHoldings"
  newarray: any;

  //TradeView Chart 
  sendTokenscripDepth: string = "ScripDetails/getScripQuoteDetails";
  @Output() navchange: EventEmitter<any> = new EventEmitter();

  constructor(public http: HttpClient,
    public toastr: ToastrService,
    private wsService: WebsocketService,
    public shareData: SharedataService,
    public router: Router) {
    var loginState: any = JSON.parse(localStorage.getItem("loginState"));
    if (loginState) {
      this.webSocketConnection();
    }

  }

  webSocketConnection() {
    this.mWatch = <Subject<any>>this.wsService
      .connect(this.WS_URL)
      .pipe(map((response: any, i): any => {
        var data = response.data;
        return data;
      }));
  }

  unauthorized() {
    localStorage.clear();
    this.logoutUser();
    this.toastr.error("User has been logged off");
    this.router.navigateByUrl("/entrylevel/login");
  }

  /**Method to logout the user */
  logoutUser() {
    let jsonObj = {
      "userId": this.getUserId(),
    }
    this.logoutSession(jsonObj).subscribe(resp => {
      this.wsService.close();
    })
  }

  userLogin(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.userloginurl, jsonObj, {
      headers: this.headers
    })
  }

  generateMobileOTP(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.userOTPUrl, jsonObj, {
      headers: this.headers
    })
  }

  setUserOTP(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.setOTPUrl, jsonObj, {
      headers: this.headers
    })
  }

  marketWatch(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.openmwURL, jsonObj, {
      headers: this.getAuthHeaders(),

    })
  }

  searchSymbol(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.searchsym, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  }

  addScripToMW(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.addScripTomw, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  }

  createMW(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.createMw, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  }

  depthNewRequest(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.depthRequest, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  }

  defaulatMarketWatch(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.defaultMW, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  }

  addIndexToList(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.addIndex, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  }

  deleteMarketWatch(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.deleteMw, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  }

  placeOrder(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.orderPlace, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  }

  listOrder(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.listorders, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  }

  positionlists(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.positionlist, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  }

  tradelist(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.tradelists, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  }

  changePassword(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.changepassword, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  }

  getAuthHeaders() {
    return this.headers.append('Authorization', 'Bearer ' + this.getUserId() + ' ' + this.getSessionToken());
  }

  getUserId() {
    if (localStorage.getItem("userid") != null || localStorage.getItem("userid") != undefined) {
      var userId = JSON.parse(localStorage.getItem("userid"));
      return userId;
    }
  }
  getInternalId() {
    if (localStorage.getItem("userdetails") != null || localStorage.getItem("userdetails") != undefined) {
      var userDetail = JSON.parse(localStorage.getItem("userdetails"));
      var internalId = userDetail['UserDetails']['UserInternalID'];
      return internalId;
    }
  }

  getSessionToken() {
    var tokenId = JSON.parse(localStorage.getItem("sessionId"));
    return tokenId;
  }


  getIndexDetails(jsonObj) {
    return this.http.post(this.baseURL + this.indexRec, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  }

  insertIndexDetails(jsonObj) {
    return this.http.post(this.baseURL + this.insertIndex, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  }
  /**
   * Web socket data 
   */
  getNavChangeEmitter() {
    return this.navchange;
  }

  sendScriptTokenDepth(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.sendTokenscripDepth, jsonObj, {
      headers: this.getAuthHeaders()
    });
  }

  holdingListDetails(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.holdingList, jsonObj, {
      headers: this.getAuthHeaders()
    });
  }

  fundsList(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.funds, jsonObj, {
      headers: this.getAuthHeaders()
    });
  }

  forgotPwdLink(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.forgotPwd, jsonObj, {
      headers: this.getAuthHeaders()
    });
  }

  getSecurityInfo(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.scripInfo, jsonObj, {
      headers: this.getAuthHeaders()
    });
  }

  getTickSize(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.tickSize, jsonObj, {
      headers: this.getAuthHeaders()
    });
  }

  getActivityLog(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.activityLog, jsonObj, {
      headers: this.getAuthHeaders()
    });
  }

  cancelOrdersCall(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.cancelOrder, jsonObj, {
      headers: this.getAuthHeaders()
    });
  }

  allOrderList(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.allOrders, jsonObj, {
      headers: this.getAuthHeaders()
    });
  }

  positionConvert(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.convertPosition, jsonObj, {
      headers: this.getAuthHeaders()
    });
  }

  logoutSession(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.logout, jsonObj, {
      headers: this.getAuthHeaders()
    });
  }

  modifyScrip(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.modifyOrder, jsonObj, {
      headers: this.getAuthHeaders()
    });
  }

  getOrderHistory(jsonObj): Observable<any> {
    return this.http.post(this.baseURL + this.orderHistory, jsonObj, {
      headers: this.getAuthHeaders()
    });
  }

  alertpop() {
    alert("In Progress..")
  }
}