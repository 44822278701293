import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  hide = true;
  hide1 = true;
  hide2 = true;
  notOKstat: any;
  userId: any;
  public resetPass: FormGroup;
  forgotemsg: any;

  constructor(
    public formBuilder: FormBuilder,
    private spinnerService: NgxUiLoaderService,
    public toastr: ToastrService,
    public httpService: HttpService,
    public routeTo: Router,
    public formbuilder: FormBuilder,
  ) { }

  ngOnInit() {
    if (localStorage.getItem("userid") != null || localStorage.getItem("userid") != undefined) {
      this.userId = JSON.parse(localStorage.getItem("userid"));
    }
    this.resetPass = this.formbuilder.group({
      oldPass: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^[a-zA-Z0-9]+$')]],
      newPass: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^[a-zA-Z0-9]+$')]],
      confirmPass: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^[a-zA-Z0-9]+$')]],

    });

  }

  /**
  * Method to change the password 
  * @author Kalai
  * @since 15.06.2020
  */
  changepassword() {
    if (this.resetPass.value.newPass == this.resetPass.value.confirmPass) {
      let JsonData = {
        "oldPass": this.resetPass.value.oldPass,
        "newPass": this.resetPass.value.newPass,
        "confirmPass": this.resetPass.value.confirmPass,
        "userId": this.httpService.getUserId(),
      }
      this.httpService.changePassword(JsonData).subscribe(resp => {
        if (resp['status'] == '0') {
          this.toastr.success("Password changed Successfully");
          this.routeTo.navigateByUrl('/entrylevel/login');
        } else {
          this.forgotemsg = resp['Message'];
        }
      }, (err) => {
        this.httpService.toastr.error(err);
      })
    } else {
      this.httpService.toastr.error("Password didn't match");
    }
  }

  /**
   * Method to go back Login
   * @author Kalai
   * @since 22-05-2020
   */
  backToLogin() {
    this.routeTo.navigateByUrl('entrylevel/login')
  }

}
